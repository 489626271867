import APIRequest from "../util/api/APIRequest";

const getDropdownData = url => {
  return APIRequest({
    url: url,
    method: "GET",
    params: {
      page: 1,
      limit: 1000
    }
  });
};

const analysisDropdown = (data) =>{
  return APIRequest({
    url: "analysis/get_distinct_data/",
    method: "POST",
    data : data
  })
}

const relatedData = (data) =>{
  return APIRequest({
    url: "analysis/get_related_property_data/",
    method:"POST",
    data: data
  })
  }

  const multifilterData = (data) =>{
    return APIRequest({
      url: "analysis/get_multifiltered_data/",
      method:"POST",
      data: data
    })
    }

    const layerExtendFilter = (data) =>{
      return APIRequest({
        url: "analysis/layer_extent_filter/",
        method:"POST",
        data: data
      })
    }

const DropdownService = {
  getDropdownData,
  analysisDropdown,
  relatedData,
  multifilterData,
  layerExtendFilter
};
export default DropdownService;
