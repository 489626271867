import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SERVER_UNAVAILABLE,
  GET_PUBLIC_USERS,
  GET_PUBLIC_USERS_FAILURE,
} from "./constants";
import { loadingActions } from "../../../../components/loader/data/action";
import authenticationService from "../../../../service/AuthenticationService";
import localStorageHandler from "../../../../util/storage/";
import { Redirect } from "react-router-dom";
import { BASE_URL, ADMIN_URL } from "../../../../config";
import { configLoadingComplete, isConfigloading } from "../../../dashboard/data/action";

export const doLogin = (userCredentials) => {
  return (dispatch) => {
    dispatch(loadingActions.isloading());
    localStorageHandler.removeSession();
    dispatch(request(userCredentials));

    authenticationService
      .login(userCredentials)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(success(response));
          dispatch(loadingActions.loadingComplete());
          dispatch(isConfigloading());
          const loginSpltPayload = {
            ...userCredentials,
            local_body_id: localStorageHandler.getLocalBody().local_body_id,
            cql_filter: `localbody_id=${localStorageHandler.getLocalBody().local_body_id}`,
            default_column_config: localStorageHandler.getLoginResponse().default_column_config,
          };
          authenticationService.loginSplit(loginSpltPayload).then((responseData) => {
            if(responseData.data.status == 200){         
              let combinedDetails = JSON.parse(localStorage.getItem("basicDetails"));
              combinedDetails.basicDetails.localBody.layerCategory = responseData?.data?.layerCategory ?? [];
              localStorage.setItem("basicDetails", JSON.stringify(combinedDetails));
            } else if(responseData.data.status ==401) {
              dispatch(loginfailure(responseData));
            }
            dispatch(configLoadingComplete());
          })

        } else if (response.data.status == 401) {
          dispatch(loginfailure(response));
          dispatch(loadingActions.loadingComplete());
        }
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(loadingActions.loadingComplete());
      });
  };

  function request(publicUsers) {
    return { type: LOGIN_REQUEST, publicUsers };
  }
  function success(response) {
    return { type: LOGIN_SUCCESS, response };
  }
  function failure(error) {
    return { type: SERVER_UNAVAILABLE, error };
  }
  function loginfailure(response) {
    return { type: LOGIN_FAILURE, response };
  }
};

export const getPublicUserList = () => {
  return (dispatch) => {
    authenticationService
      .getPublicUsers()
      .then((response) => {
        if (response.status == 200) {
          dispatch(success(response));
        } else if (response.data.status == 401) {
          dispatch(loginfailure(response));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(loadingActions.loadingComplete());
      });
  };
  function success(response) {
    return { type: GET_PUBLIC_USERS, response };
  }
  function failure(error) {
    return { type: SERVER_UNAVAILABLE, error };
  }
  function loginfailure(response) {
    return { type: GET_PUBLIC_USERS_FAILURE, response };
  }
};

export const doLogout = () => {
  return (dispatch) => {
    // authenticationService.logout().then(response => {
    localStorageHandler.removeSession();
    dispatch(lgoutAction());
    window.location.reload(ADMIN_URL + `map/logout/`);
    // });
  };

  function lgoutAction() {
    return { type: LOGOUT };
  }
};
export const doLogoutFomAdmin = () => {
  return (dispatch) => {
    localStorageHandler.removeSession();
    dispatch(lgoutAction());
    window.location.href = "/";
  };
  function lgoutAction() {
    return { type: LOGOUT };
  }
};
