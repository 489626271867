import React, { Component } from "react";
import { transform } from "ol/proj.js";
// import tokml from "tokml";
// import { saveAs } from "file-saver";
import { connect } from "react-redux";
import {
  OverlayTrigger,
  ButtonToolbar,
  Modal,
  Panel,
  Button,
  Tooltip,
} from "react-bootstrap";
import { Polygon } from "ol/geom.js";
import Draw from "ol/interaction/Draw";
import View from "ol/View";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer.js";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
import { GeoJSON } from "ol/format.js";
import { unByKey } from "ol/Observable.js";
import getLayerTree from "../../../map/helper/LayerTreeLoader";
import { snackbarActions } from "../../../../../components/snackbar/data/action";
import styleFunction from "../../../map/helper/vectorStyle";
import {
  mapQueryPolygon,
  requestLayer,
  updateSearchResultLayer,
} from "./data/action";
import selectRed from "../../../../../asset/svg/polygon_red.svg";
import ResultModel from "./components/ResultModel";
import Widget from "../../../../../containers/widget/widget";
import "./SelectByPolygon.css";
import SaveModel from "./components/saveModel";
import PolygonTable from "./components/polygonTable";
import MapSearchService from "../../../../../service/MapSearchService";
import compass from "../../../../../asset/png/compass.png";
import ulccs_emblem from "../../../../../asset/png/ULCCS emblem.png";
import emblem from "../../../../../asset/png/emblem.png";
import LocalStorageHandler from "../../../../../util/storage";
import jsPDF from "jspdf";
import { toStringHDMS } from "ol/coordinate";
import Select from "ol/interaction/Select";
import Modify from "ol/interaction/Modify";
import { Feature } from "ol";

class SelectByPolygon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawType: null,
      vectorSource: null,
      draw: null,
      sketch: null,
      listener: null,
      layerlist: [],
      geometry: null,
      show: false,
      result: null,
      showResult: false,
      resultVectorSource: null,
      enabled: false,
      polygonName: null,
      showTable: false,
      showSave: false,
      resultLayer: null,
      response_id: null,
      pageSize: "a3",
      resolution: "72",
      orientation: "landscape",
      layout: "L1",
      polygons: [],
      select: null,
      modify: null,
      drawingActive: false,
      ref: false,
    };
  }

  componentDidMount() {

    this.setState({
      vectorSource: new VectorSource(),
      resultVectorSource: new VectorSource(),
    });
  }

  handleDrawConrtol = () => {
    const { mapComponent } = this.props;
    const { vectorSource, draw, resultVectorSource, drawingActive, ref } = this.state;
    const enabled = this.state.enabled;
    if (drawingActive) {
      return; // Exit if drawing is already active
    }

    const layers = mapComponent.values_.layergroup.values_.layers.array_[1].values_.layers.array_;
    const visibleLayers = layers.filter((layer) => layer.values_.visible);
    if (visibleLayers.length === 0) {
      this.props.showSnackbar("Select Required Layers Before Drawing");
      return;
    }

    if (!ref) {
      mapComponent.addLayer(this.getVectorLayer());
    }

    let listener;
    this.setState(
      {
        output: 0.0,
        draw: this.addInteraction(),
        enabled: true,
        modify: this.addModify(),
        drawingActive: true,
        ref: true,
      },
      () => {
        mapComponent.addInteraction(this.state.draw);
        const { draw } = this.state;

        draw.on("drawstart", (evt) => {
          listener = this.handleDrawStart(evt);
        });
        draw.on("drawend", (listener) => {
          this.handleDrawEnd(listener);
        });
        // Add keydown listener to handle Backspace or Delete
        document.addEventListener("keydown", this.handleKeyDown);
      }
    );
  };

  handleKeyDown = (event) => {
    const { draw } = this.state;
    if (event.key === "Backspace" || event.key === "Delete") {
      draw.removeLastPoint();
    }
  };

  handleDrawStart = (evt) => {
    this.setState({
      sketch: evt.feature,
      drawingActive: false,
    });
    this.props.mapComponent.removeInteraction(this.state.modify);
    const geometry = this.state.sketch.getGeometry();

    window.addEventListener("keydown", this.handleUndoKeyPress);
    return geometry.on("change", (evt) => {
      let geom = evt.target;
      if (geom instanceof Polygon) {
        // this.setState({
        //   output: this.formatArea(geom),
        // });
      }
    });
  };

  handleUndoKeyPress = (event) => {
    const { sketch } = this.state;

    if (!sketch) return;

    if (event.key === "Backspace" || event.key === "Delete") {
      const geometry = sketch.getGeometry();

      if (geometry instanceof Polygon) {
        let coordinates = geometry.getCoordinates()[0];

        if (coordinates.length > 3) {
          coordinates.splice(coordinates.length - 2, 1);
          geometry.setCoordinates([coordinates]);

          sketch.getGeometry().setCoordinates([coordinates]);
        }
      }
    }
  };


  handleDrawEnd = (listener) => {
    const { vectorSource } = this.state;
    this.setState({
      sketch: null,
    });
    document.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keydown", this.handleUndoKeyPress);

    const polygonID = Date.now().toString();
    var feature = listener.feature;

    var coord = feature
      .getGeometry()
      .clone()
      .transform("EPSG:3857", "EPSG:4326");
    unByKey(listener);
    var res = [];
    var layerdetails = {};
    this.props.mapComponent.removeInteraction(this.state.draw);
    this.props.mapComponent.removeInteraction(this.state.modify);
    var layers = this.props.mapComponent.values_.layergroup.values_.layers
      .array_[1].values_.layers.array_;
    layers.forEach((element) => {
      if (element.values_.visible) {
        var obj = {};
        let layerTree = getLayerTree();
        layerTree.map((item) => {
          if (item.hasOwnProperty("children")) {
            let res = item.children.filter((layer) => {
              if (layer.label == element.values_.name) {
                obj["model_name"] = layer.model_name;
                obj["field"] = layer.category_field;
                obj["field_value"] = layer.category_id;
              }
            });
          }
        });
        if (obj["model_name"] !== "Ipms_Localbody") {
          obj["layer_name"] = element.values_.name;
          obj["service_name"] = element.values_.source.params_.LAYERS;
          res.push(obj);
        }
      }
    });
    if (res.length > 0) {
      layerdetails["layer"] = res;
      layerdetails["flatCoordinates"] = coord.flatCoordinates;
      feature.setId(polygonID);
      vectorSource.addFeature(feature);
      this.setState((prevState) => ({
        polygons: [...prevState.polygons, feature],
      }));

      this.setState(
        {
          layerlist: layerdetails,
          geometry: coord,
        },
        () => {
          this.handleShowSave();
        }
      );
    } else {
      this.props.showSnackbar("Select Required Layers Before Drawing");
    }
  };

  savePolygon = () => {
    const { vectorSource } = this.state;
    const polygonName = this.state.polygonName;
    let url;
    const polygonID = this.state.polygons[
      this.state.polygons.length - 1
    ].getId();

    MapSearchService.mapQueryPolygon(
      this.state.layerlist,
      this.state.geometry
    ).then((res) => {
      const filteredData = res.data.filter((item) => item.layer !== "Local body Boundary" && !item.hasOwnProperty('excel_path'));
      res.data.forEach((index) => {
        if (index.excel_path) {
          url = index.excel_path;
        }
      })
      const value = {
        [polygonName]: {
          layer: this.state.layerlist,
          geometry: this.state.geometry,
          polygonID: polygonID,
          url: url,
        },
      };
      let combinedDetails = JSON.parse(localStorage.getItem("Polygonname")) || [];
      combinedDetails.push(value);
      localStorage.setItem(`Polygonname`, JSON.stringify(combinedDetails));

      if (filteredData.length > 0) {
        this.setState(
          {
            result: filteredData,
            showTable: true,
          },
          () => {
            this.handleShowResult();
          }
        );
        this.addSearchResultToMap(filteredData);
      }
    })
      .catch((error) => {
        console.error("Error in mapQueryPolygon:", error);
      })
      .finally(() => {
        this.handleShowSave();
      });

  };

  handleModify = () => {
    const { select, modify, vectorSource } = this.state;
  
    if (select && modify) {
      this.props.mapComponent.removeInteraction(select);
      this.props.mapComponent.removeInteraction(modify);
      this.setState({ select: null, modify: null });
    } else {
      const select = new Select();
      const modify = new Modify({
        source: vectorSource,
      });
  
      let originalGeometries = new Map();
      let layerList = {};
  
      // Listen for the 'modifystart' event to capture original geometry before modification
      modify.on('modifystart', (event) => {
        event.features.forEach((feature) => {
          const originalGeometry = feature.getGeometry().clone(); // Clone the original geometry
          originalGeometries.set(feature.getId(), originalGeometry); // Store it in a map with the feature ID
        });
      });
  
      // Listen for the 'modifyend' event to check if geometry has changed
      modify.on('modifyend', (event) => {
        event.features.forEach((feature) => {
          const originalGeometry = originalGeometries.get(feature.getId());
          const currentGeometry = feature.getGeometry().clone();
  
          // Transform the geometries to the same projection for comparison
          const originalCoords = originalGeometry.transform('EPSG:3857', 'EPSG:4326').getCoordinates();
          const currentCoords = currentGeometry.transform('EPSG:3857', 'EPSG:4326').getCoordinates();
  
          // Compare geometries to check if they have changed
          if (JSON.stringify(originalCoords) !== JSON.stringify(currentCoords)) {
            // Geometry has changed, continue with the rest of the code
            let coords = currentGeometry;
  
            const layerlistValue = this.state.layerlist.layer;
            layerList["layer"] = layerlistValue;
            layerList["flatCoordinates"] = coords.flatCoordinates;
  
            this.setState(
              {
                layerlist: layerList,
                geometry: coords,
              },
              () => {
                MapSearchService.mapQueryPolygon(
                  this.state.layerlist,
                  this.state.geometry
                ).then((res) => {
                  const filteredData = res.data.filter(
                    (item) =>
                      item.layer !== "Local body Boundary" &&
                      !item.hasOwnProperty("excel_path")
                  );
                  if (filteredData.length > 0) {
                    this.setState(
                      {
                        result: filteredData,
                        showTable: true,
                      },
                      () => {
                        this.handleShowResult();
                      }
                    );
                  }
                  this.addSearchResultToMap(filteredData);
                });
              }
            );
          }
        });
      });
  
      this.props.mapComponent.addInteraction(modify);
  
      this.setState({ select, modify });
    }
  };
  

  // handleModify = () => {
  //   const { select, modify, vectorSource } = this.state;

  //   if (select && modify) {
  //     this.props.mapComponent.removeInteraction(select);
  //     this.props.mapComponent.removeInteraction(modify);
  //     this.setState({ select: null, modify: null });
  //   } else {
  //     const select = new Select();
  //     const modify = new Modify({
  //       source: vectorSource,
  //     });

  //     let layerList = {};

  //     modify.on("modifyend", (event) => {
  //       const feature = event.features.item(0);
  //       // event.features.forEach((feature) => {
  //       if (feature) {
  //         let coords = feature
  //           .getGeometry()
  //           .clone()
  //           .transform("EPSG:3857", "EPSG:4326");

  //         const layerlistValue = this.state.layerlist.layer;
  //         layerList["layer"] = layerlistValue;
  //         layerList["flatCoordinates"] = coords.flatCoordinates;

  //         this.setState(
  //           {
  //             layerlist: layerList,
  //             geometry: coords,
  //           },
  //           () => {
  //             MapSearchService.mapQueryPolygon(
  //               this.state.layerlist,
  //               this.state.geometry
  //             ).then((res) => {
  //               const filteredData = res.data.filter((item) => item.layer !== "Local body Boundary" && !item.hasOwnProperty('excel_path'));
  //               if (filteredData.length > 0) {
  //                 this.setState(
  //                   {
  //                     result: filteredData,
  //                     showTable: true,
  //                   },
  //                   () => {
  //                     this.handleShowResult();
  //                   }
  //                 );
  //               }
  //               this.addSearchResultToMap(filteredData);
  //             });
  //           }
  //         );
  //       };
  //     });

  //     this.props.mapComponent.addInteraction(modify);

  //     this.setState({ select, modify });
  //   }
  // };

  handleshowPolygon = () => {
    this.setState({
      showTable: !this.state.showTable,
    });
  };

  handleShowResult = () => {
    this.setState({
      showResult: true,
    });
  };

  handleCloseResult = () => {
    this.setState({
      showResult: false,
    });
  };

  handleShowSave = () => {
    this.setState({
      showSave: !this.state.showSave,
    });
  };

  handleSaveClose = () => {
    const { vectorSource } = this.state;

    const polygonFeature = this.state.polygons;

    vectorSource.removeFeature(polygonFeature[polygonFeature.length - 1]);

    this.setState({
      showSave: false,
    });
  };

  handleViewsavedPolygon = (name) => {
    const polygonData = JSON.parse(localStorage.getItem("Polygonname")) || [];
    polygonData.map((item) =>
      Object.keys(item).map((key) => {
        if (key == name) {
          const geometry = item[name].geometry;
          const layerlist = item[name].layer;
          let responseValues = [];
          MapSearchService.mapQueryPolygon(layerlist, geometry).then((res) => {
            const filteredData = res.data.filter((item) => item.layer !== "Local body Boundary" && !item.hasOwnProperty('excel_path'));

            if (filteredData.length > 0) {
              this.setState(
                {
                  result: filteredData,
                },
                () => {
                  this.handleShowResult();
                }
              );
            }

            this.addSearchResultToMap(filteredData);

            responseValues.push(filteredData);
          });
        }
      })
    );
  };

  getInfoAttributes(element) {
    let infoAttributes;
    let layerTree = getLayerTree();
    layerTree.map((item) => {
      if (item.hasOwnProperty("children")) {
        let res = item.children.filter((layer) => {
          if (layer.label == element.layer) {
            infoAttributes = {
              minimalInfoAttributes: layer.minimalInfoAttributes,
              detailedInfoAttributes: layer.detailedInfoAttributes,
            };
          }
        });
      }
    });
    return infoAttributes;
  }

  addSearchResultToMap(response_id) {
    const { mapComponent } = this.props;

    let resultVectorSource = this.state.resultVectorSource;

    response_id.forEach((element) => {

      if (
        element.layer !== "Ward Boundary"
        &&
        element.layer !== "Local body Boundary"
      ) {
        let infoAttributes = this.getInfoAttributes(element);

        let resultLayer = new VectorLayer({
          name: element.layer,
          infoAttributes: infoAttributes,
          visible: true,
          source: resultVectorSource,
          style: (feature, resolution) => styleFunction(feature, resolution),
        });

        let features = new GeoJSON().readFeatures(element.result);

        resultVectorSource.addFeatures(features);
        mapComponent.addLayer(resultLayer);
        this.setState({
          resultLayer: resultLayer,
        });
      }
      if (
        element.layer == "Ward Boundary"
      ) {
        let infoAttributes = this.getInfoAttributes(element);

        let resultLayer = new VectorLayer({
          name: element.layer,
          infoAttributes: infoAttributes,
          visible: false,
          source: resultVectorSource,
          // style: (feature, resolution) => styleFunction(feature, resolution),
        });

        let features = new GeoJSON().readFeatures(element.result);

        // resultVectorSource.addFeatures(features);
        // mapComponent.addLayer(resultLayer);
        this.setState({
          resultLayer: resultLayer,
        });

      }
    });

    mapComponent.getView().fit(resultVectorSource.getExtent());
  }

  getVectorLayer() {
    return new VectorLayer({
      source: this.state.vectorSource,
      projection: "EPSG:4326",
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
          fillOpacity: 0.2,
        }),
        stroke: new Stroke({
          color: "red",
          width: 2,
        }),
      }),
    });
  }

  addInteraction() {
    return new Draw({
      source: this.state.vectorSource,
      type: "Polygon",
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
          fillOpacity: 0.2,
        }),
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.5)",
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(0, 0, 0, 0.7)",
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
        }),
      }),
    });
    // return draw;
  }

  addModify = () => {
    return new Modify({
      source: this.state.vectorSource,
      type: "Polygon",
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.5)",
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(0, 0, 0, 0.7)",
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
        }),
      }),
    });
  };

  handlePolygonName = (e) => {
    this.setState({
      polygonName: e.target.value,
    });
  };


  render() {
    return (
      <React.Fragment>
        {this.state.showResult && (
          <ResultModel
            show={this.state.showResult}
            handleShowResult={this.handleShowResult}
            handleCloseResult={this.handleCloseResult}
            result={this.state.result}
          />
        )}
        {this.state.showSave && (
          <SaveModel
            show={this.state.showSave}
            polygonName={this.state.polygonName}
            handlePolygonName={this.handlePolygonName}
            // handleshowPolygon={this.handleshowPolygon}

            handleSaveClose={this.handleSaveClose}
            handleShowSave={this.handleShowSave}
            savePolygon={this.savePolygon}
          />
        )}
        {this.state.showTable && (
          <PolygonTable
            show={this.state.showTable}
            state={this.state}
            result={this.state.result}
            addSearchResultToMap={this.addSearchResultToMap}
            handleViewsavedPolygon={this.handleViewsavedPolygon}
            handleShowResult={this.handleShowResult}
            getInfoAttributes={this.getInfoAttributes}
            handleExportPdf={this.handleExportPdf}
            handleshowPolygon={this.handleshowPolygon}
            handleDrawConrtol={this.handleDrawConrtol}
            handleExportKml={this.handleExportKml}
            handleModify={this.handleModify}
          />
        )}
        <Widget
          placement="top"
          tooltipText="Select By Polygon"
          handleClick={this.handleDrawConrtol}
          img={selectRed}
          class="selectButton"
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: state.mapQuery.fetching,
    data: state.mapQuery.data,
    mapComponent: state.mapReducer.OlMap,
    response_id: state.mapQuery.response_id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    mapQueryPolygon: (layerlist, geometry) =>
      dispatch(mapQueryPolygon(layerlist, geometry)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectByPolygon);
