//const api = 'http://127.0.0.1:5000/'

//QA
const api = 'http://drishtitestadmin.ulgis.com/'


// const api = 'https://drishtiadmin.ulgis.com/'



export default api;