import React, { Component } from "react";
import { Row, Col, Table, Jumbotron } from "react-bootstrap";
import "./ReportModel.css";
import getAttributeName from "../../../../../../util/attributes";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import localStorageHandler from "../../../../../../util/storage";

class TableModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excelName: "Excel",
      layers: [],
      open: false,
    };
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSelect = (activeKey) => {
    this.setState({ activeKey });
  };
  componentDidMount = () => {
    this.getLayerNames();
    this.setState({
      ...this.state,
      excelName: this.props.layer,
    });
  };

  getLayerNames = () => {
    let layerTree = localStorageHandler.getLayerCategory();
    var layerMatch = {};
    layerTree.map((treeItem, index) => {
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.map((layer) => {
          if (layer.label == this.props.layer) {
            layerMatch = layer;
          }
        });
      }
    });
    this.setState(
      {
        layerMatch: layerMatch,
      }
    );
  };

  filterProperties(properties) {
    let filteredProps = {};
    let detailedInfoAttributes = null;
    if (properties != null) {
      if (this.state.layerMatch != null) {
        detailedInfoAttributes = this.state.layerMatch.detailedInfoAttributes;
      }
      if (detailedInfoAttributes)
        Object.keys(properties).filter((key) => {
          detailedInfoAttributes.forEach((attribute) => {
            if (key === attribute.field) {
              filteredProps[key] = properties[key];
            }
          });
        });
    }
    return filteredProps;
  }
  handleResponseHeader() {
    let filteredProps = {};
    let properties = {};
    this.props.result.map((res, i) => {
      properties = res.properties;
    });
    filteredProps = this.filterProperties(properties);
    if (filteredProps != null) {
      return (
        <tr key={-1}>
          {Object.keys(filteredProps).map((key) => {
            if (!key.includes("photo") && !key.includes("p_d_f_")) {
              return <th key={key}>{getAttributeName(key)}</th>;
            }
          })}
        </tr>
      );
    }
  }

  handleResponse() {
    let properties = {};
    let filteredProps = {};
    return this.props.result.map((res, i) => {
      properties = res.properties;
      filteredProps = this.filterProperties(properties);
      if (filteredProps != null) {
        return (
          <tr key={i}>
            {Object.keys(filteredProps).map((key) => {
              if (!key.includes("photo") && !key.includes("p_d_f_")) {
                return <td key={key}>{filteredProps[key]}</td>;
              }
            })}
          </tr>
        );
      }
    });
  }

  render() {
    const rowItems = this.handleResponse();
    const rowHead = this.handleResponseHeader();
    return (
      <div className="bufferReportContainerModel">
        <Jumbotron className="jumbo">
          <Row>
            <Col xs={12} sm={3} md={3} lg={3}>
              <h5 className="excelNam">{"Layer : " + this.props.layer}</h5>
            </Col>
            <Col xs={12} sm={2} md={2} lg={2}>
              <h5 className="excelNam"> {"Count : " + this.props.count}</h5>
            </Col>
            <Col xs={12} sm={7} md={7} lg={7}>
              <label>Excel Name</label>
              <input
                type="text"
                placeholder="Label"
                value={this.state.excelName}
                name="excelName"
                onChange={this.handleInputChange}
                required
                className="excelName"
              />
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-success"
                table={"table" + this.props.layer}
                filename={this.state.excelName}
                sheet="tablexls"
                white-color="Download as XLS"
              />
            </Col>
          </Row>
        </Jumbotron>
        <Table
          striped
          bordered
          condensed
          hover
          className="detailedInfoContent"
          id={"table" + this.props.layer}
        >
          <thead>{rowHead}</thead>
          <tbody>{rowItems}</tbody>
        </Table>
      </div>
    );
  }
}
export default TableModel;
