import React, { Component } from "react";
import { Button, ButtonToolbar, FormControl, InputGroup, Modal, Panel } from "react-bootstrap";
import CloseButton from '../../../../../../asset/svg/close_icon.svg';
import PolygonTable from "./polygonTable";
import Draggable from "react-draggable";

class SaveModel extends Component {


  render() {
    return (
      <div className="save-model-polygon">
        <Draggable >

          <Panel bsStyle="primary" 

          className="resultModal"
          >
            <Panel.Heading>
              <Panel.Title componentClass="h3">Save Polygon</Panel.Title>
              <ButtonToolbar className="pull-right button">
                <img
                  src={CloseButton}
                  className="panelheadbtn"
                  onClick={this.props.handleSaveClose}
                  alt="Close"
                />
              </ButtonToolbar>
            </Panel.Heading>
            <Panel.Body className="panelBody save-model-body">
              <InputGroup>
                <FormControl
                  type="input"
                  placeholder="Enter Polygon Name"
                  value={this.props.polygonName}
                  onChange={this.props.handlePolygonName}
                  required
                />
              </InputGroup>
              <Button
                bsStyle="danger"
                className="resultbutton"
                onClick={this.props.savePolygon}
              >
                Save
              </Button>
              {/* <Button
                bsStyle="danger"
                className="resultbutton"
                onClick={this.props.handleModify}
              >
                Modify
              </Button> */}
            
            </Panel.Body>
          </Panel>
          </Draggable>

      </div>
    );
  }
}

export default SaveModel;
