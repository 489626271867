import React, { Component } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  Row,
  Col,
  ControlLabel,
  Glyphicon,
  Checkbox,
} from "react-bootstrap";
// import Recaptcha from "react-recaptcha";
import ReCaptchaV2 from "react-google-recaptcha";
import localStorageHandler from "../../../util/storage/";
import { connect } from "react-redux";
import { doLogin, getPublicUserList } from "../login/data/actions";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import ForgotPasswordModel from "../forgot_password/ForgotPasswordModel";
import "./Login.css";
import Snackbar from "../../snackbar";
import { snackbarActions } from "../../snackbar/data/action";
import { CAPTCHA_KEY } from "../../../config/";
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      show: false,
      isVerified: true,
      publicAccessModelShow: false,
      localBodies: [{ id: "kannur_public", value: "Kannur Corporation" }],
      publicAccess: false,
      restrictedAccess: true,
      localBodyName: "",
      localBodyValue: false,
    };
  }

  handleForgotModel = () => {
    this.setState({ show: !this.state.show });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleInputChange = (event) => {
    this.setState({ ...this.state, localBodyName: event.target.value });
  };

  handlePublicAccess = () => {
    if (this.state.localBodyName === "") {
      this.setState({ ...this.state, localBodyValue: true });
    } else {
      const userCredentials = {
        username: this.state.localBodyName,
        password: "",
      };
      this.props.doLogin(userCredentials);
    }
  };

  validateForm = () => {
    return this.state.username.length > 0 && this.state.password.length > 0;
  };

  recaptchaLoaded = () => {
    console.log("capcha successfully loaded");
  };

  handleCaptchaChange = (value) => {
    if (value) {
      this.setState({
        isVerified: true,
      });
    }
  };

  // verifyCallback = (response) => {
  //   if (response) {
  //     this.setState({
  //       isVerified: true,
  //     });
  //   }
  // };

  handleLoginType = (e, type) => {
    e.preventDefault();
    this.setState({
      [type]: true,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.props.isLoading) return;

    const userCredentials = {
      username: this.state.username,
      password: this.state.password,
    };
    if (this.state.isVerified == false) {
      alert("Please verify that you are a human!");
    } else {
      this.props.doLogin(userCredentials);
    }
  };

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
    this.props.getPublicUserList();
  }

  componentDidUpdate(prevprops) {
    if (
      this.props.isAuthenticated &&
      this.props.isAuthenticated !== prevprops.isAuthenticated
    ) {
      localStorageHandler.setLoginResponse(this.props.userInfo);
      this.props.history.push("/dashboard");
    }
  }

  render() {
   
    return (
      <div className="loginContainer">
        <Loader />
        <ForgotPasswordModel
          show={this.state.show}
          handleForgotModel={this.handleForgotModel}
        />
        <Snackbar />
        <div className="login">
          <div className="appTitle">DRISHTI</div>

          <form
            onSubmit={(event) => this.handleSubmit(event)}
            className="loginFormTwo"
          >
            {!this.state.publicAccess && !this.state.restrictedAccess && (
              <Row>
                <Col xs={12} style={{ marginTop: "50px" }}>
                  <Button
                    block
                    bsSize="large"
                    onClick={(e) => this.handleLoginType(e, "publicAccess")}
                    className="loginButton"
                  >
                    Public Access
                  </Button>
                </Col>
                <Col xs={12} style={{ marginTop: "60px" }}>
                  <Button
                    block
                    bsSize="large"
                    className="loginButton"
                    onClick={(e) => this.handleLoginType(e, "restrictedAccess")}
                  >
                    Restricted Access
                  </Button>
                </Col>
              </Row>
            )}
            {this.state.publicAccess && (
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ marginTop: "30px" }}
                >
                  <FormGroup>
                    <ControlLabel>Select LocalBody</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="LocalBody"
                      name="localBodyName"
                      onChange={this.handleInputChange}
                      required
                    >
                      <option disabled selected>
                        Select LocalBody
                      </option>
                      {this.props.publicUsers.length > 0 &&
                        this.props.publicUsers.map((user) => {
                          return (
                            <option value={user.username} key={user.username}>
                              {user.first_name + " " + user.last_name}
                            </option>
                          );
                        })}
                    </FormControl>
                  </FormGroup>
                </Col>
                {this.state.localBodyValue && (
                  <Col xs={12}>
                    <p style={{ color: "red" }}>Please select a local body</p>
                  </Col>
                )}
                <Col xs={12} style={{ marginTop: "35px" }}>
                  <Button
                    block
                    bsSize="large"
                    bsStyle="success"
                    onClick={(e) => this.handlePublicAccess(e, "publicAccess")}
                    className="loginButton"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            )}
            {this.state.restrictedAccess && (
              <>
                <FormGroup controlId="username" bsSize="large">
                  <FormControl
                    autoFocus
                    type="text"
                    placeholder="Username"
                    value={this.state.username}
                    onChange={this.handleChange}
                    className="loginText"
                  />
                  <FormControl.Feedback>
                    <Glyphicon glyph="user" />
                  </FormControl.Feedback>
                </FormGroup>
                <FormGroup controlId="password" bsSize="large">
                  <FormControl
                    value={this.state.password}
                    onChange={(e) => this.handleChange(e)}
                    type="password"
                    placeholder="Password"
                    className="loginText"
                    autoComplete="off"
                  />
                  <FormControl.Feedback>
                    <Glyphicon glyph="lock" />
                  </FormControl.Feedback>
                </FormGroup>
                {(this.props.loginFailed || this.props.serverUnavilable) && (
                  <p style={{ color: "red" }}>
                    <strong>{this.props.message}</strong>
                  </p>
                )}
                <FormGroup>
                  <Row>
                    {/* <Col xs={12}>
                      <ReCaptchaV2
                        className="captcha"
                        sitekey={CAPTCHA_KEY}
                        onChange={this.handleCaptchaChange}
                      />
                    </Col> */}
                    <Col xs={12}>
                      <Button
                        block
                        type="submit"
                        bsSize="large"
                        bsStyle="success"
                        disabled={!this.validateForm()}
                        className="loginButton"
                      >
                        {this.props.loggingIn && !this.props.loginFailed ? (
                          <span>
                            Logging in... &nbsp;
                            <i className="fa fa-spinner fa-pulse fa-fw" />
                          </span>
                        ) : (
                          <span> Login</span>
                        )}
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xs={6}
                      // className="remember"
                      style={{
                        top: "10px",
                        justifyContent: "center",
                        display: "flex",
                        color: "#2B2B2B",
                      }}
                    >
                      <Checkbox inline>Remember Me</Checkbox>
                    </Col>
                    <Col
                      xs={6}
                      // className="forgot"
                      style={{
                        top: "10px",
                        justifyContent: "center",
                        display: "flex",
                        cursor: "pointer",
                      }}
                    >
                       <a
                        style={{
                          top: "10px",
                          right: "5px",
                          color: "#CA2208",
                          textDecoration: "none",
                        }}
                        onClick={this.handleForgotModel}
                      >
                        Forgot Password?
                      </a> 
                    </Col>
                  </Row>
                </FormGroup>
              </>
            )}
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggingIn: state.loginReducer.loggingIn,
    isAuthenticated: state.loginReducer.isAuthenticated,
    userInfo: state.loginReducer.userInfo,
    userData: state.loginReducer.userData,
    loginFailed: state.loginReducer.loginFailed,
    publicUsers: state.loginReducer.publicUsers,
    message: state.loginReducer.message,
    serverUnavilable: state.loginReducer.serverUnavilable,
    isLoading: state.loading.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doLogin: (userCredentials) => dispatch(doLogin(userCredentials)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
    getPublicUserList: () => dispatch(getPublicUserList()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
