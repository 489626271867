import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "react-circular-progressbar/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { Image } from "react-bootstrap";
import DashboardLayout from "./DashboardLayout";
import images from "../../asset/jpg/loginBg.jpg"
import axios from "axios";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }

  componentDidMount(){
    // const localbodyId = null;
    // axios.get(`map/localbodyabout/${localbodyId}/`).then((res)=>{
    //     console.log("response",res);
        
    // })
  }

  render() {
    // console.log("state result  values", this.state.data);
    return (
        <DashboardLayout>
      <div className="about-div">
        <div className="about-image-div">
        <Image src={images} className="about-image" fluid />;
        </div>
        <div className="about-discrtiption">
<p>
Could you clarify which long description you're asking for? It could be related to a project you're working on, like a React component, an API feature, or a specific functionality. Let me know so I can provide the most accurate and detailed response
</p>
        </div>
      </div>
      </DashboardLayout>
    );
  }
}
function mapStateToProps(state) {
  return {
    // show: state.mapSearch.showAdvancedFilterWindow,
    // layerColumns: state.advancedFilter.layerColumns,
    // layers: state.advancedFilter.layers,
    // tableData: state.advancedFilter.tableData,
    // recordCount: state.advancedFilter.recordCount,
    // fetching: state.advancedFilter.fetching,
    // data: state.advancedFilter.data,
    // report_result: state.advancedFilter.report_result,
    // reportLink: state.advancedFilter.reportLink,
    // memberLink: state.advancedFilter.memberLink,
    // fetchReportLoading: state.advancedFilter.fetchReportLoading,
    // memberData: state.advancedFilter.memberData,
    // reportUrls: state.advancedFilter.reportUrls,
    // reportFile: state.advancedFilter.reportFile,
    // downloadLoading: state.advancedFilter.downloadLoading,
    // unique_id: state.advancedFilter.unique_id,
    // downlaodPage: state.advancedFilter.page,
    // savedTemplate: state.advancedFilter.savedTemplate,
    // isSave: state.advancedFilter.isSave,
    // isDeleted: state.advancedFilter.isDeleted,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // setPageHeader: (title, subtitle, action) =>
    //   dispatch(setPageHeader(title, subtitle, action)),
    // fetchColumnsByLayer: (layer) => dispatch(fetchColumnsByLayer(layer)),
    // hide: () => dispatch(toggleAdvancedFilterWindow()),
    // isLoading: () => dispatch(loadingActions.isloading()),
    // loadingComplete: () => dispatch(loadingActions.loadingComplete()),
    // getLayers: () => dispatch(getLayers()),
    // showSnackbar: (snackbarMessage) =>
    //   dispatch(snackbarActions.showSnackbar(snackbarMessage)),

    // fetchDataInReport: (filterValues) =>
    //   dispatch(fetchDataInReport(filterValues)),
    // generateReport: (reportValues) => dispatch(generateReport(reportValues)),
    // getReportLink: (reportValues) => dispatch(getReportLink(reportValues)),
    // requestSearchedLayer: (layerToSearch, featureRequest) =>
    //   dispatch(requestSearchedLayer(layerToSearch, featureRequest)),

    // downloadReport: (reportValues) => dispatch(downloadReport(reportValues)),

    // clearFilterInitAction: () => dispatch(clearFilterInitAction()),
    // clearDownLoadInitAction: () => dispatch(clearDownLoadInitAction()),

    // saveReportTemplate: (payload) => dispatch(saveReportTemplate(payload)),
    // getReportTemplate: (payload) => dispatch(getReportTemplate(payload)),
    // deleteReportTemplate: (payload) => dispatch(deleteReportTemplate(payload)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(About));
