import APIRequest from "../util/api/APIRequest";

const getDashboardSummary = (localBodyId, wardId) => {
  return APIRequest({
    url: `dashboard/dashboardsummery/${localBodyId}/${wardId}/`,
    method: "GET",
  });
};

const getWards = (localBodyId) => {
  return APIRequest({
    url: `dashboard/wards/${localBodyId}/`,
    method: "GET",
  });
};

const getDashboardFilters = () => {
  return APIRequest({
    url: `mobileapi/get-base-tables/`,
    method: "GET",
  });
};

const getMemberDetails = (body) => {
  return APIRequest({
    url: `dashboard/socioeconomic/`,
    method: "POST",
    data: body,
  });
};

const getJobFilter = (category_id) => {
  return APIRequest({
    url: `dashboard/get_job/${category_id}/`,
    method: "GET",
  });
};

const getEducationFilter = (category_id) => {
  return APIRequest({
    url: `dashboard/get_education/${category_id}/`,
    method: "GET",
  });
};

const downloadMemberDetails = (data) => {
  return APIRequest({
    url: `/dashboard/socioeconomic_dwnload/`,
    method: "POST",
    data: data,
  });
};

const getSecondDashboardData = (id) => {
  return APIRequest({
    url: `/dashboard/get_ward_wise_property_data/`,
    method: "GET",
    params: {
      ward: id,
    },
    // data: data,
  });
};

const getWardList = () => {
  return APIRequest({
    url: `/dashboard/get_user_ward_data/`,
    method: "GET",
    // data: data,
  });
};

const getPropertyID=(data)=>{
  // get_properties_by_area
  return APIRequest({
    url: `/dashboard/get_properties_by_area/`,
    method: "GET",
    params: data,
  });
}

const DashboardService = {
  getDashboardSummary,
  getWards,
  getDashboardFilters,
  getMemberDetails,
  getJobFilter,
  getEducationFilter,
  downloadMemberDetails,
  getSecondDashboardData,
  getWardList,
  getPropertyID,
};
export default DashboardService;
