import React, { Component } from "react";
import { Button, ButtonToolbar, Col, Panel, Row } from "react-bootstrap";
import CloseButton from "../../../../../../asset/svg/close_icon.svg";
import { FaRegEye } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
import { CiExport } from "react-icons/ci";
import "./saveModel.css";
import { connect } from "react-redux";
import { mapQueryPolygon } from "../data/action";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer.js";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
import { GeoJSON } from "ol/format.js";
import tokml from "tokml";
import { saveAs } from "file-saver";
import { transform } from "ol/proj";
import MapSearchService from "../../../../../../service/MapSearchService";
import Draggable from "react-draggable";
import { GrDocumentDownload } from "react-icons/gr";
import { ADMIN_URL } from "../../../../../../config";

class PolygonTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      polygonData: JSON.parse(localStorage.getItem("Polygonname")) || [],
      reponseData: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.result !== this.props.result && this.props.result) {
      this.setState({
        polygonData: JSON.parse(localStorage.getItem("Polygonname")) || [],
      });
    }
  }

  handleView = (name) => {
    this.props.handleViewsavedPolygon(name);
  };

  handleDelete = (name) => {
    const polygonData = JSON.parse(localStorage.getItem("Polygonname")) || [];
    let layerList = [];
    let geometry = null;
    let polygonID = null;

    polygonData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key === name) {
          geometry = item[name].geometry;
          layerList = item[name].layer;
          polygonID = item[name].polygonID;
        }
      });
    });

    MapSearchService.mapQueryPolygon(layerList, geometry).then((res) => {
      if (res.data.length > 0) {
        const filteredData = res.data.filter((item) => item.layer !== "Local body Boundary" && !item.hasOwnProperty('excel_path'));
        this.handleVectorClear(filteredData, polygonID);
      }
    });

    const filteredData = polygonData.filter(
      (item) => !item.hasOwnProperty(name)
    );
    this.setState({
      polygonData: filteredData,
    });
    localStorage.setItem("Polygonname", JSON.stringify(filteredData));
  };

  handleReportDownload = (name) => {
    let url;
    // = "/static/report/polygon_report.xlsx";
    const polygonData = JSON.parse(localStorage.getItem("Polygonname")) || [];
    polygonData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key === name) {
          console.log("key item url", item[key].url);
          url = item[key].url;
        }
      });
    });
    const completeUrl = url.startsWith("http") ? url : `${ADMIN_URL}${url}`;

    if (url) {
      window.open(completeUrl);
      // saveAs(completeUrl, `${name}_report`);
    } else {
      alert("Report URL not found for this polygon.");
    }
  }

  handleVectorClear = (response_id, polygonId) => {
    let resultVectorSource = this.props.state.resultVectorSource;
    let vectorSource = this.props.state.vectorSource;

    response_id.forEach((element) => {
      let features = new GeoJSON().readFeatures(element.result);
      if (features && features.length > 0) {
        features.forEach((feature) => {
          let featureInSource = resultVectorSource
            .getFeatures()
            .find((f) => f.getId() === feature.getId());
          if (featureInSource) {
            resultVectorSource.removeFeature(featureInSource);
          }
        });
      }
    });

    if (polygonId) {
      let vectorFeature = vectorSource.getFeatureById(polygonId);
      if (vectorFeature) {
        vectorSource.removeFeature(vectorFeature);
      }
    }
  };

  handleAllclear = () => {
    const { vectorSource, draw, resultVectorSource } = this.props.state;
    const { mapComponent } = this.props;
    this.setState({
      polygonData: [],
    });
    mapComponent.removeInteraction(draw);
    vectorSource.clear();
    resultVectorSource.clear();
    localStorage.removeItem("Polygonname");
  };

  handleDraw = () => {
    this.props.handleDrawConrtol();
  };

  handleExportKml = () => {
    const { vectorSource } = this.props.state;

    const features = vectorSource.getFeatures();

    if (features.length === 0) {
      alert("No features to export.");
      return;
    }
    const polygonData = JSON.parse(localStorage.getItem("Polygonname")) || [];


    features.forEach((feature, index) => {
      const transformedFeature = feature.clone();
      transformedFeature.getGeometry().transform("EPSG:3857", "EPSG:4326");

      const geoJsonFormat = new GeoJSON();
      const geoJson = geoJsonFormat.writeFeatureObject(transformedFeature, {
        featureProjection: "EPSG:4326",
        dataProjection: "EPSG:4326",
      });

      if (!geoJson.properties) {
        geoJson.properties = {};
      }
      geoJson.properties.name = `Feature ${index + 1}`;

      try {
        let kmlData = tokml(geoJson);

        const styles = `
        <Style id="myStyle">
          <LineStyle>
            <color>ff0000ff</color>
            <width>2</width>
          </LineStyle>
          <PolyStyle>
            <color>7dff0000</color>
          </PolyStyle>
        </Style>
      `;

        const kmlWithStyles = kmlData.replace(/(<Document>)/, `$1\n${styles}`);

        const kmlWithStyledPlacemarks = kmlWithStyles.replace(
          /(<Placemark>)/g,
          `$1\n<styleUrl>#myStyle</styleUrl>`
        );

        const polygonName = polygonData[index] ? Object.keys(polygonData[index])[0] : `exported_polygon_${index + 1}`;
        const blob = new Blob([kmlWithStyledPlacemarks], {
          type: "application/vnd.google-earth.kml+xml",
        });

        const fileName = `${polygonName}.kml`; // Set the filename to the corresponding polygon name

        // const fileName = `exported_polygon_${index + 1}.kml`;
        saveAs(blob, fileName);
      } catch (error) {
        console.error("Error converting GeoJSON to KML:", error);
        alert("Failed to convert GeoJSON to KML.");
      }
    });
  };

  handleClose = () => {
    this.handleAllclear();
    this.props.handleshowPolygon();
  };

  render() {
    const { polygonData } = this.state;
    return (
      <Panel bsStyle="primary" className="polygonTablePanel">
        <Panel.Heading>
          <Panel.Title componentClass="h3">Saved Polygons</Panel.Title>
          <ButtonToolbar className="pull-right button">
            <img
              src={CloseButton}
              className="panelheadbtn"
              onClick={this.handleClose}
              alt="Close"
            />
          </ButtonToolbar>
        </Panel.Heading>
        <Panel.Body className="panelBody">
          {polygonData.length > 0 ? (
            polygonData.map((item, index) =>
              Object.keys(item).map((key) => (
                <Row className="polygonTableRow" key={`${key}-${index}`}>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    className="polygonTableHeading"
                  >
                    {key}
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} className="polygonTableIcon">
                    <FaRegEye onClick={() => this.handleView(key)} />
                    <MdOutlineDelete onClick={() => this.handleDelete(key)} />
                    <GrDocumentDownload onClick={() => this.handleReportDownload(key)} />
                  </Col>
                </Row>
              ))
            )
          ) : (
            <span>NO Data found</span>
          )}
          <Row style={{ marginTop: "7px" }}>
            <Col xs={12} sm={12} md={12} lg={12} className="polygonTableButton">
              <Button onClick={this.handleAllclear}>Clear</Button>
              <Button onClick={this.handleDraw}>Draw</Button>
              <Button onClick={this.handleExportKml}>Export</Button>
              <Button
                bsStyle="danger"
                className="resultbutton"
                onClick={this.props.handleModify}
              >
                Modify
              </Button>
            </Col>
          </Row>
        </Panel.Body>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
    response_id: state.mapQuery.response_id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    mapQueryPolygon: (layerlist, geometry) =>
      dispatch(mapQueryPolygon(layerlist, geometry)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PolygonTable);
