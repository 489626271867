import React, { Component } from "react";
import { connect } from "react-redux";
import closeButton from "../../../../asset/svg/close_icon.svg";
import {
  Modal,
  Panel,
  ButtonToolbar,
  Button,
  Form,
  FormGroup,
  Col,
  FormControl,
} from "react-bootstrap";
import "./AdvancedFilter.css";
import { toggleAdvancedFilterWindow } from "../navigation/navbar_form/data/action";
import { updateSearchResultLayer } from "../navigation/navbar_form/data/action";
import { loadingActions } from "../../../../components/loader/data/action";
import Attribute from "../../../report/components/Attribute";
import { snackbarActions } from "../../../../components/snackbar/data/action";
import {
  getLayers,
  fetchColumnsByLayer,
  getColumns,
  requestSearchedLayer,
  clearDownLoadInitAction,
  downloadReport,
} from "./data/action";
import { fetchDataByFilter } from "./data/action";
import FilterForm from "./components/FilterForm";
import { GeoJSON } from "ol/format.js";
import { Vector as VectorLayer } from "ol/layer.js";
import VectorSource from "ol/source/Vector.js";
import styleFunction from "../../map/helper/vectorStyle";
import ResultModel from "./components/ResultModel";
import localStorageHandler from "../../../../util/storage/";
import { CircularProgressbar } from "react-circular-progressbar";
import { PUSHER_KEY } from "../../../../../src/config";
import Draggable from "react-draggable";
var temp;
var channel;
class AdvancedFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReport: false,
      showAtrbt: false,
      reportStatus: false,
      filterFormCount: [],
      result: [],
      index: 0,
      layers: {},
      layerCategories: localStorageHandler.getLayerCategory(),
      data: {
        layer: "",
        layerCategory: "",
        layerName: "",
        fileFormat: "",
        layerName: "",
        fileType: "",
      },
      columnlist: [],
      layer: null,
      parameters: [],
      temp: [],
      page: "1",
      limit: "10",
      modelList: [],
      fileName: "",
      recordCount: 0,
      parentModel: "",
      layerToBeSearched: {},
      attributeValue: null,
      operationFieldValue: "",
      valueField: "",
      downloadConfirmation: false,
      readyToDownloadFlag: false,
      downloadPage: 1,
      downloadLimit: 100,
      isDownloading: false,
      percentage: 0,
    };
  }
  handleToUpdate = (value, index) => {
    this.setState({
      data: { ...this.state.data, ["temp".concat(index)]: value },
    });
  };

  handleShowReport = () => {
    this.setState({
      showReport: !this.state.showReport,
    });
  };

  handleInputChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
      operationFieldValue: event.target.value,
    });
  };

  handleInputChangeOperation = (event) => {
    this.props.showSnackbar("Please enter the Value");
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
      operationFieldValue: event.target.value,
    });
  };

  handleInputChangeDropdown = (value, index) => {
    this.setState({
      data: { ...this.state.data, ["value".concat(index)]: value },
      valueField: value,
    });
  };

  componentDidMount() {
    this.props.getLayers();

    const pusher = new Pusher(PUSHER_KEY, {
      cluster: "ap2",
      encrypted: true,
    });
    channel = pusher.subscribe("my-channel");
    channel.bind("my-event", (data) => {
      if (data.percentage > this.state.percentage)
        this.setState({ percentage: data.percentage });
    });
  }

  addFilterForm = () => {
    this.setState(
      {
        filterFormCount: [...this.state.filterFormCount, 1],
        data: {
          ...this.state.data,
          ["operation".concat(this.state.index)]: "=",
          ["temp".concat(this.state.index)]: [],
        },
        index: this.state.index + 1,
      },
      () => {
        if (this.state.index >= 2) {
          this.setState({
            data: {
              ...this.state.data,
              ["condition".concat([this.state.index - 1])]: "and",
            },
          });
        }
      }
    );
  };
  //delete row
  deleteField = (index) => {
    let tempData = this.state.data;
    let count = this.state.filterFormCount;
    count.pop();
    delete tempData["value".concat(index)];
    delete tempData["operation".concat(index)];
    delete tempData["temp".concat(index)];
    delete tempData["condition".concat(index) + 1];
    delete tempData["column".concat(index)];

    this.setState({ data: { ...this.state.data, tempData } });

    // delete.
  };

  getLayerByName(layer_name) {
    this.setState({
      data: { ...this.state.data, ["layer"]: layer_name },
    });
    let layer = this.props.layers.filter(
      (layer) => layer.layer_name == layer_name
    );
    if (layer.length > 0) {
      this.props.fetchColumnsByLayer(layer[0]);
    }
  }

  handleInputChangeAttribute = (
    event,
    index,
    field,
    model_name,
    parent_model,
    isClearValue
  ) => {
    // console.log("handle attribute ss",event.target.name);
    this.setState(
      {
        data: {
          ...this.state.data,
          [event.target.name]: event.target.value,
          ["display_name".concat(index)]: event.target.value,
          ["attributeField".concat(index)]: field,
          ["model".concat(index)]: model_name,
          ["parentModel".concat(index)]: parent_model,
        },
      },
      () => {
        isClearValue && this.handleClearValue([], index);
      }
    );
  };
  handleClearValue = (value, index) => {
    this.setState({
      data: {
        ...this.state.data,
        ["temp".concat(index)]: value,
        ["value".concat(index)]: "",
      },
    });
  };

  handleSubmit = (e) => {
    
    this.setState({ percentage: 0, page: 1 });
    const formData = new FormData(e.target.form);
    e.persist();
    e.preventDefault();
    e.stopPropagation();

    var data = {
      layer: "",
    };
    var form = e.target.form;

    Object.keys(form.elements).map((key) => {
      
      if (form.elements[key].name) {
        data[form.elements[key].name] = form.elements[key].value;
      }
    });
   

    let parentModel = this.state.data.layer.advancefilterinfo.filter(
      (attribute) => {
        return attribute.parent_model;
      }
    );
    
    // To get model names of selected columns in a list
    // List should contain the parent model name as first element
    let parentModelList = {};
    parentModelList = [parentModel[0].parent_model];
    let modelNames = {};
    
    modelNames = "disability_types"
    // this.state.parameters.map((model) => {
    //   return model.model_name;
    // });
   
    const finalModelList = [...parentModelList, ...modelNames];
    // To eliminate duplicate elements
    let modelList = [...new Set(finalModelList)];

    // logic: Id field of the parent model must be send along with the selected columns
    let idColumn = {
      field: "id",
      model_name: parentModel[0].parent_model,
      display_name: "ID Field",
    };
    this.state.parameters.push(idColumn);

    
    // Data to be send in request
    let filter = {
      page: "1",
      limit: this.state.limit,
      report: "True",
      parent_model: parentModel[0].parent_model,
      obj: this.getFilter(this.state.data),
      category_field: this.state.data.layer.category_field,
      category_id: this.state.data.layer.category_id,
      recordCount: this.props.recordCount,
      file_name: this.state.fileName,
      layer_name: this.state.data.layerName,
      module: "advanceresult",
      file_extension: "csv",
      columns: this.state.parameters,
      models: modelList,
    };
    console.log("filter",filter);

    const demoColumnList = [
      {
        field: 'name',
        model_name: 'disability_types',
        display_name: 'Name'
      },
    ];

    let filterDemo = {
      page: "1",
      limit: "10",
      report: "True",
      parent_model: parentModel[0].parent_model,
      // obj: this.getFilter(this.state.data),
      obj: [
        {
            display_name: "Building Name",
            attribute: "building_name",
            op: "=",
            value: "Sample Building",
            model_name: "BuildingModel",
            parent_model: "Building",
            join_op: "and"
        },
    ],
      category_field: this.state.data.layer.category_field,
      category_id: this.state.data.layer.category_id,
      recordCount: this.props.recordCount,
      file_name: "",
      layer_name: this.state.data.layerName,
      module: "advanceresult",
      file_extension: "csv",
      columns: demoColumnList,
      // columns: this.state.parameters,
      models: modelList,
    };
    this.setState({
      parentModel: parentModel[0].parent_model,
      modelList: modelList,
    });
    
    this.props.fetchDataByFilter(filter);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.response_id != this.props.response_id) {
      if (
        this.props.response_id &&
        this.props.response_id.features &&
        this.props.response_id.features.length > 0
      ) {
        this.addSearchResultToMap();
        this.setState(
          {
            result: this.props.response_id.features,
            layer: this.state.data.layer,
            reportStatus: true,
            showReport: true,
            // columnlist: [],
            // modelList: [],
            // parameters: [],
          },
          () => {
            // this.state.page === 1 && this.handleShowReport();
          }
        );
      } else {
        this.props.mapComponent.removeLayer(prevProps.resultLayer);
        this.props.showSnackbar("No Features Found");
      }
    }
    if (prevState.page != this.state.page) {
      // Data to be send in request
      let filter = {
        page: this.state.page,
        limit: this.state.limit,
        report: "True",
        file_name: this.state.fileName,
        file_extension: "",
        models: this.state.modelList,
        columns: this.state.parameters,
        layer_name: this.state.data.layerName,
        parent_model: this.state.parentModel,
        obj: this.getFilter(this.state.data),
        category_field: this.state.data.layer.category_field,
        category_id: this.state.data.layer.category_id,
        module: "advanceresult",
      };
      console.log("filter !!!!!", filter);
      const demoColumnList = [
        {
          field: 'name',
          model_name: 'disability_types',
          display_name: 'Name'
        },
      ];
      
      let filterDemo = {
        page: this.state.page,
        limit: "10",
        report: "True",
        file_name: "",
        file_extension: "",
        models: this.state.modelList,
        // columns: this.state.parameters,//
        columns: demoColumnList,
        layer_name: this.state.data.layerName,
        parent_model: this.state.parentModel,
        // obj: this.getFilter(this.state.data),
        obj: [
          {
              display_name: "Building Name",
              attribute: "building_name",
              op: "=",
              value: "Sample Building",
              model_name: "BuildingModel",
              parent_model: "Building",
              join_op: "and"
          },
          {
              display_name: "Height",
              attribute: "height",
              op: ">=",
              value: "50",
              model_name: "BuildingModel",
              parent_model: "Building",
              join_op: "and"
          }
      ],
        category_field: this.state.data.layer.category_field,
        category_id: this.state.data.layer.category_id,
        module: "advanceresult",
      };
      this.props.fetchDataByFilter(filter);
    }

    if (prevProps.downlaodPage != this.props.downlaodPage) {
      this.appendDownload();
    }
  }

  addSearchResultToMap() {
    const { mapComponent, response_id } = this.props;
    const layerToSearch = this.state.data.layer;
    let infoAttributes = {
      minimalInfoAttributes: layerToSearch.minimalInfoAttributes,
      detailedInfoAttributes: layerToSearch.detailedInfoAttributes,
    };
    let vectorSource = new VectorSource();
    let resultLayer = new VectorLayer({
      name: layerToSearch.value,
      infoAttributes: infoAttributes,
      visible: true,
      source: vectorSource,
      style: (feature, resolution) => styleFunction(feature, resolution),
    });

    let features = new GeoJSON().readFeatures(response_id);
    vectorSource.addFeatures(features);
    this.props.updateSearchResultLayer(resultLayer);
    mapComponent.addLayer(resultLayer);
    mapComponent.getView().fit(vectorSource.getExtent());
    if (mapComponent.getView().getZoom() > 20)
      mapComponent.getView().setZoom(20);
  }
  getFilter = (data) => {
    let filter = "";
    let obj = [];
    Object.keys(data).map((key) => {
      console.log("data getfilterss",data);
      if (key.includes("column")) {
        if (data["condition".concat(key.substr(-1))]) {
          filter = filter
            .concat(" ")
            .concat(data["condition".concat(key.substr(-1))])
            .concat(" ");
        }
        filter = filter
          .concat(data[key])
          .concat(data["operation".concat(key.substr(-1))])
          .concat("'")
          .concat(data["value".concat(key.substr(-1))])
          .concat("'");
        obj.push({
          display_name: data["display_name".concat(key.substr(-1))],
          attribute: data["attributeField".concat(key.substr(-1))],
          op: data["operation".concat(key.substr(-1))],
          value: data["value".concat(key.substr(-1))],
          model_name: data["model".concat(key.substr(-1))],
          parent_model: data["parentModel".concat(key.substr(-1))],
          join_op:
            data["condition".concat(key.substr(-1))] == undefined
              ? null
              : data["condition".concat(key.substr(-1))],
        });
      }
      
    });
    return obj;
  };

  clearFilter = () => {
    let { mapComponent, resultLayer } = this.props;
    mapComponent.removeLayer(resultLayer);
    this.setState({
      data: {
        layer: "",
        layerCategory: "",
      },
      reportStatus: false,
      filterFormCount: [],
      index: 0,
    });
  };

  handleInputChangeLayer = (event) => {
    this.props.layers.map((layer) => {
      if (layer.layer_id == event.target.value) {
        this.setState({
          data: {
            ...this.state.data,
            layer: layer,
            reportName: layer.label,
            layerName: layer.label,
          },
          modelList: [],
          parameters: [],
        });
      }
    });
  };

  feildValidation() {
    for (let index = 0; index < this.state.filterFormCount.length; index++) {
      if (
        this.state.data["operation".concat(index)] === "=" ||
        !this.state.data["column".concat(index)] ||
        !this.state.data["value".concat(index)]
      ) {
        return true;
      }
    }
  }
  closeAttri = () => {
    this.setState({
      showAtrbt: !this.state.showAtrbt,
    });
  };

  showAttribute = () => {
    if (this.state.columnlist.length == 0) {
      
      let selectedColumnsList = this.getFilter(this.state.data);
     
      selectedColumnsList.map((item) => {
        
        this.state.columnlist.push({
          field: item.attribute,
          model_name: item.model_name,
          display_name: item.display_name,
        });
      });
      
      this.setState({
        showAtrbt: !this.state.showAtrbt,
        columnlist: this.state.columnlist,
        parameters: this.state.parameters.concat(this.state.columnlist),
      });
      
    } else {
      this.setState({
        showAtrbt: !this.state.showAtrbt,
      });
    }
  };
  //USED IN ATRIBUTE COMPONENT
  // handleInputChangeSelect = (event, index) => {
  //   const { parameters } = this.state; // Assuming `parameters` is managed in the parent component's state
  //   const fieldName = event.target.value;
  
  //   // Check if the checkbox is already selected
  //   const isSelected = parameters.find((param) => param.field === fieldName);
  
  //   if (event.target.checked && !isSelected) {
  //     // Add the new field to the selected parameters
  //     this.setState({
  //       parameters: [...parameters, { field: fieldName }],
  //     });
  //   } else if (!event.target.checked && isSelected) {
  //     // Remove the field from the selected parameters
  //     this.setState({
  //       parameters: parameters.filter((param) => param.field !== fieldName),
  //     });
  //   }
  // };
  
  handleInputChangeSelect = (event, int) => {
    console.log("advancefilter",this.state.data.layer.advancefilterinfo, event.target.value);
    
    const attributeInformation = this.state.data.layer.advancefilterinfo.find(
      (attribute) => attribute.display_name === event.target.value
    );

    let newColumns = {
      field: attributeInformation.field,
      model_name: attributeInformation.model_name,
      display_name: event.target.value,
    };

    let flag = true;
    if (this.state.parameters.length != 0) {
      // Uncheck the selected attribute
      this.state.parameters.map((item, index) => {
        if (item.display_name == event.target.value) {
          flag = false;
          var array = [...this.state.parameters];
          // make a separate copy of the array
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ parameters: array }, () => {});
          }
        }
      });
    }
    if (flag) {
      this.setState(
        {
          parameters: [...this.state.parameters, newColumns],
        },
        () => {}
      );
    }
  };

  handleInputSelectAll = () => {
    let array = [];
    this.state.data.layer.advancefilterinfo.map((item) => {
      
      let newColumns = {
        field: item.field,
        model_name: item.model_name,
        display_name: item.display_name,
      };
      array.push(newColumns);
    });
    this.setState(
      {
        parameters: array,
      },
      () => {}
    );
   
  };

  //unselect all functionality
  handleInputUnselectAll = () => {
    this.setState(
      {
        parameters: [],
      },
      () => {}
    );
  };

  handlepaginationClick = (page) => {
    
    this.setState(
      {
        page: page,
        percentage: 0,
      },
      () => {
        //this.handleSubmit();
      }
    );
  };

  //New functions

  handleFileChange = (el, field) => {
    this.setState({
      data: {
        ...this.state.data,
        fileType: el.target.value,
      },
    });
  };

  handleFileFormatChange = (el, field) => {
    this.setState({
      data: {
        ...this.state.data,
        fileFormat: el.target.value,
      },
    });
  };

  downloadFile = () => {
    this.setState({ isDownloading: false });
    let urls =
      this.props.reportUrls &&
      this.props.reportUrls[0] &&
      this.props.reportUrls[0].urls;

    if (!urls) return;
    if (this.state.data.fileFormat === "csv") {
      const { building, member, merged } = urls.csv;
      if (this.state.data.layerName === "Residential") {
        this.state.data.fileType === "building" && window.open(building);
        this.state.data.fileType === "member" && member && window.open(member);
        this.state.data.fileType === "merged" && merged && window.open(merged);
      } else {
        building && window.open(building);
      }
    }
    if (this.state.data.fileFormat === "excel") {
      const { building, member, merged } = urls.excel;
      if (this.state.data.layerName === "Residential") {
        this.state.data.fileType === "building" && window.open(building);
        this.state.data.fileType === "member" && member && window.open(member);
        this.state.data.fileType === "merged" && merged && window.open(merged);
      } else {
        building && window.open(building);
      }
    }
  };


  generateReport = () => {
    this.props.recordCount > 1000 &&
      this.setState({ downloadConfirmation: true });
    this.setState({ downloadPercentage: 0, isDownloading: true });

    let reportValues = {
      page: 1,
      limit: this.state.downloadLimit,
      report: "True",
      // file_name: this.state.data.reportName,
      file_name: this.state.fileName,
      file_extension: this.state.data.fileFormat,
      models: this.state.modelList,
      columns: this.state.parameters,
      layer_name: this.state.data.layerName,
      parent_model: this.state.parentModel,
      obj: this.getFilter(this.state.data),
      category_field: this.state.data.layer.category_field,
      category_id: this.state.data.layer.category_id,
      unique_id: this.props.unique_id,
      recordCount: this.props.recordCount,
      maxPage: this.props.recordCount / this.state.downloadLimit,
      file_type: this.state.data.fileType ? this.state.data.fileType : null,
    };
    
    this.props.downloadReport(reportValues);
    this.setState({ downloadPage: 1 });
    // window.open(this.props.reportLink);
  };
  appendDownload = () => {
    try {
      if (!this.state.isDownloading) return;

      let maxCount = this.props.recordCount / this.state.downloadLimit;
      let pusherCount = (100 / maxCount).toFixed(2);
      let currentPage = Number(this.state.downloadPage);
      let currentPusherCount = Number(this.state.downloadPercentage);
      if (maxCount > currentPage) {
        let reportValues = {
          page: currentPage + 1,
          limit: this.state.downloadLimit,
          report: "True",
          // file_name: this.state.data.reportName,
          file_name: this.state.fileName,
          file_extension: this.state.data.fileFormat,
          models: this.state.modelList,
          columns: this.state.parameters,
          layer_name: this.state.data.layerName,
          parent_model: this.state.parentModel,
          obj: this.getFilter(this.state.data),
          category_field: this.state.data.layer.category_field,
          category_id: this.state.data.layer.category_id,
          unique_id: this.props.unique_id,
          recordCount: this.props.recordCount,
          maxPage: this.props.recordCount / this.state.downloadLimit,
          file_type: this.state.data.fileType ? this.state.data.fileType : null,
        };
        this.props.downloadReport(reportValues);
        this.setState({ downloadPage: currentPage + 1 });

        // let pusherCount = 100 / maxCount;

        var sumPercentage = Number(currentPusherCount) + Number(pusherCount);
        let finalPercentage = sumPercentage.toFixed(2);
        if (sumPercentage < 100) {
          this.setState({
            downloadPercentage: finalPercentage,
          });
        }
      } else {
        !this.props.downloadLoading &&
          this.props.reportFile &&
          this.setState({ readyToDownloadFlag: true, isDownloading: false });
      }
    } catch (e) {
      return;
    }
  };

  render() {
   
    
    return (
      <React.Fragment>
        {this.state.showReport && (
          <ResultModel
            show={this.state.showReport}
            handleShowReport={this.handleShowReport}
            result={this.props.response_table}
            layer={this.state.layer}
            recordCount={this.props.recordCount}
            handlepaginationClick={(page) => this.handlepaginationClick(page)}
            data={this.state.data}
            response_member={this.props.response_member}
            handleFileChange={this.handleFileChange}
            handleFileFormatChange={this.handleFileFormatChange}
            downloadFile={() => this.downloadFile}
            generateReport={() => this.generateReport}
            reportFile={this.props.reportFile}
            advanceLoading={this.props.advanceLoading}
            percentage={this.state.percentage}
            downloadPercentage={this.state.downloadPercentage}
            downloadLoading={this.props.downloadLoading}
          />
        )}
        <Draggable>
          <Modal
            className="advancedFilterModal"
            show={this.props.show}
            onHide={this.props.onHide}
            style={{ top: "45%" }}
          >
            <Modal.Header className="model-header">
              <Modal.Title className="modal-tile" componentClass="h3">
                Advanced Filter
              </Modal.Title>
              <ButtonToolbar className="pull-right button">
                <img
                  src={closeButton}
                  className="panelheadbtn"
                  onClick={this.props.hide}
                />
              </ButtonToolbar>
            </Modal.Header>

            <Modal.Body className="panelBodys">
              {/* <Modal.Body> */}
              <Form horizontal onSubmit={this.onSubmit}>
                <FormGroup controlId="formHorizontalEmail">
                  <Col md={4}>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="layerCategory"
                      value={this.state.data.layerCategory}
                      onChange={this.handleInputChange}
                      required
                    >
                      <option value="">Select Layer Category</option>
                      {this.state.layerCategories
                        .sort((a, b) => (a["label"] > b["label"] ? 1 : -1))
                        .map((layerCategory) => {
                          return (
                            <option
                              key={layerCategory.value}
                              value={layerCategory.layer_category_id}
                            >
                              {layerCategory.label}
                            </option>
                          );
                        })}
                    </FormControl>
                  </Col>
                  <Col md={4}>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="layer"
                      value={this.state.data.layer.layer_id}
                      disabled={this.state.data.layerCategory == ""}
                      onChange={this.handleInputChangeLayer}
                      required
                    >
                      <option value="">Select layer</option>
                      {this.props.layers.map((layer) => {
                        // if (layer.adv_fltr_status) {
                        if (
                          layer.layer_category_id ==
                          this.state.data.layerCategory
                        ) {
                          return (
                            <option key={layer.value} value={layer.layer_id}>
                              {layer.label}
                            </option>
                          );
                        }
                        // }
                      })}
                    </FormControl>
                  </Col>
                  <Col md={4}>
                    <Button
                      inline="true"
                      className="addfilterbtn"
                      style={{ padding: "3px" }}
                      onClick={this.addFilterForm}
                      disabled={
                        this.state.filterFormCount.length === 8 ||
                        this.state.data.layer === ""
                      }
                    >
                      <i className="glyphicon glyphicon-plus-sign" /> Add Filter
                    </Button>
                  </Col>
                </FormGroup>
                <FormGroup controlId="formHorizontalEmail"></FormGroup>
                <hr />
                {this.state.filterFormCount.map((element, index) => {
                  if (index < 8)
                    return (
                      <FilterForm
                        data={this.state.data}
                        handleInputChange={this.handleInputChange}
                        handleInputChangeDropdown={
                          this.handleInputChangeDropdown
                        }
                        handleInputChangeAttribute={
                          this.handleInputChangeAttribute
                        }
                        handleInputChangeOperation={
                          this.handleInputChangeOperation
                        }
                        handleToUpdate={this.handleToUpdate}
                        index={index}
                        key={index}
                        temp={temp}
                        deleteField={this.deleteField}
                        handleClearValue={this.handleClearValue}
                      />
                    );
                })}

                {this.props.advanceLoading && (
                  <div className="resultProgressBarDiv">
                    <div className="resultProgressBar">
                      <CircularProgressbar
                        value={this.state.percentage}
                        text={`${this.state.percentage}%`}
                      />
                    </div>
                  </div>
                )}

                <br />
                <Button
                  type="submit"
                  bsStyle="success"
                  className="pull-right"
                  disabled={
                    this.state.filterFormCount.length === 0 ||
                    this.state.operationFieldValue == "" ||
                    this.state.operationFieldValue == "=" ||
                    this.state.valueField == "" ||
                    this.state.parameters.length == 0 ||
                    this.feildValidation()
                  }
                  onClick={this.handleSubmit}
                >
                  Apply
                </Button>
                <Button
                  bsStyle="danger"
                  className="pull-left"
                  disabled={this.state.filterFormCount.length === 0}
                  onClick={this.clearFilter}
                >
                  Clear Filter
                </Button>
                <Button
                  bsStyle="danger"
                  className="pull-left report"
                  disabled={!this.state.data.layerCategory}
                  onClick={this.showAttribute}
                >
                  Select Columns To Display
                </Button>
                <Button
                  bsStyle="danger"
                  className="pull-left report"
                  disabled={!this.state.reportStatus}
                  //disabled={this.state.parameters.length == 0}
                  onClick={this.handleShowReport}
                >
                  Show Report
                </Button>
              </Form>
            </Modal.Body>

            {/*<Modal.Footer>
          </Modal.Footer>*/}
          </Modal>
        </Draggable>
        {this.state.data.layer != "" && (
          <Attribute
            data={this.state.data}
            columnStatus={this.state.columnStatus}
            show={this.state.showAtrbt}
            showAttri={this.showAttribute}
            closeAttri={this.closeAttri}
            parameters={this.state.parameters}
            handleInputChange={this.handleInputChange}
            handleInputChangeSelect={this.handleInputChangeSelect}
            handleInputSelectAll={this.handleInputSelectAll}
            handleInputUnselectAll={this.handleInputUnselectAll}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  
  return {
    show: state.mapSearch.showAdvancedFilterWindow,
    layers: state.advancedFilter.layers,
    mapComponent: state.mapReducer.OlMap,
    resultLayer: state.mapSearch.resultLayer,
    attributes: state.advancedFilter.attributes,
    response_id: state.advancedFilter.response_id,
    fetching: state.advancedFilter.fetching,
    data: state.advancedFilter.data,
    recordCount: state.advancedFilter.recordCount,
    responseData: state.advancedFilter.responseData,
    response_table: state.advancedFilter.response_table,
    response_member: state.advancedFilter.response_member,
    downlaodPage: state.advancedFilter.page,
    unique_id: state.advancedFilter.unique_id,

    reportUrls: state.advancedFilter.reportUrls,
    reportFile: state.advancedFilter.reportFile,
    downloadLoading: state.advancedFilter.downloadLoading,
    advanceLoading: state.advancedFilter.advanceLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    hide: () => dispatch(toggleAdvancedFilterWindow()),
    getLayers: () => dispatch(getLayers()),
    fetchColumnsByLayer: (layer) => dispatch(fetchColumnsByLayer(layer)),
    updateSearchResultLayer: (searchLayer) =>
      dispatch(updateSearchResultLayer(searchLayer)),
    isLoading: () => dispatch(loadingActions.isloading()),
    loadingComplete: () => dispatch(loadingActions.loadingComplete()),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
    getColumns: () => dispatch(getColumns()),
    fetchDataByFilter: (filterValues) =>
      dispatch(fetchDataByFilter(filterValues)),
    requestSearchedLayer: (layerToSearch, featureRequest) =>
      dispatch(requestSearchedLayer(layerToSearch, featureRequest)),
    generateReport: (reportValues) => dispatch(generateReport(reportValues)),
    clearDownLoadInitAction: () => dispatch(clearDownLoadInitAction()),

    downloadReport: (reportValues) => dispatch(downloadReport(reportValues)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFilter);
