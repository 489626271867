import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import "./AddAssetPanel.css";
import { ADMIN_URL } from "../../../../../../src/config";
import { accessibleLayers } from "./data/actions";
import LocalStorageHandler from "../../../../../util/storage";
import OverlayPanel from "../overlay_panel/OverlayPanel";

class AddAssetPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layer_id: "",
      app_name: "",
      model_name: "",
    };
  }

  handleInputChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
    let layerInfo = this.props.layers_list.find(
      (attribute) => attribute.layer_name === event.target.value
    );
    this.setState({
      app_name: layerInfo.app_name,
      model_name: layerInfo.model_name,
    });
  };

  componentDidMount() {
    this.props.accessibleLayers();
  }

  render() {
    const { showWidget } = this.props;
    if (showWidget === "5") {
      return (
        <OverlayPanel
          showControl={this.props.showAssetPanelControl}
          handleShowControl={this.props.handleShowAssetPanelControl}
          titleName=" Add New Asset"
          headClass="addassetpanel"
          bodyClass="addassetpanelBody"
        >
          <Row>
            <FormGroup className="marginSelect">
              <Col xs={12} sm={12} md={12} lg={12}>
                <ControlLabel style={{ fontWeight: "bold" }}>
                  Layer
                </ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  name="bufferType"
                  value={this.state.layer_name}
                  onChange={this.handleInputChange}
                  required
                >
                  <option value="">Select Layer</option>
                  {this.props.layers_list != null
                    ? this.props?.layers_list?.map((layer, i) => {
                        return (
                          <option key={i} value={layer.layer_name}>
                           {layer?.layer_name.replace(/_/g, " ")}
                          </option>
                        );
                      })
                    : ""}
                </FormControl>
              </Col>
            </FormGroup>
          </Row>
          <Row>
            <Button
              className="addassetpanelButton "
              bsStyle="success"
              bsSize="small"
              onClick={(e) => {
                window.open(
                  ADMIN_URL +
                    // "admin/" +
                    "map/login/" +
                    LocalStorageHandler.getToken() +
                    "/" +
                    this.state.app_name +
                    "/" +
                    this.state.model_name +
                    "/add/",
                  "_blank"
                );
              }}
            >
              Add
            </Button>
          </Row>
        </OverlayPanel>
      );
    } else return null;
  }
}

function mapStateToProps(state) {
  return {
    layers_list: state.addAsset.layers_list,
    fetching: state.addAsset.fetching,
    showWidget: state.mapReducer.showWidget,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    accessibleLayers: () => dispatch(accessibleLayers()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAssetPanel);
