import React from 'react';
import TableFormat from './TableFormat';
import './DetailedInfo.css'; // Import a CSS file for additional styles

function DetailInfoTable(props) {
    const name = props.name.replace(/_/g, ' ');

    return (
        props.data.length !== 0 &&(
        <TableFormat displayName={name}>
            <table className="detail-info-table">
                <tbody>
                    {props.data.map((values, i) => (
                        <React.Fragment key={i}>
                            {values.display_name === "Floor Area" ? (
                                <tr>
                                    <td colSpan={2} className="floor-area-header">
                                        {values.title}
                                    </td>
                                </tr>
                            ) : null}
                            <tr>
                                {(values.values !== "NA" || values.values !== null) &&(
                                    <>
                                <td className="detail-cell" style={{fontWeight: 'bolder'}}>{values.display_name}</td>
                                <td className="detail-cell">{values.values}</td>
                                </>
                            )}
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </TableFormat>
        )
    );
}

export default DetailInfoTable;




// import React from 'react'
// import TableFormat from './TableFormat'

// function DetailInfoTable(props) {
//     const name = props.name.replace(/_/g, ' ')

//     return (
//         <TableFormat displayName={name}>
//             <>

//                 <tbody>

//                     {props.data.map((values, i) => (
//                         <>
//                             {values.display_name === "Floor Area" ?
//                                 <tr>
//                                     <td colSpan={2} style={{ backgroundColor: "#ca2208", textAlign: "center", fontWeight: "bolder" }}>{values.title}</td>
//                                 </tr>
//                                 :
//                                 <></>}
//                             <tr key={i} >

//                                 <td>{values.display_name}</td>
//                                 <td>{values.values}</td>
//                             </tr>
//                         </>
//                     ))}
//                 </tbody>
//             </>
//         </TableFormat>
//     )
// }

// export default DetailInfoTable