import React, { Component } from "react";
import {
  Panel,
  ButtonToolbar,
  Table,
  ButtonGroup,
  Button,
  Badge,
  Label,
} from "react-bootstrap";
// import Overlay from "ol/Overlay.js";
// import "./MinimalInfo.css";
import closeButton from "../../../../../asset/svg/close_icon.svg";
import getLayerTree from "../../helper/LayerTreeLoader";
// import DataFormat from "../../helper/DataFormat";
// import { transform } from "ol/proj";
// import proj4 from 'proj4';
import "./style.css";
import OverlayPanel from "../overlay_panel/OverlayPanel";
import CheckboxTree from "react-checkbox-tree";
import { connect } from "react-redux";
import LayerTree from "../layer_tree/LayerTree";

class SearchLayerPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOverlay: null,
      minimalInfoContent: null,
      checked: [],
      expanded: [],
      showLayerControl: true,
    };
    this.popup = React.createRef();
    this.popup_close = React.createRef();
    this.popup_more = React.createRef();
    this.zoomTo = React.createRef();
    this.onIncrement = React.createRef();
    this.onDecrement = React.createRef();
    this.streeView = React.createRef();
  }

  VisbleFilteredLayer=()=>{

  }

  getMapLayerTree() {
    let layerTree = getLayerTree();
    
    let newLayerTree = [];
    layerTree.map((treeItem, i) => {
      let j = treeItem.layer_category_order - 1;
      newLayerTree[j] = layerTree[i];
    });
    newLayerTree.map((treeItem, i) => {
      let children = [];
      if (treeItem.hasOwnProperty("children")) {
        if (treeItem.children.length > 1) {
          treeItem.children.map((layer, i) => {
            let j = layer.layer_order - 1;
            children[j] = treeItem.children[i];
          });
          treeItem.children = children;
        }
      }
    });
    let mapLayerTree = [];
    newLayerTree.map((treeItem, i) => {
      let isBaseLayer = true;
      if (treeItem.hasOwnProperty("children")) {
        if (treeItem.children.length > 0) {
          treeItem.children.forEach((layer) => {
            if (layer.layer_type != "layer") {
              isBaseLayer = false;
            }
          });
          if (isBaseLayer) {
            mapLayerTree.push(newLayerTree[i]);
          }
        }
      }
    });
    
    return mapLayerTree;
  }

  showSelectedLayer(checkedLayerName) {
    const { mapLayers,feature_data } = this.props;
    const ward = feature_data.features.map((value)=>value.properties.ward)
    console.log("ward values",ward[0]);
    const cql_filter = `ward_id=${ward[0]}`
    
    for (let i = 0; i < mapLayers.length; i++) {
      let layer = mapLayers[i].getProperties().name;
      if (checkedLayerName.includes(layer)) {
        mapLayers[i].getSource().updateParams({ 'CQL_FILTER': cql_filter });
        mapLayers[i].setVisible(true);
      } else {
        mapLayers[i].setVisible(false);
      }
    }
    this.setState({
      checked: checkedLayerName,
    });
   
  }

  handleUnCheckLayer = () => {
    const { mapLayers } = this.props;
    for (let i = 0; i < mapLayers.length; i++) {
      mapLayers[i].setVisible(false);
    }
    this.setState({
      checked: [],
    });
  };


  render() {
    // console.log("proprs vlaue",this.props.relatedValues.road_direction_id.actual_value);
    // const items = this.formatInfo();
    // const layerCount = this.formatLayerCount();
    const {feature_data} = this.props
    // const ward = feature_data?.features?.getProperties().ward
    console.log('PROPS VALUES',this.props);
    // console.log('PROPS ward VALUES',ward);

    return (
      <>
        {/* <div
          // className="ol-popup"
          className="search-popup"
          //   ref={this.popup}
          //   hidden={!this.props.isMinimalInfoVisible}
        > */}
          {/* <Panel bssStyle="success" className="minimalInfoPopup">
            <Panel.Heading className="minimalInfoHead">
              <Panel.Title componentClass="h3">
                <Label style={{ backgroundColor: "#142240" }}>
                  Total Features
                </Label>
              </Panel.Title>
              <ButtonToolbar className="pull-right button Btn_toolbar">
                <ButtonGroup>
                  <button className="mpanelheadbtn" ref={this.popup_close}>
                    <img src={closeButton} />
                  </button>
                </ButtonGroup>
              </ButtonToolbar>
            </Panel.Heading>
            <Panel.Body className="minimalInfoPanelBody"> */}
           
              {/* {this.props.minimalInfoContents == null && (
                <h5>There are no Layers selected</h5>
              )} */}
              <OverlayPanel
                showControl={this.state.showLayerControl}
                // handleShowControl={this.props.handleShowControl}
                titleName="Layers"
                handleUnCheckLayer={this.handleUnCheckLayer}
                layerCheck={true}
              >
                <CheckboxTree
                  className="layerTree"
                  nodes={this.getMapLayerTree()}
                  expandOnClick
                  checked={this.state.checked}
                  expanded={this.state.expanded}
                  onCheck={(checked) => this.showSelectedLayer(checked)}
                  onExpand={(expanded) => this.setState({ expanded })}
                />
              </OverlayPanel>

            {/* </Panel.Body>
          </Panel> */}
        {/* </div> */}
        {/* <div
          id="street-view"
          style={{ width: "50%", height: "400px", float: "left" }}
        ></div> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log("sttae values",state);
  
  return {
    mapComponent: state.mapReducer.OlMap,
    layers: state.mapReducer.mapLayers,
    enabled: state.mapReducer.widgetEnable,
    feature_popup: state.mapSearch.feature_popup,
    feature_data: state.mapSearch.feature_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateMapReference: (mapComponent) =>
      dispatch(updateMapReference(mapComponent)),
    fetchDataByValue: (searchBy, query) =>
      dispatch(fetchDataByValue(searchBy, query)),
  };
}

export default connect(mapStateToProps, {})(SearchLayerPopup);
