import APIRequest from "../util/api/APIRequest";
import mapDefaults from "../components/map_viewer/map/helper/mapDefaults";

const fetchDataByType = (searchBy, query, localbody) => {
  let searchtype = mapDefaults.getLayerToSearch();
  return APIRequest({
    url: `map/basicsearch/` + searchBy + `/`,
    method: "GET",
    // params: {
    //   type: searchtype,
    //   value: searchBy,
    //   query: query,
    // },
  });
};

const fetchDataByValue = (searchBy, query, localbody) => {
  return APIRequest({
    url: `map/basicsearchresult/` + searchBy + `/`,
    method: "GET",
    params: {
      search_value: query,
    },
  });
};

const fetchValueByAttribute = (
  model,
  attribute,
  data_type,
  category_field,
  category_id,
  isSearch,
  searchParam
) => {
 
  return APIRequest({
    url:
      `map/advancesearchdistinct/` +
      model +
      `/` +
      attribute +
      `/` +
      data_type +
      `/`,
    method: "GET",
    params: {
      category_field: category_field,
      category_id: category_id,
      isSearch: isSearch,
      searchParam: searchParam,
    },
  });
};

const fetchDataByFilter = (filterValues) => {
  return APIRequest({
    // url: `map/advancesearchresult/`,
    url: `map/reportresult_for_advaced/`,
    method: "POST",
    data: filterValues,
  });
};

const fetchDataInReport = (filterValues) => {
  return APIRequest({
    // url: `map/reportresult/`,
    url: `reports/view_reports/`,
    method: "POST",
    data: filterValues,
  });
};

// const fetchDataInReport = (filterValues) => {
//   return APIRequest({
//     url: `reports/view_reports/`,
//     method: "POST",
//     data: filterValues,
//   });
// };

// const downloadReport = (reportValues) => {
//   return APIRequest({
//     url: `reports/view_reports/`,
//     method: "POST",
//     data: reportValues,
//   });
// };

const generateReport = (reportValues) => {
  return APIRequest({
    // url: `reports/view_reports/`,
    url: `map/reportresult/`,
    method: "POST",
    data: reportValues,
  });
};



// const generateReport = (reportValues) => {
//   return APIRequest({
//     url: `reports/view_reports/`,
//     method: "POST",
//     data: reportValues,
//   });
// };

const getReportLink = (reportValues) => {
  return APIRequest({
    url: `map/reportoutput/`,
    method: "POST",
    data: reportValues,
  });
};

const mapQueryPolygon = (layerlist, geometry) => {
  return APIRequest({
    url: "map/selectpolygon/",
    method: "POST",
    data: layerlist,
  });
};

// const downloadPdf = (unique_id) => {
//   return APIRequest({
//     url: "asset/values",
//     method: "GET",
//     params: {
//       type: table,
//       value: searchBy,
//       query: query,
//     },
//   });
// };

//New API for download

const downloadReport = (reportValues) => {
  return APIRequest({
    // url: `map/reportresult2/`,
    url: `reports/view_reports/`,
    method: "POST",
    data: reportValues,
  });
};

const saveReportTemplate = (templateValues) => {
  return APIRequest({
    url: "map/saved_template/",
    method: "POST",
    data: templateValues,
  });
};

const getReportTemplate = (templateValues) => {
  return APIRequest({
    url: "map/get_template/" + templateValues.uid,
    method: "GET",
    data: templateValues,
  });
};

const deleteReportTemplate = (templateValues) => {
  return APIRequest({
    url: "map/del_template/" + templateValues.data.id,
    method: "DELETE",
    data: templateValues,
  });
};

const MapSearchService = {
  fetchDataByType,
  fetchDataByValue,
  fetchValueByAttribute,
  fetchDataByFilter,
  fetchDataInReport,
  generateReport,
  getReportLink,
  // fetchDataByAttribute,
  // fetchDataByAttribute,
  mapQueryPolygon,
  downloadReport,
  saveReportTemplate,
  getReportTemplate,
  deleteReportTemplate,
  // downloadPdf
};
export default MapSearchService;
