import React, { Component } from "react";
import upload from "../../../../../asset/svg/upload.svg";
import './UploadKml.css';
import Widget from "../../../../../containers/widget/widget";
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import KML from 'ol/format/KML';

class UploadKml extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: [], // Changed to handle multiple files
            kmlLayers: [], // Array to hold multiple KML layers
            isKmlUploaded: false, 
            popUp: false,
        };
    }

    handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        this.setState({ selectedFiles }, () => {
            this.uploadKmlFiles();
        });
    };

    handleFileUpload = () => {
        this.setState(prevState => ({
            popUp: !prevState.popUp
        }));
    };

    uploadKmlFiles = () => {
        const { selectedFiles } = this.state;
        const { mapComponent } = this.props;

        selectedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const kmlData = e.target.result;
                    const format = new KML();
                    const features = format.readFeatures(kmlData, {
                        featureProjection: 'EPSG:3857'
                    });

                    const vectorSource = new VectorSource({
                        features: features
                    });

                    const resultLayer = new VectorLayer({
                        source: vectorSource
                    });

                    // Add the new KML layer to the map
                    mapComponent.addLayer(resultLayer);

                    // Update state with the new layer
                    this.setState(prevState => ({
                        kmlLayers: [...prevState.kmlLayers, resultLayer],
                        isKmlUploaded: true
                    }));
                } catch (error) {
                    console.error("Error processing KML file:", error);
                    alert("Failed to upload KML file. Please ensure it's valid.");
                }
            };

            reader.onerror = () => {
                alert("Error reading the file. Please try again.");
            };

            reader.readAsText(file);
        });
    };

    clearKmlFiles = () => {
        const { mapComponent } = this.props;

        this.state.kmlLayers.forEach(layer => {
            mapComponent.removeLayer(layer);
        });

        this.setState({ kmlLayers: [], isKmlUploaded: false });
    };

    handleFileUploadClick = () => {
        document.getElementById('file-upload').click();
    };

    render() {
        const { isKmlUploaded } = this.state;

        return (
            <>
                <div className="upload-widget-button">
                    <Widget
                        placement="top"
                        tooltipText="Upload KML"
                        handleClick={this.handleFileUpload}
                        img={upload}
                    />
                </div>
                
                {this.state.popUp && (
                    <div className="popup-options">
                        <input
                            id="file-upload"
                            type="file"
                            accept=".kml"
                            style={{ display: "none" }}
                            multiple // Allow multiple file selection
                            onChange={this.handleFileChange}
                        />
                        <button 
                            onClick={this.handleFileUploadClick} 
                            className="popup-option"
                            style={{ width: '100%' }}
                        >
                            Upload KML
                        </button>
                        <button 
                            onClick={this.clearKmlFiles} 
                            className="popup-option" 
                            disabled={!isKmlUploaded}
                            style={{ width: '100%' }}
                        >
                            Clear KML
                        </button>
                    </div>
                )}
            </>
        );
    }
}

export default UploadKml;