import React, { Component } from "react";
import { connect } from "react-redux";
import "../style.css";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  Legend,
  LinearScale,
  BarElement,
} from "chart.js";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale
);
import { getDashboardSummary, getWards } from "../data/action";
import Crop from "ol-ext/filter/Crop";
import Map from "ol/Map.js";
import Draw from "ol/interaction/Draw.js";
import { XYZ, OSM } from "ol/source/";
import View from "ol/View.js";
import { GeoJSON, WFS } from "ol/format.js";
import { defaults as defaultControls } from "ol/control.js";
import { Group as LayerGroup, Tile as TileLayer } from "ol/layer.js";
import { MousePosition, ScaleLine } from "ol/control/";
import { createStringXY } from "ol/coordinate.js";
import { transform } from "ol/proj";
import VectorSource from "ol/source/Vector.js";
import getLayerTree from "../../map_viewer/map/helper/LayerTreeLoader";
import generateLayer from "../../map_viewer/map/helper/LayerLoader";
import mapDefaults from "../../map_viewer/map/helper/mapDefaults";
import LocalStorageHandler from "../../../util/storage";
import axios from "axios";
import { AUTH_KEY } from "../../../config";
import { getLayers } from "../../map_viewer/map_header/advanced_filter/data/action";
import Feature_info from "../../map_viewer/map/widgets/feature_info";
import { updateMapReference } from "../../map_viewer/map/map_component/data/actions";
import DefaultExtend from "../../map_viewer/map/widgets/default_extend/DefaultExtend";
import * as olExtent from 'ol/extent';
import DropdownService from "../../../service/DropdownService";


class RoadMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diagrams: null,
      layers: this.getLayersFromTree(),
      baseLayers: this.getBaseLayers(),
      vectorSource: null,
      clickEvent: null,
    } 
  }

  componentDidMount() {
    this.createOlMap();
    this.props.dropdownLayer()
    this.props.getLayers();
    this.setState({
      vectorSource: new VectorSource(),
    });
  this.props.getWards(LocalStorageHandler.getLocalBody().local_body_id);
  const cql_filter = LocalStorageHandler.getLoginResponse().cql_filter
  this.setState({
    localBodyId:cql_filter
  })
  this.RoadLayer();
  // this.addSearchResultToMap()
  }

  componentDidUpdate(prevProps) {
    if (this.props.layerData != prevProps.layerData) {
      this.RoadLayer()
    }
    if (prevProps.featureData != this.props.featureData ) {
      // if (this.props.featureData.features.length > 0) {
        this.addSearchResultToMap();
      // }
    }
  }

  visibleLayerExtend = () =>{
    
  }
  

  handleMapRenderComplete = (evt) => {
    this.setState({ renderEvent: evt });
  };

  createCropFeature = async () => {
    
    let layerTree = getLayerTree();
    let cropLayers;
    let feature;
    layerTree.map((treeItem) => {
      
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.forEach((layer) => {
          if (layer.layer_name == "drishti:ipms_localbody") {
            cropLayers = layer;
          }
        });
      }
    });
    await axios
      .get(cropLayers && cropLayers.url.concat("wfs"), {
        params: {
          version: "1.3.0",
          request: "GetFeature",
          outputFormat: "application/json",
          service: "WFS",
          typeName: cropLayers && cropLayers.layer_name,
          srsname: "EPSG:3857",
          cql_filter: cropLayers && cropLayers.cql_filter,
          authkey: AUTH_KEY,
        },
      })
      .then((response) => {
        feature = new GeoJSON().readFeatures(response.data);
      });
      
    return feature;
  };

  getBaseLayers = () => {
    const osm = new TileLayer({
      name: "OSM",
      visible: true,
      isBaseLayer: true,
      source: new OSM({
        attributions: ["<b><a href='http://ults.in'>ULTS</a></b>"],
        crossOrigin: "anonymous",
      }),
    });
    const Satellite = new TileLayer({
      name: "Satellite",
      isBaseLayer: true,
      visible: false,
      source: new XYZ({
        url:
          "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiYWptYWxhdG0iLCJhIjoiY2tjNzFvbWNjMHg2ajJ2bWdlNzlmamU0MSJ9.z_qLRhyXbaBnJV4edvis-g",
        crossOrigin: "anonymous",
      }),
    });
    const googleSatellite = new TileLayer({
      name: "Google Satellite",
      isBaseLayer: true,
      visible: false,
      source: new XYZ({
        url: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
        crossOrigin: "anonymous",
      }),
    });
    const googleMap = new TileLayer({
      name: "Google Map",
      isBaseLayer: true,
      visible: false,
      source: new XYZ({
        url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
        crossOrigin: "anonymous",
      }),
    });
    const googleHybrid = new TileLayer({
      name: "Google Hybrid",
      isBaseLayer: true,
      visible: true,
      source: new XYZ({
        url: "http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
        crossOrigin: "anonymous",
      }),
    });

    let baseLayerData = LocalStorageHandler.getBaseLayers();
    let baseLayers = [];
    baseLayers.push(osm);
    baseLayers.push(Satellite);
    baseLayers.push(googleMap);
    baseLayers.push(googleSatellite);
    baseLayers.push(googleHybrid);

    //this is for drone image
    baseLayerData.map(async (layerItem) => {
      if (layerItem.layer_type == "base_layer") {
        let layer = generateLayer(
          layerItem.layer_type,
          layerItem.label,
          layerItem.layer_name,
          layerItem.type,
          layerItem.url,
          null,
          null,
          layerItem.visibility,
          null,
          layerItem.opacity,
          layerItem.crop
        );
        baseLayers.push(layer);
      }
    });
    return baseLayers;
  };

  getLayersFromTree() {

    let layerTree = getLayerTree();   
    let layersFromTree = [];
    let mapLayers = [];

    layerTree.map((treeItem) => {
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.forEach((layer) => {
          if (layer.layer_type == "layer") {
            layersFromTree.push(layer);
          }
        });
      }
    });


    // this for the road layers
    layersFromTree.forEach((treeLayerItem) => {
      let url = treeLayerItem.url;
      if (url != undefined) {
        let infoAttributes = {
          minimalInfoAttributes: treeLayerItem.minimalInfoAttributes,
          detailedInfoAttributes: treeLayerItem.detailedInfoAttributes,
        };
        let layer = generateLayer(
          treeLayerItem.layer_type,
          treeLayerItem.value,
          treeLayerItem.layer_name,
          treeLayerItem.type,
          treeLayerItem.url,
          treeLayerItem.style,
          treeLayerItem.cql_filter,
          false,
          infoAttributes,
          treeLayerItem.opacity,
          false
        );
        mapLayers.push(layer);
      }
    });
     return mapLayers;  
  }

createOlMap = () => {
  const { layers, baseLayers } = this.state;
  let localBody = null;
  if (LocalStorageHandler.getLocalBody() !== null) {
    localBody = LocalStorageHandler.getLocalBody().local_body_name;
    this.setState({
      localBody: localBody
    })
  }
  this.mapComponent = new Map({
    controls: defaultControls({
      collapsible: false,
    }).extend([
      new ScaleLine(),
      new MousePosition({
        undefinedHTML: "",
        coordinateFormat: createStringXY(4),
        projection: "EPSG:4326",
      }),
    ]),
    layers: [
      new LayerGroup({ layers: baseLayers }),
      new LayerGroup({ layers }),
    ],
    target: "RoadMap",
    view: new View({
      center: transform(
        mapDefaults.getDefaultCenter(),
        "EPSG:4326",
        "EPSG:3857"
      ),
      zoom: mapDefaults.getDefaultZoom(),
      minZoom: mapDefaults.getDefaultZoom(),
    }),
  });
  this.mapComponent.on("singleclick", (evt) => this.handleMapClick(evt)); 
  
  this.mapComponent.on("pointermove", (evt) =>
  this.handleMapPointerMove(evt)
);

  this.mapComponent.once("postcompose", async (evt) => {
    this.handleMapRenderComplete(evt);
    let cropFeature = await this.createCropFeature();
    baseLayers.forEach((layer) => {
      if (layer.get("crop") === true) {
        var crop = new Crop({
          feature: cropFeature[0],
          inner: false,
          active: true,
        });
        layer.addFilter(crop);
      }
    });
  });
  this.props.updateMapReference(this.mapComponent);
};

handleMapClick = (evt) => {
  let drawInteraction = this.mapComponent
    .getInteractions()
    .getArray()
    .filter((interaction) => {
      if (interaction instanceof Draw) return interaction;
    });
  if (drawInteraction.length > 0) return;
  if (!evt.dragging) this.setState({ clickEvent: evt });
};

handleMapPointerMove = (evt) => {
  if (evt.dragging) return;
  try {
    var pixel = this.mapComponent.getEventPixel(evt.originalEvent);
    var hit = this.mapComponent.forEachLayerAtPixel(pixel, (layer) => {
      if (!layer.getProperties().isBaseLayer) return true;
    });
    this.mapComponent.getTargetElement().style.cursor = hit ? "pointer" : "";
  } catch (e) {
    console.log("Excepion", e);
  }
};

componentWillUnmount() {
  // this.mapComponent.un("singleclick", this.handleMapClick);
  this.mapComponent.un("pointermove", this.handleMapPointerMove);
  this.mapComponent.setTarget(undefined);
}

addSearchResultToMap(layer) {
  const { mapComponent, featureData } = this.props;

  // DropdownService.layerExtendFilter()
  const vectorSource = this.state.vectorSource
  vectorSource.clear();

  // basicsearchapi---//
  if(featureData != null)
  {
   
    let features = new GeoJSON().readFeatures(featureData)
    console.log("features",features);
    if(features != [])
   {
    vectorSource.addFeatures(features)
   
  mapComponent && mapComponent.getView().fit(vectorSource.getExtent())
 
}

  }else{
    
    mapComponent && mapComponent.getView().setCenter(transform(mapDefaults.getDefaultCenter(), "EPSG:4326", "EPSG:3857"));
    mapComponent && mapComponent.getView().setZoom(mapDefaults.getDefaultZoom());
  }


}



RoadLayer = () => {
  const { layers } = this.state;
  const { layerData,mapComponent } = this.props;
  const localbodyid= LocalStorageHandler.getLoginResponse().cql_filter

  const checkedLayerName = "Road";
  const localBodyid = localbodyid;
  
  //cq_filter dropdown values 
  const ward = `ward_id=${layerData.ward}`;
  const road_name = `road_name=${"'"+layerData.road_name+"'"}`
  const maintainedBy = `maintained_by_id=${layerData.maintained_by}`;
  const road_material = `road_material=${"'"+layerData.road_material+"'"}`
  const road_category = `road_category=${"'"+layerData.road_category+"'"}`

  const cqlFilterConditions = [localBodyid];

  if(layerData.ward === "None" || layerData.ward === "")
{
  cqlFilterConditions.push(localBodyid)
}else{
  cqlFilterConditions.push(ward);
}

if(layerData.road_material === "None" || layerData.road_material === "")
{
  cqlFilterConditions.push(localBodyid)
}else{
  cqlFilterConditions.push(road_material);
}

if(layerData.road_name === "None" || layerData.road_name === "")
{
  cqlFilterConditions.push(localBodyid)
}else{
  cqlFilterConditions.push(road_name);
}

if(layerData.maintained_by === "None" || layerData.maintained_by === "")
{
  cqlFilterConditions.push(localBodyid)
}else{
  cqlFilterConditions.push(maintainedBy);
}

if(layerData.road_category === "None" || layerData.road_category === "")
{
  cqlFilterConditions.push(localBodyid)
}else{
  cqlFilterConditions.push(road_category);
}


  const cql_filter = cqlFilterConditions.join(' AND ');

  layers.forEach((layer) => {
    const layerName = layer.getProperties().name;
    if (checkedLayerName.includes(layerName)) {
      layer.getSource().updateParams({ 'CQL_FILTER': cql_filter });
      layer.setVisible(true);
      // map layer extend
      // this.addSearchResultToMap(layer)
    } else {
      layer.setVisible(false);
    }
  });
}



  render() {
console.log("state value",this.state);

    return (
      <div style={{width:'85%',height:'385px'}}>
      <div id="RoadMap" style={{width:'100%', position: "relative"}}> 
      <DefaultExtend />
     </div>
     <Feature_info
          clickEvent={this.state.clickEvent}
          mapLayers={this.state.layers}
        />
        </div>
    );
  }
}

function mapStateToProps(state) {
  
  return {
    dashboardSummary: state.dashboard.dashboardSummary,
    wards: state.dashboard.wards,
    isWardsLoaded: state.dashboard.isWardsLoaded,
    mapComponent: state.mapReducer.OlMap,
    feature_data: state.mapSearch.feature_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDashboardSummary: (localBodyId, ward) =>
      dispatch(getDashboardSummary(localBodyId, ward)),
    getWards: (localBodyId) => dispatch(getWards(localBodyId)),
    getLayers: () => dispatch(getLayers()),
    updateMapReference: (mapComponent) =>
    dispatch(updateMapReference(mapComponent)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoadMap);
