import React, { Component } from "react";
import axios from "axios";
import MinimalInfo from "./MinimalInfo";
import DetailedInfo from "./DetailedInfo";
import VectorLayer from "ol/layer/Vector.js";
import VectorSource from "ol/source/Vector.js";
import GeoJSON from "ol/format/GeoJSON.js";
import { connect } from "react-redux";
import { loadingActions } from "../../../../../components/loader/data/action";
import styleFunction from "../../helper/vectorStyle";
import getLayerTree from "../../../map/helper/LayerTreeLoader";
import LayerInfoService from "../../../../../service/LayerInfoService";
class FeatureInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDetailedInfoVisible: false,
      detailedInfoContent: null,
      isMinimalInfoVisible: false,
      minimalInfoContent: false,
      clickEvent: null,
      highlightOverlay: null,
      clickedLayer: null,
      detailedInfoContents: null,
      detailedInfoContentsForeignKey: null,
      minimalInfoContents: null,
      status: "",
      layerParent: "",
      count: 0,
      total_count: 0,
      selectedLayer: null,
      coordinates: null,
      relatedValues: null,
      tableName: null
    };
  }

  componentDidMount() {
    this.setState({
      highlightOverlay: new VectorLayer({
        style: (feature, resolution) => styleFunction(feature, resolution),
        source: new VectorSource(),
      }),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clickEvent !== this.props.clickEvent) {
      this.handleMapClick(this.props.clickEvent);
    }
  }

  onCountIncrement = () => {
    this.setState({
      count: this.state.count + 1,
    });
    document.getElementById("featureInfoPanelBody").scrollTop = 0;
  };

  onCountDecrement = () => {
    this.setState({
      count: this.state.count - 1,
    });
    document.getElementById("featureInfoPanelBody").scrollTop = 0;
  };

  handleMapClick = (evt) => {
    const { mapComponent } = this.props;
    if (evt.dragging) {
      return;
    }
    this.setState({
      count: 0,
    });
    try {
      let pixel = mapComponent.getEventPixel(evt.originalEvent);
      mapComponent.forEachLayerAtPixel(pixel, (layer) => {
        if (layer.type === "VECTOR") return;
        let viewResolution = /** @type {number} */ (mapComponent
          .getView()
          .getResolution());
         
        if (layer.getProperties().layerType !== "base_layer") {
          if (!layer.getProperties().isBaseLayer) {
            let url = layer
              .getSource()
              .getGetFeatureInfoUrl(
                evt.coordinate,
                viewResolution,
                "EPSG:3857",
                {
                  INFO_FORMAT: "application/json",
                  FEATURE_COUNT: 5,
                }
              );
            if (url) {
              this.requestFeatureInfo(url, evt, layer);
            }
          }
        }
      });
      mapComponent.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        if (layer === null) return;
        if (layer.getProperties().name === undefined) return;
        let properties = feature;
        this.handleResponse(properties, evt, layer, "vector");
      });
    } catch (e) {
      console.log("Exception", e);
    }
  };

  async requestFeatureInfo(url, evt, layer) {
    this.props.isLoading();
    await axios({
      url: url,
      method: "GET",
    }).then((response) => {
      // console.log("response of the feature point",response.data.features[0].geometry.coordinates);
      if (response.data.features.length > 0) {
        let properties_tot = {};
        this.setState({
          ...this.state,
          coordinates:response.data.features[0].geometry.coordinates
        })
        response.data.features.map((feature, i) => {
          properties_tot[i] = feature.properties;
          this.setState({
            total_count: i,
          });
        });
        this.handleResponse(response.data.features, evt, layer, "tile");
        this.highlightSelectedLayer(response);
      }
    });
  }

  handleResponse = async (features, evt, layer, type) => {
    let layerTree = getLayerTree();
    let is_boundary = false;
    let spatialQueryData = [];
    let minimalInfoAttributes = layer.getProperties().infoAttributes.minimalInfoAttributes;
    let detailedInfoAttributes = layer.getProperties().infoAttributes.detailedInfoAttributes;
    console.log("detailedInfoAttributes", layer.getProperties().infoAttributes.detailedInfoAttributes);
    
    let minimalInfoContents = {};
    let minimalInfoContentLayerCounts = [];
    let detailedInfoContents = {};
    let detailedInfoContentsForeignKey = {};
   
    //Checking whether selected layer is boundary or not
    layerTree.map((item) => {
      if (item.hasOwnProperty("children") && item.value === "Boundaries") {
        item.children.map((l) => {
          if (l.value === layer.getProperties().name) {
            is_boundary = true;
            return;
          }
        });
      }
    });
   
    if (type == "tile") {
      features.map((feature, i) => {
        let minimalInfoContent = {};
        let detailedInfoContent = {};
        let res = [];
        let detailedInfoContentForeignKey = {};
             //iterating data get from layer
        Object.keys(feature.properties).filter((key) => {
          minimalInfoAttributes.forEach((attribute) => {         
            if (key === attribute.field) {
              //adding value in to attribute list
              attribute["value"] = feature.properties[key];
              //add that in to minimal info content
              if (attribute.data_type !== "ForeignKey")
                minimalInfoContent[key] = Object.assign({}, attribute);
            }
          });
          detailedInfoAttributes.forEach((attribute) => {
            if (key === attribute.field) {
              attribute["value"] = feature.properties[key];
              //add that in to minimal info content
              if (attribute.data_type !== "ForeignKey") {
                detailedInfoContent[key] = Object.assign({}, attribute);
              } else {
               
                detailedInfoContentForeignKey[key] = Object.assign(
                  {},
                  attribute
                );
              }
            }
          });
          
        });
        if (is_boundary) {
          this.props.mapLayers.forEach((element) => {
            if (element.getProperties().visible) {
              var obj = {};
              layerTree.map((item) => {
                if (
                  item.hasOwnProperty("children") &&
                  item.value !== "Boundaries"
                ) {
                  item.children.map((layer) => {
                    if (layer.value == element.values_.name) {
                      obj["model_name"] = layer.model_name;
                      obj["field"] = layer.category_field;
                      obj["field_value"] = layer.category_id;
                      obj["layer_name"] = element.values_.name;
                      obj["layer_label"] = item.label + " - " + layer.label;
                      obj["service_name"] =
                        element.values_.source.params_.LAYERS;
                      res.push(obj);
                      return;
                    }
                  });
                }
              });
            }
          });
        }
        if (res.length > 0)

          minimalInfoContentLayerCounts[i] = {
            layer: res,
            geometry: feature.geometry,
          };
        minimalInfoContents[i] = minimalInfoContent;
        detailedInfoContents[i] = detailedInfoContent;
        detailedInfoContentsForeignKey[i] = {
          foreignKeys: detailedInfoContentForeignKey,
          id: feature.id,
        };
      });
    } else {
      let minimalInfoContent = {};
      let detailedInfoContent = {};
      let detailedInfoContentForeignKey = {};
      
      let i = 0;
      var vectorFeatures = features.getProperties();
      Object.keys(vectorFeatures).filter((key) => {
        minimalInfoAttributes.forEach((attribute) => {
          if (key === attribute.field) {
            //adding value in to attribute list
            attribute["value"] = vectorFeatures[key];
            //add that in to minimal info content
            if (attribute.data_type !== "ForeignKey")
              minimalInfoContent[key] = Object.assign({}, attribute);
          }
        });
        detailedInfoAttributes.forEach((attribute) => {
          if (key === attribute.field) {
            attribute["value"] = vectorFeatures[key];
            //add that in to minimal info content
            if (attribute.data_type !== "ForeignKey") {
              detailedInfoContent[key] = Object.assign({}, attribute);
            } else {
              detailedInfoContentForeignKey[key] = Object.assign({}, attribute);
            }
          }
        });
      });
      let FeatureId = "";
      if (features.id_ == undefined) {
        if (this.props.response_table != null) {
          FeatureId = this.props.response_table + "." + vectorFeatures["pk"];
        } else {
          FeatureId = this.props.feature_table + "." + vectorFeatures["pk"];
        }
      } else {
        FeatureId = features.id_;
      }
      minimalInfoContents[i] = minimalInfoContent;
      detailedInfoContents[i] = detailedInfoContent;
      detailedInfoContentsForeignKey[i] = {
        foreignKeys: detailedInfoContentForeignKey,
        id: FeatureId,
      };
    }
    var result = await LayerInfoService.foreignKeyInfo(
      detailedInfoContentsForeignKey
    );
   console.log("result",result);
   

    if (minimalInfoContentLayerCounts.length > 0) {
      spatialQueryData = await LayerInfoService.spatialQueryData(
        minimalInfoContentLayerCounts
      );
      console.log("spatialQueryData", spatialQueryData);
      
    }
    const filterMinimal = Object.entries(minimalInfoContents[0]);
    filterMinimal.sort((a, b) => {
      return a[1].index - b[1].index;
    });
    minimalInfoContents[0] = Object.fromEntries(filterMinimal);
    const filter = Object.entries(detailedInfoContents[0]);
    filter.sort((a, b) => {
      return a[1].index - b[1].index;
    });
    detailedInfoContents[0] = Object.fromEntries(filter);
    this.setState({
      detailedInfoContents: detailedInfoContents,
      detailedInfoContentsForeignKey: result.data,
      // tableName: ,
      relatedValues:result.data[0].foreignKeys,
      minimalInfoContents: minimalInfoContents,
      minimalInfoContentLayerCounts: spatialQueryData.data,
      isMinimalInfoVisible: true,
      clickEvent: evt,
      selectedLayer: layer,
    });
    this.props.loadingComplete();
  };

  highlightSelectedLayer = (response) => {
    const { highlightOverlay } = this.state;
    const { mapComponent } = this.props;
    highlightOverlay.getSource().clear();
    highlightOverlay.setMap(mapComponent);
    highlightOverlay
      .getSource()
      .addFeatures(new GeoJSON().readFeatures(response.data));
  };

  onDetailedInfoClose = () => {
    this.setState(
      {
        isDetailedInfoVisible: false,
      },
      () => {
        if (
          !this.state.isMinimalInfoVisible &&
          !this.state.isDetailedInfoVisible
        )
          this.state.highlightOverlay.getSource().clear();
      }
    );
  };

  onMinimalInfoClose = (e, popupOverlay) => {
    this.setState(
      {
        isMinimalInfoVisible: false,
      },
      () => {
        if (
          !this.state.isMinimalInfoVisible &&
          !this.state.isDetailedInfoVisible
        )
          this.state.highlightOverlay.getSource().clear();
      }
    );
  };

  showDetailedInfo = () => {
    this.setState({
      isDetailedInfoVisible: true,
    });
  };

  render() {
    return (
      this.state.minimalInfoContent != null && (
        <React.Fragment>
          <DetailedInfo
            count={this.state.count}
            total_count={this.state.total_count}
            status={this.state.status}
            layerParent={this.state.layerParent}
            onCountIncrement={this.onCountIncrement}
            onCountDecrement={this.onCountDecrement}
            isDetailedInfoVisible={this.state.isDetailedInfoVisible}
            onDetailedInfoClose={this.onDetailedInfoClose}
            detailedInfoContents={this.state.detailedInfoContents}
            detailedInfoContentsForeignKey={
              this.state.detailedInfoContentsForeignKey
            }
            selectedLayer={this.state.selectedLayer}
          />
          <MinimalInfo
            count={this.state.count}
            status={this.state.status}
            layerParent={this.state.layerParent}
            total_count={this.state.total_count}
            onCountIncrement={this.onCountIncrement}
            onCountDecrement={this.onCountDecrement}
            isMinimalInfoVisible={this.state.isMinimalInfoVisible}
            showDetailedInfo={this.showDetailedInfo}
            minimalInfoContentLayerCounts={
              this.state.minimalInfoContentLayerCounts
            }
            minimalInfoContents={this.state.minimalInfoContents}
            detailedInfoContents={this.state.detailedInfoContents}
            onMinimalInfoClose={this.onMinimalInfoClose}
            clickEvent={this.state.clickEvent}
            clickedLayer={this.state.highlightOverlay}
            mapComponent={this.props.mapComponent}
            coordinates={this.state.coordinates}
            relatedValues={
              this.state.relatedValues
            }
          />
          
        </React.Fragment>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
    feature_table: state.mapSearch.feature_table,
    response_table: state.advancedFilter.response_table,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    isLoading: () => dispatch(loadingActions.isloading()),
    loadingComplete: () => dispatch(loadingActions.loadingComplete()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureInfo);
