const setLoginResponse = (response) => {

  if(!localStorage.getItem("basicDetails"))
  localStorage.setItem("basicDetails", JSON.stringify(response));
};
const getLoginResponse = () => {
  return localStorage["basicDetails"]
    ? JSON.parse(localStorage.getItem("basicDetails"))
    : null;
};
const isAuthenticated = () => {
  return getLoginResponse() && getLoginResponse().token ? true : false;
};
const getToken = () => {
  return isAuthenticated() ? getLoginResponse().token : null;
};

const getBasicDetails = () => {
  return getLoginResponse() == null ? null : getLoginResponse().basicDetails;
};

const getUserDetails = () => {
  return getBasicDetails() == null ? null : getBasicDetails().userDetails;
};

const getLocalBody = () => {
  return getBasicDetails() == null ? null : getBasicDetails().localBody;
};
const getRole = () => {
  return getBasicDetails() == null ? null : getBasicDetails().role;
};

const getLayerCategory = () => {
  return getLocalBody().layerCategory;
};
const getBaseLayers = () => {
  return getLocalBody().baseLayers;
};
const getMenu = () => {
  return getRole() == null ? null : getRole().menu;
};
const removeSession = () => {
  localStorage.removeItem("basicDetails");
  localStorage.clear();
};
const LocalStorageHandler = {
  setLoginResponse,
  getLoginResponse,
  getToken,
  getUserDetails,
  getLocalBody,
  getRole,
  getLayerCategory,
  getBaseLayers,
  getMenu,
  removeSession,
  isAuthenticated,
};

export default LocalStorageHandler;
