import React, { Component } from "react";
import {
  Grid,
  Col,
  Panel,
  Row,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
} from "react-bootstrap";
import analysisimage from "../../../asset/png/data.png";
import "./Analysis.css";
import { connect } from "react-redux";
import {
  clearDownLoadInitAction,
  downloadReport,
  fetchColumnsByLayer,
  fetchDataByFilter,
  getLayers,
  requestSearchedLayer,
} from "../../map_viewer/map_header/advanced_filter/data/action";
import LocalStorageHandler from "../../../util/storage";
import DropdownService from "../../../service/DropdownService";
import DashboardLayout from "../DashboardLayout";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import RoadMap from "./RoadMap";
import { Prev } from "react-bootstrap/lib/Pagination";
import ResultModel from "../../map_viewer/map_header/advanced_filter/components/ResultModel";
import {
  toggleAdvancedFilterWindow,
  updateSearchResultLayer,
} from "../../map_viewer/map_header/navigation/navbar_form/data/action";
import { loadingActions } from "../../loader/data/action";
import { snackbarActions } from "../../snackbar/data/action";
import { generateReport } from "../data/action";
import PropertyMap from "./PropertyMap";
// import Spinner from 'react-bootstrap/Spinner';

class PropertyAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layer: null,
      localbody_id: LocalStorageHandler.getLocalBody().local_body_id,
      propertyList: [],
      attribute: "",
      data: {
        // bldg_status: "",
        // bldg_usage: "",
        // floor_type: "",
        // roof_type: "",
        // plot_area: "",
        // gas_conn: "",
        // electricity_conn: "",
        // water_conn: "",
        // bldg_zone: "",
        // ward: "",
        // new_pro_id: "",
        // owner_occup: "",
        // occuppation: "",
        // education_category: "",
        // caste: "",
        // ration_card_color: "",
        // age: "",
        // marital_status: "",
        // blood_group: "",
        // disease: "",
        // disablity: "",
        // well: "",
        // rain_water_hv: "",
        // solar_panel: "",
        // firstname: "",
        // pension: "",
      },
      localBody_name: LocalStorageHandler.getLocalBody().local_body_name,
      showReport: false,
      columnlist: [],
      page: "1",
      limit: "10",
      downloadConfirmation: false,
      readyToDownloadFlag: false,
      downloadPage: 1,
      downloadLimit: 100,
      isDownloading: false,
      percentage: 0,
      recordCount: 0,
      fileFormat: "",
      fileName: "",
      showRange: true,
      dropDownArray: [],
      filters: "",
      loading: false,
      featureData: null,
    };

    this.typeaheadRef = React.createRef();
    // this.handleDropdown = this.handleDropdown.bind(this);
  }

  componentDidMount = () => {
    this.props.getLayers();
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { data, layer, filters, localbody_id } = this.state;
    if (this.props.layers && !this.state.layersAvailable) {
      this.setState({ layersAvailable: true }, () => {
        this.dropdownValues();
      });
    }
    if (prevState.data != this.state.data) {
      this.handleExtendlayer();
    }
    const newData = { ...prevState.data };

    if (prevProps.response_id != this.props.response_id) {
      if (
        this.props.response_id &&
        this.props.response_id.features &&
        this.props.response_id.features.length > 0
      ) {
        this.setState({
          showReport: !this.state.showReport,
        });
      }
    }

    if (this.state.data !== prevState.data) {
      const { data, layer, filters, localbody_id } = this.state;

      const advLayer = layer.advancefilterinfo;
      const app_label = layer.app_label;

      // Define the data array outside the loop
      let dropDownArray = [];
      let propData = null;
      let attribute;
      let value;

      Object.keys(data).forEach((key) => {
        if (data[key] !== "" && data[key] !== "None") {
          attribute = key;
          value = data[key];

          if (attribute.trim() !== "") {
            advLayer.find((item) => {
              if (item.field === attribute) {
                const newFilter = {
                  [attribute]: value,
                  localbody: localbody_id,
                };

                if (item.model_name === "Property") {
                  const existingFilterIndex = dropDownArray.findIndex(
                    (existingItem) =>
                      existingItem.model === item.model_name &&
                      existingItem.app_label === app_label
                  );

                  if (existingFilterIndex !== -1) {
                    dropDownArray[existingFilterIndex].filter[
                      attribute
                    ] = value;
                    dropDownArray[
                      existingFilterIndex
                    ].filter.localbody = localbody_id;
                  } else {
                    dropDownArray.push({
                      model: item.model_name,
                      app_label: app_label,
                      filter: newFilter,
                    });
                  }
                } else {
                  if (attribute === "firstname") {
                    if (item.model_name === "Owner_Details") {
                      dropDownArray.push({
                        model: item.model_name,
                        app_label: app_label,
                        filter: {
                          [attribute]: value,
                        },
                      });

                      propData = {
                        model: item.parent_model,
                        app_label: app_label,
                        filter: {
                          localbody: localbody_id,
                        },
                      };
                    }
                  } else {
                    dropDownArray.push({
                      model: item.model_name,
                      app_label: app_label,
                      filter: {
                        [attribute]: value,
                      },
                    });

                    propData = {
                      model: item.parent_model,
                      app_label: app_label,
                      filter: {
                        localbody: localbody_id,
                      },
                    };
                  }
                }
              }
            });
          }
        }
      });

      const dataValues = Object.values(data).every((item) => item === "");
      if (dataValues === true) {
        this.setState({
          filters: "",
        });
      }
      let combinedDataArray =
        propData !== null ? [...dropDownArray, propData] : [...dropDownArray];

      if (combinedDataArray.length > 0) {
        DropdownService.multifilterData(combinedDataArray).then((res) => {
          if (res.data.data.length > 0) {
            this.setState({
              filters: {
                new_pro_id: res.data.data,
              },
            });
          } else {
            this.props.showSnackbar("No Data Found");
            this.setState({
              filters: prevState.filters,
            });
          }
        });
      }
    }
  };

  dropdownValues = () => {
    const { layers } = this.props;
    const { state } = this.props.location;
    const selectedLayer = layers.find(
      (layer) => layer.model_name === state.model
    );
    this.setState({
      ...this.state,
      layer: selectedLayer,
    });

    if (selectedLayer) {
      const sortedFilter = selectedLayer.advancefilterinfo;

      const app_label = selectedLayer.app_label;
      sortedFilter.find((items) => {
        const parent_model = items.parent_model?.toLowerCase();

        // // building status
        // if (items.field === "bldg_status") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     const filteredResponseData = res.data.response_data.filter(item => item.id !== null);

        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         bldg_status: filteredResponseData
        //       }
        //     })
        //   })
        // }

        // // Type
        // if (items.field === "bldg_usage") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }

        //   DropdownService.analysisDropdown(data).then((res) => {
        //     const filteredResponseData = res.data.response_data.filter(item => item.id !== null);

        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         bldg_usage: filteredResponseData
        //       }
        //     })
        //   })
        // }

        // // floor type
        // if (items.field === "floor_type") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {

        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         floor_type: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // // // roof type
        // if (items.field === "roof_type") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         roof_type: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // // area
        // if (items.field === "door_status") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     // related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         door_status: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // // gas connection
        // if (items.field === "gas_conn") {

        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         gas_conn: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // // electric connection
        // if (items.field === "electricity_conn") {

        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {

        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         electricity_conn: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // // water connection
        // if (items.field === "water_conn") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {

        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         water_conn: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // //  Zone
        // if (items.field === "bldg_zone") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         bldg_zone: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // // Ward
        // if (items.field === "ward") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {

        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         ward: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // property id
        if (items.field === "new_pro_id") {
          let data = {
            model: items.model_name,
            attribute: items.field,
            app_label: app_label,
            filters: {
              localbody: this.state.localbody_id,
            },
          };
          DropdownService.analysisDropdown(data).then((res) => {
            if (res.status == 200) {
              this.setState({
                propertyList: {
                  ...this.state.propertyList,
                  new_pro_id: res.data.response_data,
                },
              });
            } else {
              this.setState({
                ...this.state,
                loading: true,
              });
            }
          });
        }

        // owner name
        if (
          items.field === "firstname" &&
          items.model_name === "Owner_Details"
        ) {
          let data = {
            model: items.model_name,
            attribute: items.field,
            app_label: app_label,
            related_field: parent_model,
            filters: {
              localbody: this.state.localbody_id,
            },
          };
          DropdownService.relatedData(data).then((res) => {
            console.log("response",res),
            this.setState({
              propertyList: {
                ...this.state.propertyList,
                firstname: res.data.data,
              },
            });
          });
        }

        // owner occupation
        // if (items.field === "owner_occup") {

        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        // const uniqueResponseData = [];
        // const uniqueIds = new Set();

        // res.data.response_data.forEach(item => {
        //   if (!uniqueIds.has(item.attribute_data)) {
        //     uniqueIds.add(item.attribute_data);
        //     uniqueResponseData.push(item);
        //   }
        // });
        // const filteredResponseData = uniqueResponseData.filter(item => item.id !== null);

        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         owner_occup: filteredResponseData
        //       }
        //     })
        //   })
        // }

        // education
        // if (items.field === "education_category") {

        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {

        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         edu_cat: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // // caste
        // if (items.field === "caste") {

        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {

        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         caste: res.data.response_data
        //       }
        //     })
        //   })
        // }

        if (items.field === "member_count") {
          let data = {
            model: items.model_name,
            attribute: items.field,
            app_label: app_label,
            related_field: parent_model,
            filters: {
              localbody: this.state.localbody_id,
            },
          };
          DropdownService.analysisDropdown(data).then((res) => {
            console.log("response",res),
            this.setState({
              propertyList: {
                ...this.state.propertyList,
                member_count: res.data.response_data,
              },
            });
          });
        }

        // // ration_card
        // if (items.field === "ration_card_color") {

        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         ration_card: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // // pension

        // if (items.field === "pension") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     const uniqueResponseData = [];
        //     const uniqueIds = new Set();

        //     res.data.response_data.forEach(item => {
        //       if (!uniqueIds.has(item.id)) {
        //         uniqueIds.add(item.id);
        //         uniqueResponseData.push(item);
        //       }
        //     });

        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         pension: uniqueResponseData
        //       }
        //     })
        //   })
        // }
        //Age
        if (items.field === "age") {
          let data = {
            model: items.model_name,
            attribute: items.field,
            app_label: app_label,
            related_field: parent_model,
            filters: {
              localbody: this.state.localbody_id,
            },
          };
          DropdownService.analysisDropdown(data).then((res) => {
            console.log("response",res),
            
            this.setState({
              propertyList: {
                ...this.state.propertyList,
                age: res.data.response_data,
              },
            });
          });
        }

        // martial status
        // if (items.field === "marital_status") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         marital_status: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // blood type
        // if (items.field === "blood_group") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         blood_type: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // // Diseases
        // if (items.field === "disease") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         disease: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // // well
        // if (items.field === "well") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         well: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // rain water harvesting
        // if (items.field === "rain_water_hv") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         rain_water_hv: res.data.response_data
        //       }
        //     })
        //   })
        // }

        // solar panel
        // if (items.field === "solar_panel") {
        //   let data = {
        //     model: items.model_name,
        //     attribute: items.field,
        //     app_label: app_label,
        //     related_field: parent_model,
        //     filters: {
        //       localbody: this.state.localbody_id,
        //     }
        //   }
        //   DropdownService.analysisDropdown(data).then((res) => {
        //     this.setState({
        //       propertyList: {
        //         ...this.state.propertyList,
        //         solar_panel: res.data.response_data
        //       }
        //     })
        //   })
        // }
      });
    }
  };

  handleExtendlayer = () => {
    const { layers } = this.props;
    const { state } = this.props.location;

    const selectedLayer = layers.find(
      (layer) => layer.model_name === state.model
    );
    const dataValue = this.state.data;

    //  if(dataValue)
    //  {
    //  const filters = Object.keys(dataValue).filter(key => dataValue[key] !== undefined && dataValue[key] !== '') .reduce((acc, key) => ({ ...acc, [key]: dataValue[key] }), {});
    let filters = null;

    Object.keys(dataValue).map((value) => {
      if (dataValue[value] != undefined && dataValue[value] != "") {
        filters = { ...filters, [value]: dataValue[value] };
      }
    });

    if (filters != null) {
      const data = {
        layer_name: selectedLayer.layer_name,
        filters: filters,
      };

      DropdownService.layerExtendFilter(data).then((res) => {
        const data = res?.data?.detail?.data;
        if(data){
        this.setState({
          ...this.state,
          featureData: data,
        });
      }
      });
    }
  };

  handleDropdown = (name) => {
    const { layers } = this.props;
    const { state } = this.props.location;

    const selectedLayer = layers.find(
      (layer) => layer.model_name === state.model
    );
    this.setState({
      ...this.state,
      layer: selectedLayer,
    });

    if (selectedLayer) {
      const sortedFilter = selectedLayer.advancefilterinfo;

      const app_label = selectedLayer.app_label;
      sortedFilter.find((items) => {
        const parent_model = items.parent_model?.toLowerCase();
        const keyName = this.state.propertyList;

        // const checkingValue = keyName && Object.keys(keyName[name]).length

        if (!keyName.hasOwnProperty(name)) {
          if (items.field == name) {
            if (items.model_name == "Property") {
              // if(name == "fristname" && )
              let data = {
                model: items.model_name,
                attribute: items.field,
                app_label: app_label,

                filters: {
                  localbody: this.state.localbody_id,
                },
              };
              DropdownService.analysisDropdown(data).then((res) => {
                // const filteredResponseData = res.data.response_data.filter(item => item.id !== null);
                const uniqueResponseData = [];
                const uniqueIds = new Set();

                res.data.response_data.forEach((item) => {
                  if (!uniqueIds.has(item.attribute_data)) {
                    uniqueIds.add(item.attribute_data);
                    uniqueResponseData.push(item);
                  }
                });
                const filteredResponseData = uniqueResponseData.filter(
                  (item) => item.id !== null
                );
                if(filteredResponseData){
                this.setState({
                  propertyList: {
                    ...this.state.propertyList,
                    [name]: filteredResponseData,
                  },
                });
              }
              });
            } else {
              if (
                items.field === "firstname" &&
                items.model_name === "Owner_Details"
              ) {
                let data = {
                  model: items.model_name,
                  attribute: items.field,
                  app_label: app_label,
                  related_field: parent_model,
                  filters: {
                    localbody: this.state.localbody_id,
                  },
                };
                DropdownService.analysisDropdown(data).then((res) => {
                  // const filteredResponseData = res.data.response_data.filter(item => item.id !== null);
                  const uniqueResponseData = [];
                  const uniqueIds = new Set();

                  res.data.response_data.forEach((item) => {
                    if (!uniqueIds.has(item.attribute_data)) {
                      uniqueIds.add(item.attribute_data);
                      uniqueResponseData.push(item);
                    }
                  });
                  const filteredResponseData = uniqueResponseData.filter(
                    (item) => item.id !== null
                  );
                  if(filteredResponseData){
                  this.setState({
                    propertyList: {
                      ...this.state.propertyList,
                      [name]: filteredResponseData,
                    },
                  });
                }
                });
              } else {
                let data = {
                  model: items.model_name,
                  attribute: items.field,
                  app_label: app_label,
                  related_field: parent_model,
                  filters: {
                    localbody: this.state.localbody_id,
                  },
                };
                DropdownService.analysisDropdown(data).then((res) => {
                  // const filteredResponseData = res.data.response_data.filter(item => item.id !== null);
                  const uniqueResponseData = [];
                  const uniqueIds = new Set();

                  res.data.response_data.forEach((item) => {
                    if (!uniqueIds.has(item.attribute_data)) {
                      uniqueIds.add(item.attribute_data);
                      uniqueResponseData.push(item);
                    }
                  });
                  const filteredResponseData = uniqueResponseData.filter(
                    (item) => item.id !== null
                  );
if(filteredResponseData){
                  this.setState({
                    propertyList: {
                      ...this.state.propertyList,
                      [name]: filteredResponseData,
                    },
                  });
                }
                });
              }
            }
          }
        }
      });
    }
  };

  showReports = () => {
    const { state } = this.props.location;
    const { data, layer, columnlist, obj } = this.state;
    if (layer) {
      const advancedFilter = layer.advancefilterinfo;

      let isFirstIteration = true;
      let obj;

      this.state.obj = [];
      this.state.columnlist = [];

      Object.keys(data).forEach((key) => {
        if (data[key] !== "" && data[key] !== "None") {
          const attribute = key;
          const value = data[key];

          advancedFilter.forEach((item) => {
            if (attribute === "") {
              this.state.columnlist.push({
                field: item.field,
                model_name: item.model_name,
                display_name: item.display_name,
              });

              obj = {
                attribute,
                op: "equal",
                value,
                model_name: item.model_name,
                parent_model: item.parent_model,
                join_op: isFirstIteration ? null : "and",
              };

              this.state.obj.push(obj);

              isFirstIteration = false;
            } else if (attribute === item.field) {
              if (item.field === "firstname") {
                this.state.columnlist.push({
                  field: item.field,
                  model_name: "Owner_Details",
                  display_name: item.display_name,
                });

                obj = {
                  attribute,
                  op: "equal",
                  value,
                  model_name: "Owner_Details",
                  parent_model: item.parent_model,
                  join_op: isFirstIteration ? null : "and",
                };
              } else {
                this.state.columnlist.push({
                  field: item.field,
                  model_name: item.model_name,
                  display_name: item.display_name,
                });

                obj = {
                  attribute,
                  op: "equal",
                  value,
                  model_name: item.model_name,
                  parent_model: item.parent_model,
                  join_op: isFirstIteration ? null : "and",
                };
              }
              this.state.obj.push(obj);
              isFirstIteration = false;
            }
          });
        }
      });

      let filter = {
        page: this.state.page,
        limit: this.state.limit,
        report: true,
        parent_model: state.model,
        obj: this.state.obj,
        category_field: null,
        category_id: layer.layer_category_id,
        file_name: "",
        layer_name: "",
        module: "advanceresult",
        file_extension: "",
        columns: this.state.columnlist,
        models: [state.model],
      };

      this.props.fetchDataByFilter(filter);
    }
  };

  handleShowReport = () => {
    this.setState({
      showReport: !this.state.showReport,
    });
  };

  handlepaginationClick = (page) => {
    this.setState({
      page: page,
      percentage: 0,
    });
  };

  //input chnage for dropdown
  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      data: { ...this.state.data, [name]: value },
      attribute: name,
    });
  };

  handlePropertyIdInputChange = (selectedOption) => {
    this.setState({
      data: {
        ...this.state.data,
        new_pro_id: selectedOption.new_pro_id,
      },
    });
  };

  //input change of the typehead
  handleSearchInputChange = (selectedOption, attribute) => {
    this.setState({
      data: {
        ...this.state.data,
        [attribute]: selectedOption,
      },

      attribute: attribute,
    });
  };

  //input change on the range
  handleRangeChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  handleFileChange = (el, field) => {
    this.setState({
      ...this.state,
      fileType: el.target.value,
    });
  };

  handleFileFormatChange = (el, field) => {
    this.setState({
      ...this.state,
      fileFormat: el.target.value,
    });
  };

  downloadFile = () => {
    this.setState({ isDownloading: false });
    let urls =
      this.props.reportUrls &&
      this.props.reportUrls[0] &&
      this.props.reportUrls[0].urls;

    if (!urls) return;
    if (this.state.fileFormat === "csv") {
      const { building, member, merged } = urls.csv;
      if (this.state.data.layerName === "Residential") {
        this.state.data.fileType === "building" && window.open(building);
        this.state.data.fileType === "member" && member && window.open(member);
        this.state.data.fileType === "merged" && merged && window.open(merged);
      } else {
        building && window.open(building);
      }
    }
    if (this.state.fileFormat === "excel") {
      const { building, member, merged } = urls.excel;
      if (this.state.data.layerName === "Residential") {
        this.state.data.fileType === "building" && window.open(building);
        this.state.data.fileType === "member" && member && window.open(member);
        this.state.data.fileType === "merged" && merged && window.open(merged);
      } else {
        building && window.open(building);
      }
    }
  };

  generateReport = () => {
    const { state } = this.props.location;
    const { layer, data } = this.state;

    let attribute, value;

    this.state.recordCount > 1000 &&
      this.setState({ downloadConfirmation: true });
    this.setState({ downloadPercentage: 0, isDownloading: true });

    Object.keys(data).map((key) => {
      if (data[key] !== "") {
        attribute = key;
        value = data[key];
      }
    });

    let reportValues = {
      page: 1,
      limit: this.state.downloadLimit,
      report: "True",
      file_name: this.state.fileName,
      file_extension: this.state.fileFormat,
      models: [state.model],
      columns: this.state.columnlist,
      layer_name: state.model,
      parent_model: state.model,
      obj: this.state.obj,
      category_field: null,
      category_id: null,
      unique_id: this.props.unique_id,
      recordCount: this.state.recordCount,
      maxPage: this.state.recordCount / this.state.downloadLimit,
      file_type: null,
    };
    this.props.downloadReport(reportValues);
    this.setState({ downloadPage: 1 });
    // window.open(this.props.reportLink);
  };

  ClearFilter = () => {
    this.setState((prevState) => ({
      //layer: this.props,
      data: {
        ...prevState.data,
        bldg_status: "",
        bldg_usage: "",
        floor_type: "",
        roof_type: "",
        plot_area: "",
        gas_conn: "",
        electricity_conn: "",
        water_conn: "",
        bldg_zone: "",
        ward: "",
        new_pro_id: [],
        owner_occup: "",
        occuppation: "",
        education_category: "",
        caste: "",
        ration_card_color: "",
        age: "",
        marital_status: "",
        blood_group: "",
        disease: "",
        disablity: "",
        well: "",
        rain_water_hv: "",
        solar_panel: "",
        firstname: "",
        pension: "",
        door_status: "",
      },
      columnlist: [],
      obj: [],
      attribute: "",
      dropDownArray: [],
      filters: "",
      featureData: null,
    }));
    if (this.typeaheadRef.current) {
      this.typeaheadRef.current.getInstance().clear();
    }
  };

  calculateMinMaxValues(propertyList, propertyName) {
    const propertyValues = propertyList[propertyName];

    if (!propertyValues || propertyValues.length < 2) {
      return { minValue: 0, maxValue: 0 };
    }

    const minValue = propertyValues[0].attribute_data;
    const secondToLastValue = propertyValues[propertyValues.length - 2];

    const maxValue =
      secondToLastValue && secondToLastValue.attribute_data !== null
        ? secondToLastValue.attribute_data
        : 0;

    return { minValue, maxValue };
  }

  renderRangeInput(propertyName, label) {
    const { data } = this.state;
    const { minValue, maxValue } = this.calculateMinMaxValues(
      this.state.propertyList,
      propertyName
    );

    return (
      this.state.showRange && (
        <FormGroup className="analysis-form-dropdown" key={propertyName}>
          <div>
            <label>{label}</label>
            <p>{data[propertyName]}</p>
          </div>

          <input
            className="analysis-form-dropdown-input"
            type="range"
            name={propertyName}
            value={data[propertyName] || 0}
            onChange={this.handleRangeChange}
            min={minValue}
            max={maxValue}
          />
        </FormGroup>
      )
    );
  }

  render() {
    const { propertyList } = this.state;

    const {
      bldg_status,
      bldg_usage,
      floor_type,
      roof_type,
      plot_area,
      gas_conn,
      electricity_conn,
      water_conn,
      bldg_zone,
      ward,
      new_pro_id,
      owner_occup,
      occuppation,
      education_category,
      caste,
      ration_card_color,
      age,
      marital_status,
      blood_group,
      disease,
      disablity,
      well,
      rain_water_hv,
      solar_panel,
      firstname,
      member_count,
    } = this.state.data;

    let isButtonEnabled =
      bldg_status ||
      bldg_usage ||
      floor_type ||
      roof_type ||
      plot_area ||
      gas_conn ||
      electricity_conn ||
      water_conn ||
      bldg_zone ||
      ward ||
      new_pro_id ||
      owner_occup ||
      occuppation ||
      education_category ||
      caste ||
      ration_card_color ||
      age ||
      marital_status ||
      blood_group ||
      disease ||
      disablity ||
      well ||
      rain_water_hv ||
      solar_panel ||
      firstname ||
      member_count;

    return (
      <DashboardLayout handleRedirect={this.handleRedirect}>
        <Grid
          style={{
            width: "100%",
            // height:"100%",
            display: "flex",

            paddingBottom: "2rem",
            // maxHeight: "950px",
            // overflow: "hidden",
            margin: "0px",
            padding: "0px",
          }}
        >

          <Row className="analysis-main-row">
            <div className="analysis-left-dropdown">
              <>
                <div className="analysis-left-head">
                  <span className="analysis-left-head-text">
                    Building Information
                  </span>
                  <hr className="analysis-head-line"></hr>
                </div>
                <div className="analysis-left-dropdowns">
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Building Status</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="bldg_status"
                      value={this.state.data.bldg_status}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.bldg_status ? (
                        propertyList.bldg_status.map((item, i) => (
                          // item.attribute_data !== "None" &&
                          <option value={item.id} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Type</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="bldg_usage"
                      value={this.state.data.bldg_usage}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.bldg_usage ? (
                        propertyList.bldg_usage.map((item, i) => (
                          <option value={item.id} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Floor Type</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="floor_type"
                      value={this.state.data.floor_type}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.floor_type ? (
                        propertyList.floor_type.map((item, i) => (
                          <option value={item.id} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Roof Type</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="roof_type"
                      value={this.state.data.roof_type}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.roof_type ? (
                        propertyList.roof_type.map((item, i) => (
                          <option value={item.id} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Door Status</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="door_status"
                      value={this.state.data.door_status}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.door_status ? (
                        propertyList.door_status.map((item, i) => (
                          <option value={item.id} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                </div>
              </>
              <>
                <div className="analysis-left-head">
                  <span className="analysis-left-head-text">Utilities</span>
                  <hr className="analysis-head-line"></hr>
                </div>
                <div className="analysis-left-dropdowns">
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Gas Connection</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="gas_conn"
                      value={this.state.data.gas_conn}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.gas_conn ? (
                        propertyList.gas_conn.map((item, i) => (
                          <option value={item.attribute_data} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Electricity Connection</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="electricity_conn"
                      value={this.state.data.electricity_conn}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.electricity_conn ? (
                        propertyList.electricity_conn.map((item, i) => (
                          <option value={item.attribute_data} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Water Connection</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="water_conn"
                      value={this.state.data.water_conn}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.water_conn ? (
                        propertyList.water_conn.map((item, i) => (
                          <option value={item.attribute_data} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                </div>
              </>
             <div className="analysis-btn-div">
              <button
                className="analysis-btn"
                onClick={this.ClearFilter}
                disabled={this.state.data === ""}
              >
                Clear
              </button>
              </div>
            </div>
            <div className="analysis-center-dropdown">
              <>
                <div className="analysis-left-head">
                  <span className="analysis-left-head-text">
                    Smart {this.state.localBody_name} - Property Analysis
                  </span>
                  <hr className="analysis-head-line"></hr>
                </div>
              </>
              <>
                <div className="analysis-center-first-dropdowns">
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Zone</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="bldg_zone"
                      value={this.state.data.bldg_zone}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.bldg_zone ? (
                        propertyList.bldg_zone.map((item, i) => (
                          <option value={item.id} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Ward Name</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="ward"
                      value={this.state.data.ward}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.ward ? (
                        propertyList.ward.map((item, i) => (
                          <option value={item.id} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Property ID</ControlLabel>

                    <AsyncTypeahead
                      className="analysis-form-dropdown-input"
                      id="searchinput"
                      minLength={0}
                      options={
                        propertyList.new_pro_id &&
                        Array.isArray(propertyList.new_pro_id)
                          ? propertyList.new_pro_id.map((item) => ({
                              id: item.id,
                              new_pro_id: item.attribute_data,
                            }))
                          : []
                      }
                      labelKey="new_pro_id"
                      value={this.state.data.new_pro_id}
                      inputProps={{ name: "new_pro_id" }}
                      onChange={(selectedOptions) => {
                        if (selectedOptions && selectedOptions.length > 0) {
                          this.handlePropertyIdInputChange(selectedOptions[0]);
                        }
                      }}
                      // onClick={(e)=>this.handleDropdown(e.target.name)}
                      onClick={() => this.handleDropdown("new_pro_id")}
                      filterBy={(option, props) =>
                        option.new_pro_id
                          .toString()
                          .toLowerCase()
                          .includes(props.text.toLowerCase())
                      }
                      placeholder="SELECT"
                      ref={this.typeaheadRef}
                      isLoading={this.state.isLoading}
                    />
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Owner Name</ControlLabel>

                    <AsyncTypeahead
                      className="analysis-form-dropdown-input"
                      id="searchinput"
                      minLength={0}
                      options={
                        propertyList.firstname &&
                        Array.isArray(propertyList.firstname)
                          ? propertyList.firstname.map((item) => ({
                              property_id: item.property_id,
                              new_pro_id: item.new_pro_id,
                              name: item.attribute_data,
                              firstname: `${item.attribute_data}(${item.new_pro_id})`,
                            }))
                          : []
                      }
                      labelKey={(option) => option.firstname}
                      value={this.state.data.firstname}
                      inputProps={{ name: "firstname" }}
                      onChange={(selectedOptions) => {
                        if (selectedOptions && selectedOptions.length > 0) {
                          this.handleSearchInputChange(
                            selectedOptions[0].name,
                            "firstname"
                          );
                        }
                      }}
                      onClick={() => this.handleDropdown("firstname")}
                      filterBy={(option, props) =>
                        option.firstname
                          .toString()
                          .toLowerCase()
                          .includes(props.text.toLowerCase())
                      }
                      placeholder="SELECT"
                      ref={this.typeaheadRef}
                    />
                  </FormGroup>
                </div>
              </>
              <>
                <PropertyMap
                  layerData={this.state.data}
                  featureData={this.state.featureData}
                  dropdownLayer={this.dropdownValues}
                  filters={this.state.filters}
                />
              </>
              <>
                <div className="analysis-left-head">
                  <span className="analysis-left-head-text">
                    Sustainability
                  </span>
                  <hr className="analysis-head-line"></hr>
                </div>
              </>
              <>
                <div className="analysis-center-first-dropdowns">
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Well Available</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="well"
                      value={this.state.data.well}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.well ? (
                        propertyList.well.map((item, i) => (
                          <option value={item.attribute_data} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Rain Water Harvesting</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="rain_water_hv"
                      value={this.state.data.rain_water_hv}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.rain_water_hv ? (
                        propertyList.rain_water_hv.map((item, i) => (
                          <option value={item.attribute_data} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Solar Panel Available</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="solar_panel"
                      value={this.state.data.solar_panel}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.solar_panel ? (
                        propertyList.solar_panel.map((item, i) => (
                          <option value={item.attribute_data} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Waste Management</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="waste_mgmnt"
                      value={this.state.data.well}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.waste_mgmnt ? (
                        propertyList.waste_mgmnt.map((item, i) => (
                          <option value={item.attribute_data} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                </div>
              </>
            </div>
            <>
              <div className="analysis-left-dropdown">
                <>
                  <div className="analysis-left-head">
                    <span className="analysis-left-head-text">
                      Social Status
                    </span>
                    <hr className="analysis-head-line"></hr>
                  </div>
                </>
                <>
                  <div className="analysis-left-dropdowns">
                    <FormGroup className="analysis-form-dropdown">
                      <ControlLabel>Owner Occupation</ControlLabel>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        className="analysis-form-dropdown-input"
                        name="owner_occup"
                        value={this.state.data.owner_occup}
                        onChange={(e) => this.handleInputChange(e)}
                        onClick={(e) => this.handleDropdown(e.target.name)}
                      >
                        <option value="">Select</option>
                        {propertyList.owner_occup ? (
                          propertyList.owner_occup.map((item, i) => (
                            <option value={item.id} key={i}>
                              {item.attribute_data}
                            </option>
                          ))
                        ) : (
                          <option>loading...</option>
                        )}
                      </FormControl>
                    </FormGroup>
                    <FormGroup className="analysis-form-dropdown">
                      <ControlLabel>Education</ControlLabel>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        className="analysis-form-dropdown-input"
                        name="education_category"
                        value={this.state.data.education_category}
                        onChange={(e) => this.handleInputChange(e)}
                        onClick={(e) => this.handleDropdown(e.target.name)}
                      >
                        <option value="">Select</option>
                        {propertyList.edu_cat ? (
                          propertyList.edu_cat.map((item, i) => (
                            <option value={item.id} key={i}>
                              {item.attribute_data}
                            </option>
                          ))
                        ) : (
                          <option>loading...</option>
                        )}
                      </FormControl>
                    </FormGroup>
                    <FormGroup className="analysis-form-dropdown">
                      <ControlLabel>Caste</ControlLabel>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        className="analysis-form-dropdown-input"
                        name="caste"
                        value={this.state.data.caste}
                        onChange={(e) => this.handleInputChange(e)}
                        onClick={(e) => this.handleDropdown(e.target.name)}
                      >
                        <option value="">Select</option>
                        {propertyList.caste ? (
                          propertyList.caste.map((item, i) => (
                            <option value={item.id} key={i}>
                              {item.attribute_data}
                            </option>
                          ))
                        ) : (
                          <option>loading...</option>
                        )}
                      </FormControl>
                    </FormGroup>
                    <div>
                      {this.renderRangeInput("member_count", "No of Members")}
                    </div>

                    <FormGroup className="analysis-form-dropdown">
                      <ControlLabel>Ration Card</ControlLabel>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        className="analysis-form-dropdown-input"
                        name="ration_card_color"
                        value={this.state.data.ration_card_color}
                        onChange={(e) => this.handleInputChange(e)}
                        onClick={(e) => this.handleDropdown(e.target.name)}
                      >
                        <option value="">Select</option>
                        {propertyList.ration_card_color ? (
                          propertyList.ration_card_color.map((item, i) => (
                            <option value={item.id} key={i}>
                              {item.attribute_data}
                            </option>
                          ))
                        ) : (
                          <option>loading...</option>
                        )}
                      </FormControl>
                    </FormGroup>
                    <FormGroup className="analysis-form-dropdown">
                      <ControlLabel>Pension</ControlLabel>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        className="analysis-form-dropdown-input"
                        name="pension"
                        value={this.state.data.pension}
                        onChange={(e) => this.handleInputChange(e)}
                        onClick={(e) => this.handleDropdown(e.target.name)}
                      >
                        <option value="">Select</option>
                        {propertyList.pension ? (
                          propertyList.pension.map((item, i) => (
                            <option value={item.id} key={i}>
                              {item.attribute_data}
                            </option>
                          ))
                        ) : (
                          <option>loading...</option>
                        )}
                      </FormControl>
                    </FormGroup>
                  </div>
                </>
                <div className="analysis-left-head">
                  <span className="analysis-left-head-text">Health</span>
                  <hr className="analysis-head-line"></hr>
                </div>
                <div className="analysis-left-dropdowns">
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Martial Status</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="marital_status"
                      value={this.state.data.marital_status}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.marital_status ? (
                        propertyList.marital_status.map((item, i) => (
                          <option value={item.id} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Blood Type</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="blood_group"
                      value={this.state.data.blood_group}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.blood_type ? (
                        propertyList.blood_type.map((item, i) => (
                          <option value={item.id} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup className="analysis-form-dropdown">
                    <ControlLabel>Diseases</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className="analysis-form-dropdown-input"
                      name="disease"
                      value={this.state.data.disease}
                      onChange={(e) => this.handleInputChange(e)}
                      onClick={(e) => this.handleDropdown(e.target.name)}
                    >
                      <option value="">Select</option>
                      {propertyList.disease ? (
                        propertyList.disease.map((item, i) => (
                          <option value={item.id} key={i}>
                            {item.attribute_data}
                          </option>
                        ))
                      ) : (
                        <option>loading...</option>
                      )}
                    </FormControl>
                  </FormGroup>
                </div>
                <div className="analysis-btn-div">
                <button
                  className="analysis-btn"
                  disabled={!isButtonEnabled}
                  onClick={this.showReports}
                >
                  Show Report
                </button>
                </div>
                </div>
            </>
          </Row>
         
        </Grid>

        {this.state.showReport && (
          <ResultModel
            show={this.state.showReport}
            handleShowReport={this.handleShowReport}
            result={this.props.response_table}
            layer={this.state.layer}
            recordCount={this.props.recordCount}
            handlepaginationClick={(page) => this.handlepaginationClick(page)}
            data={this.state.data}
            response_member={this.props.response_member}
            handleFileChange={this.handleFileChange}
            handleFileFormatChange={this.handleFileFormatChange}
            downloadFile={() => this.downloadFile}
            generateReport={() => this.generateReport}
            reportFile={this.props.reportFile}
            advanceLoading={this.props.advanceLoading}
            percentage={this.state.percentage}
            downloadPercentage={this.state.downloadPercentage}
            downloadLoading={this.props.downloadLoading}
          />
        )}
      </DashboardLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    layers: state.advancedFilter.layers,
    datas: state.advancedFilter.data,
    show: state.mapSearch.showAdvancedFilterWindow,
    mapComponent: state.mapReducer.OlMap,
    resultLayer: state.mapSearch.resultLayer,
    attributes: state.advancedFilter.attributes,
    response_id: state.advancedFilter.response_id,
    fetching: state.advancedFilter.fetching,
    data: state.advancedFilter.data,
    recordCount: state.advancedFilter.recordCount,
    responseData: state.advancedFilter.responseData,
    response_table: state.advancedFilter.response_table,
    response_member: state.advancedFilter.response_member,
    downlaodPage: state.advancedFilter.page,
    unique_id: state.advancedFilter.unique_id,

    reportUrls: state.advancedFilter.reportUrls,
    reportFile: state.advancedFilter.reportFile,
    downloadLoading: state.advancedFilter.downloadLoading,
    advanceLoading: state.advancedFilter.advanceLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLayers: () => dispatch(getLayers()),
    hide: () => dispatch(toggleAdvancedFilterWindow()),
    fetchDataByFilter: (filterValues) =>
      dispatch(fetchDataByFilter(filterValues)),
    fetchColumnsByLayer: (layer) => dispatch(fetchColumnsByLayer(layer)),
    updateSearchResultLayer: (searchLayer) =>
      dispatch(updateSearchResultLayer(searchLayer)),
    isLoading: () => dispatch(loadingActions.isloading()),
    loadingComplete: () => dispatch(loadingActions.loadingComplete()),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
    getColumns: () => dispatch(getColumns()),

    requestSearchedLayer: (layerToSearch, featureRequest) =>
      dispatch(requestSearchedLayer(layerToSearch, featureRequest)),
    generateReport: (reportValues) => dispatch(generateReport(reportValues)),
    clearDownLoadInitAction: () => dispatch(clearDownLoadInitAction()),

    downloadReport: (reportValues) => dispatch(downloadReport(reportValues)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PropertyAnalysis);
