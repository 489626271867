import { ButtonToolbar, Modal, Panel, Button, Table } from "react-bootstrap";
import React, { Component } from "react";
import "./ResultModel.css";
import closeButton from "../../../asset/svg/close_icon.svg";
import Draggable from "react-draggable";

class ResultModel extends Component {
  constructor(props) {
    super(props);
  }

  handleResponseHeader() {
    let properties = {};
    let names = {};
    this.props.result.map((res, i) => {
      properties = res;
    });
    

    if (properties != null) {
      return (
        <tr key={-1}>
          <th>SL NO</th>
          {Object.keys(properties).map((key) => {
            if (!key.includes("photo") && !key.includes("p_d_f_")) {
              return <th key={key}>{getAttributeName(key)}</th>;
            }
          })}
        </tr>
      );
    }
  }
  handleResponse() {
    let properties = {};
    return this.props.result.map((res, i) => {
      properties = res;
      if (properties != null) {
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            {Object.keys(properties).map((key) => {
              if (key == "pension") {
                let pensionlist = "";
                let pensionArray = properties[key];
                pensionArray.map((pensionItem) => {
                  pensionlist = "\n" + pensionItem["pension"];
                });
                return <th key={key}>{pensionlist}</th>;
              } else if (key == "diseases") {
                let diseaselist = "";
                let diseaseArray = properties[key];
                diseaseArray.map((diseaseItem) => {
                  diseaselist = "\n" + diseaseItem["disease"];
                });
                return <th key={key}>{diseaselist}</th>;
              } else if (!key.includes("photo") && !key.includes("p_d_f_")) {
                return <td key={key}>{properties[key]}</td>;
              }
            })}
          </tr>
        );
      }
    });
  }

  render() {
    return (
      <Draggable>
        <Modal className="tabledetails" show={this.props.show}>
          <Panel className="tabledetailsPanel">
            <Panel.Heading>
              <Panel.Title componentClass="h3">{this.props.title}</Panel.Title>
              <ButtonToolbar className="pull-right button">
                <img
                  src={closeButton}
                  className="panelheadbtn"
                  onClick={this.props.handleShowResult}
                />
              </ButtonToolbar>
            </Panel.Heading>
            <Panel.Body className="panelBody">
              <div>
                <Table
                  striped
                  bordered
                  condensed
                  hover
                  className="detailedInfoContent"
                  id="table-to-xls"
                >
                  <thead>{this.handleResponseHeader()}</thead>
                  <tbody>{this.handleResponse()}</tbody>
                </Table>
              </div>
              <Button
                bsStyle="danger"
                className="resultbutton"
                onClick={this.props.handleShowResult}
              >
                OK
              </Button>
            </Panel.Body>
          </Panel>
        </Modal>
      </Draggable>
    );
  }
}
const RenderRow = (props) => {
  return props.keys.map((key, index) => {
    return <td key={props.data[key]}>{props.data[key]}</td>;
  });
};
export default ResultModel;
