import React from 'react';

const RowHeadMapper = ({ rowHead }) => {
  const mapRowHeadToDisplayNames = (rowHead) => {

    const displayNameMap = {
      any_institute: "Any institute",
      other_flr_type: "Any other lower floor type",
      structural_change: "Any structural change",
      higher_floor_gt: "Higher floor > 250 m^2",
      ipms_tax_amount: "IPMS Tax Amount",
      near_road: "Near Road",
      new_pro_id: "New Property ID",
      normal_ac: "Normal AC",
      noofyears: "Number of Years",
      old_pro_id: "Old Property ID",
      place_name: "Place Name",
      street: "Street",
      unique_id: "Unique ID",
      village: "Village",
      year_const: "Year of Construction",
      building_name: "Building Name",
      bldg_condition: {
        condition: "Building Condition"
      },
      bldg_status: {
        status: "Building Status"
      },
      bldg_sub_type: {
        bldg_sub_type: "Building Sub Type"
      },
      bldg_usage: {
        bldg_usage: "Building Usage"
      },
      bldg_zone: {
        bldg_zone: "Building Zone"
      },
      door_status: {
        door_status: "Door Status"
      },
      postoffice: {
        postoffice: "Post Office"
      },
      road_direction: {
        road_direction: "Road Direction"
      },
      road_type: {
        road_type: "Road Type"
      },
      road_width: {
        id: "Road Width"
      },
      ward: {
        ward_no: "Ward Number"
      },

      members: {
        age: "Age",
        firstname: "First Name",
        lastname: "Last Name",
        aadhaar_number: "Aadhaar Number",
        bank_account: "Bank Account",
        bank_type: "Bank Type",
        blood_group__group: "Blood Group",
        caste__caste: "Caste",
        education_category__category: "Education Category",
        education__education: "Education",
        gender__gender: "Gender",
        is_alive: "Alive Status",
        is_coolie_wage: "Coolie Wage",
        is_month: "Monthly Wage",
        is_qualified: "Qualified",
        marital_status__status: "Marital Status",
        occuppation__job: "Occupation",
        nri_or_nrk: "NRI or NRK",
        pension__pension: "Pension",
        religion__religion: "Religion"
      },

      tax_details: {
        assessment_no: "Assessment Number",
        tax_amount: "Tax Amount",
        tax_annual: "Annual Tax",
        tax_bill_no: "Tax Bill Number",
        taxpaid_date: "Tax Paid Date",
        taxpaid_year: "Tax Paid Year"
      },

      establishment: {
        estb_name: "Establishment Name",
        estb_post: "Establishment Post",
        email: "Email",
        estb_type: "Establishment Type",
        estb_year: "Establishment Year",
        gst_no: "GST Number",
        gst_status: "GST Status",
        inchrg_name: "In-Charge Name",
        prof_tax_pay_status: "Professional Tax Payment Status",
        no_of_employees: "Number of Employees",
        mobile: "Mobile",
        license: "License",
        license_no: "License Number",
        inchrg_post: "In-Charge Post",
        inchrg_role: "In-Charge Role",
        landphone: "Landline"
      },

      owners: {
        owner_house: "Owner House",
        owner_name_sanjaya: "Owner Name (Sanjaya)",
        owner_occup__job: "Owner Occupation",
        ownr_pin: "Owner PIN",
        owner_post: "Owner Post",
        ownr_state: "Owner State",
        owner_survey_no: "Owner Survey Number"
      },

      survey_details: {
        cooperation: "Cooperation",
        informed_by: "Informed By",
        neighbour_name: "Neighbour Name",
        new_pro_rmk: "New Property Remark",
        old_pro_rmk: "Old Property Remark",
        ownership_change: "Ownership Change",
        remark: "Remark"
      },

      tenant_details: {
        rent_amount: "Rent Amount",
        tnt_house_name: "Tenant House Name",
        tnt_native: "Tenant Native",
        tnt_post_office: "Tenant Post Office",
        tenant_state: "Tenant State",
        tenant_status: "Tenant Status",
        tnt_survey_no: "Tenant Survey Number"
      },

      floor_prop_area: {
        floor_area: "Floor Area",
        floor_category__floor_name: "Floor Category"
      },

      member_diseases: {
        disease__disease: "Disease"
      },

      property_roof_type: {
        roof_type__roof_category: "Roof Type",
        roof_per: "Roof Percentage"
      },

      property_floor_type: {
        floor_type__floor_category: "Floor Type"
      },

      property_other_details: {
        android_mobile_phone: "Android Mobile Phone",
        ar_status: "AR Status",
        bathroom: "Bathroom"
      }
    };

    // Split rowHead to extract subKey and key (if it's in the format subKey (key))
    const [subKey, key] = rowHead.includes('(')
      ? rowHead.split(' (').map(part => part.replace(')', '').trim())
      : [rowHead];

    if (key && displayNameMap[key] && typeof displayNameMap[key] === 'object') {
      return displayNameMap[key][subKey] || rowHead;
    }

    // Return displayName from the main map if available, else return rowHead
    return displayNameMap[subKey] || rowHead;

  };


  const displayName = mapRowHeadToDisplayNames(rowHead);

  return <th>{displayName}</th>;
};

export default RowHeadMapper;
