import React, { Component } from "react";
import { connect } from "react-redux";
import "../style.css";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  Legend,
  LinearScale,
  BarElement,
} from "chart.js";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale
);
import { getDashboardSummary, getWards } from "../data/action";
import Crop from "ol-ext/filter/Crop";
import Map from "ol/Map.js";
import Draw from "ol/interaction/Draw.js";
import { XYZ, OSM } from "ol/source/";
import View from "ol/View.js";
import { GeoJSON, WFS } from "ol/format";
import { defaults as defaultControls } from "ol/control.js";
import { Group as LayerGroup, Tile as TileLayer } from "ol/layer.js";
import { MousePosition, ScaleLine } from "ol/control/";
import { createStringXY } from "ol/coordinate.js";
import { transform } from "ol/proj";
import getLayerTree from "../../map_viewer/map/helper/LayerTreeLoader";
import mapDefaults from "../../map_viewer/map/helper/mapDefaults";
import LocalStorageHandler from "../../../util/storage";
import axios from "axios";
import { AUTH_KEY } from "../../../config";
import { getLayers } from "../../map_viewer/map_header/advanced_filter/data/action";
import Feature_info from "../../map_viewer/map/widgets/feature_info";
import { updateMapReference } from "../../map_viewer/map/map_component/data/actions";
import DefaultExtend from "../../map_viewer/map/widgets/default_extend/DefaultExtend";
import { TileWMS } from "ol/source";
import generateLayer from "../../map_viewer/map/helper/LayerLoader";
import { Collection } from "ol";
import { snackbarActions } from "../../snackbar/data/action";
import VectorSource from "ol/source/Vector";

class PropertyMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diagrams: null,
      layers: [],
      baseLayers: this.getBaseLayers(),
      vectorSource: null,
      clickEvent: null,
      nodes: this.getMapLayerTree(),
      children: {},
    };
  }

  componentDidMount() {
    const dynamicLayers = this.dynamicTileLayer([
      LocalStorageHandler.getLoginResponse().cql_filter,
    ]);

    this.setState(
      {
        layers: dynamicLayers,
      },
      () => this.createOlMap()
    );
    this.setState({
      vectorSource: new VectorSource(),
    });

    this.layerUpdation();
  }

  componentDidUpdate(prevProps) {
    const { filters, layerData } = this.props;
    const { layers } = this.state;

    if (filters !== prevProps.filters) {
      this.layerUpdation();
    }
    if (prevProps.featureData != this.props.featureData) {
      // if (this.props.featureData.features.length > 0) {
      this.addSearchResultToMap();
      // }
    }
  }

  handleMapRenderComplete = (evt) => {
    this.setState({ renderEvent: evt });
  };

  createCropFeature = async () => {
    let layerTree = getLayerTree();
    let cropLayers;
    let feature;
    layerTree.map((treeItem) => {
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.forEach((layer) => {
          if (layer.layer_name == "drishti:ipms_localbody") {
            cropLayers = layer;
          }
        });
      }
    });
    await axios
      .get(cropLayers && cropLayers.url.concat("wfs"), {
        params: {
          version: "1.3.0",
          request: "GetFeature",
          outputFormat: "application/json",
          service: "WFS",
          typeName: cropLayers && cropLayers.layer_name,
          srsname: "EPSG:3857",
          cql_filter: cropLayers && cropLayers.cql_filter,
          authkey: AUTH_KEY,
        },
      })
      .then((response) => {
        feature = new GeoJSON().readFeatures(response.data);
      });

    return feature;
  };

  // createCropFeature = async () => {
  //   let layerTree = getLayerTree();
  //   let cropLayers;
  //   let feature;

  //   layerTree.map((treeItem) => {
  //     if (treeItem.hasOwnProperty("children")) {
  //       treeItem.children.forEach((layer) => {
  //         if (layer.layer_name == "drishti:ipms_localbody") {
  //           cropLayers = layer;
  //         }
  //       });
  //     }
  //   });

  //   const itemLimit = 100;
  //   const croplayer = cropLayers.cql_filter;
  //   const conditions = croplayer.split(", ");

  //   const conditionsPerRequest = 10;

  //   const conditionChunks = [];
  //   for (let i = 0; i < conditions.length; i += conditionsPerRequest) {
  //     conditionChunks.push(conditions.slice(i, i + conditionsPerRequest));
  //   }

  //   for (const [index, chunk] of conditionChunks.entries()) {
  //     const cqlFilterChunk = chunk.join(", ");
  //     const response = await makeRequestWithCqlFilter(cqlFilterChunk);
  //   }

  //   async function makeRequestWithCqlFilter(cqlFilter) {
  //     let len = cqlFilter && cqlFilter.length;
  //     if (len == 0) {
  //       cqlFilter = `${localBodyId}`;
  //     }
  //     const response = await axios.get(cropLayers.url.concat("wfs"), {
  //       params: {
  //         version: "1.3.0",
  //         request: "GetFeature",
  //         outputFormat: "application/json",
  //         service: "WFS",
  //         typeName: cropLayers.layer_name,
  //         srsname: "EPSG:3857",
  //         cql_filter: cqlFilter,
  //         authkey: AUTH_KEY,
  //       },
  //     });

  //     return new GeoJSON().readFeatures(response.data);
  //   }
  // };

  getMapLayerTree() {
    let layerTree = getLayerTree();
    let newLayerTree = [];

    layerTree.map((treeItem, i) => {
      let j = treeItem.layer_category_order - 1;
      newLayerTree[j] = layerTree[i];
    });

    newLayerTree.map((treeItem, i) => {
      let children = [];
      if (treeItem.hasOwnProperty("children")) {
        if (treeItem.children.length > 1) {
          treeItem.children.map((layer, i) => {
            let j = layer.layer_order - 1;
            children[j] = treeItem.children[i];
          });
          treeItem.children = children;
        }
      }
    });

    let mapLayerTree = [];
    newLayerTree.map((treeItem, i) => {
      let isBaseLayer = true;
      if (treeItem.hasOwnProperty("children")) {
        if (treeItem.children.length > 0) {
          treeItem.children.forEach((layer) => {
            if (layer.layer_type != "layer") {
              isBaseLayer = false;
            }
          });
          if (isBaseLayer) {
            mapLayerTree.push(newLayerTree[i]);
          }
        }
      }
    });
    return mapLayerTree;
  }

  getBaseLayers = () => {
    const osm = new TileLayer({
      name: "OSM",
      visible: true,
      isBaseLayer: true,
      source: new OSM({
        attributions: ["<b><a href='http://ults.in'>ULTS</a></b>"],
        crossOrigin: "anonymous",
      }),
    });

    const Satellite = new TileLayer({
      name: "Satellite",
      isBaseLayer: true,
      visible: false,
      source: new XYZ({
        url:
          "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiYWptYWxhdG0iLCJhIjoiY2tjNzFvbWNjMHg2ajJ2bWdlNzlmamU0MSJ9.z_qLRhyXbaBnJV4edvis-g",
        crossOrigin: "anonymous",
      }),
    });

    const googleSatellite = new TileLayer({
      name: "Google Satellite",
      isBaseLayer: true,
      visible: false,
      source: new XYZ({
        url: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
        crossOrigin: "anonymous",
      }),
    });

    const googleMap = new TileLayer({
      name: "Google Map",
      isBaseLayer: true,
      visible: false,
      source: new XYZ({
        url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
        crossOrigin: "anonymous",
      }),
    });

    const googleHybrid = new TileLayer({
      name: "Google Hybrid",
      isBaseLayer: true,
      visible: true,
      source: new XYZ({
        url: "http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
        crossOrigin: "anonymous",
      }),
    });

    let baseLayerData = LocalStorageHandler.getBaseLayers();
    let baseLayers = [];
    baseLayers.push(osm);
    baseLayers.push(Satellite);
    baseLayers.push(googleMap);
    baseLayers.push(googleSatellite);
    baseLayers.push(googleHybrid);

    baseLayerData.map(async (layerItem) => {
      if (layerItem.layer_type == "base_layer") {
        let layer = generateLayer(
          layerItem.layer_type,
          layerItem.label,
          layerItem.layer_name,
          layerItem.type,
          layerItem.url,
          null,
          null,
          layerItem.visibility,
          null,
          layerItem.opacity,
          layerItem.crop
        );
        baseLayers.push(layer);
      }
    });
    return baseLayers;
  };

  // dynamicTileLayer =(cqlFilter)=>{

  //         let gasConnectionLayer =[]
  //         let layerTree = getLayerTree();
  //         let layersFromTree = [];
  //         let localBodyId = [LocalStorageHandler.getLoginResponse().cql_filter]
  //         let layerName = ''
  //         let layerType;
  //         let url
  //         let infoAttributes;
  //         let opacity;
  //         let visibility;
  //         let urllayerName;
  //         let i;

  //         layerTree.map((treeItem) => {
  //             if (treeItem.hasOwnProperty("children")) {
  //                 treeItem.children.forEach((layer) => {
  //                     if (layer.layer_type == "layer") {
  //                         layersFromTree.push(layer);
  //                     }
  //                 });
  //             }
  //         });

  //         layersFromTree.forEach((treeLayerItem) => {
  //             if(treeLayerItem.model_name === "Property" && treeLayerItem.value !== "Door Closed" && treeLayerItem.value !== "Door Opened" && treeLayerItem.value !="Permanently Door Closed" && treeLayerItem.value !== "All Asset")
  //              {
  //              url = treeLayerItem.url;
  //             if (url != undefined) {
  //                  infoAttributes = {
  //                     minimalInfoAttributes: treeLayerItem.minimalInfoAttributes,
  //                     detailedInfoAttributes: treeLayerItem.detailedInfoAttributes,
  //                 };
  //                 layerName = treeLayerItem.value;
  //                  layerType = treeLayerItem.layer_type;
  //                  opacity =  treeLayerItem.opacity;
  //                  visibility = true
  //                  urllayerName  = treeLayerItem.layer_name

  // let len = cqlFilter && cqlFilter.length

  //    for(i=0;i<len;i++){

  //     if(treeLayerItem.type === "TILE")
  //     {
  //         let layers = new TileLayer({
  //             name: layerName,
  //             layerType: layerType,
  //             infoAttributes: infoAttributes,
  //             visible: visibility,
  //             minZoom: 15,
  //             opacity: opacity,
  //             source: new TileWMS({
  //                 url: url + "wms",
  //                 params: {
  //                     LAYERS:  urllayerName,
  //                     CQL_FILTER: `${localBodyId} AND ${cqlFilter[i]}`,
  //                     TILED: true,
  //                     EXCEPTIONS: "application/vnd.ogc.se_inimage",
  //                     VERSION: "1.1.0",
  //                     SRS: "EPSG:4326",
  //                     STYLES: treeLayerItem.style,
  //                 },
  //                 serverType: "geoserver",
  //                 transition: 0,
  //                 crossOrigin: "anonymous",
  //             })
  //         })
  //         gasConnectionLayer.push(layers)
  //             }
  //         }
  //      }
  //  }
  // })
  // return gasConnectionLayer
  // }

  dynamicTileLayer = (cqlFilter) => {
    let gasConnectionLayer = [];
    let layerTree = getLayerTree();
    let layersFromTree = [];
    let localBodyId = [LocalStorageHandler.getLoginResponse().cql_filter];
    let layerName = "";
    let layerType;
    let url;
    let infoAttributes;
    let opacity;
    let visibility;
    let urllayerName;
    let i;

    layerTree.map((treeItem) => {
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.forEach((layer) => {
          if (layer.layer_type == "layer") {
            layersFromTree.push(layer);
          }
        });
      }
    });

    layersFromTree.forEach((treeLayerItem) => {
      if (
        treeLayerItem.model_name === "Property" &&
        treeLayerItem.value !== "Door Closed" &&
        treeLayerItem.value !== "Door Opened" &&
        treeLayerItem.value != "Permanently Door Closed" &&
        treeLayerItem.value !== "All Asset"
      ) {
        url = treeLayerItem.url;

        if (url != undefined) {
          infoAttributes = {
            minimalInfoAttributes: treeLayerItem.minimalInfoAttributes,
            detailedInfoAttributes: treeLayerItem.detailedInfoAttributes,
          };

          layerName = treeLayerItem.value;
          layerType = treeLayerItem.layer_type;
          opacity = treeLayerItem.opacity;
          visibility = true;
          urllayerName = treeLayerItem.layer_name;

          let len = cqlFilter && cqlFilter.length;

          if (len == 0) {
            if (treeLayerItem.type === "TILE") {
              let layers = new TileLayer({
                name: layerName,
                layerType: layerType,
                infoAttributes: infoAttributes,
                visible: visibility,
                minZoom: 15,
                opacity: opacity,
                source: new TileWMS({
                  url: url + "wms",
                  params: {
                    LAYERS: urllayerName,
                    CQL_FILTER: `${localBodyId} `,
                    TILED: true,
                    EXCEPTIONS: "application/vnd.ogc.se_inimage",
                    VERSION: "1.1.0",
                    SRS: "EPSG:4326",
                    STYLES: treeLayerItem.style,
                  },
                  serverType: "geoserver",
                  transition: 0,
                  crossOrigin: "anonymous",
                }),
              });
              gasConnectionLayer.push(layers);
            }
          } else {
            for (i = 0; i < len; i++) {
              if (treeLayerItem.type === "TILE") {
                let layers = new TileLayer({
                  name: layerName,
                  layerType: layerType,
                  infoAttributes: infoAttributes,
                  visible: visibility,
                  minZoom: 15,
                  opacity: opacity,
                  source: new TileWMS({
                    url: url + "wms",
                    params: {
                      LAYERS: urllayerName,
                      CQL_FILTER: `${localBodyId} AND ${cqlFilter[i]}`,
                      TILED: true,
                      EXCEPTIONS: "application/vnd.ogc.se_inimage",
                      VERSION: "1.1.0",
                      SRS: "EPSG:4326",
                      STYLES: treeLayerItem.style,
                    },
                    serverType: "geoserver",
                    transition: 0,
                    crossOrigin: "anonymous",
                  }),
                });
                gasConnectionLayer.push(layers);
              }
            }
          }
        }
      }
    });
    return gasConnectionLayer;
  };

  createOlMap = () => {
    const { layers, baseLayers } = this.state;
    const center = [75.592, 11.5935];
    let defaultCenter = LocalStorageHandler.getLocalBody()
      .default_centre.split(",")
      .map(Number);

    // Update or change the value (adjust the latitude value)
    defaultCenter[1] = defaultCenter[1] - 0.0081;
    // const centers = [...defaultCenter, defaultCenter[1]];
    console.log("center vlaues", defaultCenter);

    if (!this.mapComponent) {
      let localBody = null;
      if (LocalStorageHandler.getLocalBody() !== null) {
        localBody = LocalStorageHandler.getLocalBody().local_body_name;
        this.setState({
          localBody: localBody,
        });
      }

      this.mapComponent = new Map({
        controls: defaultControls({
          collapsible: false,
        }).extend([
          new ScaleLine(),
          new MousePosition({
            undefinedHTML: "",
            coordinateFormat: createStringXY(4),
            projection: "EPSG:4326",
          }),
        ]),
        layers: [
          new LayerGroup({ layers: baseLayers }),
          new LayerGroup({ layers }),
        ],
        target: "RoadMap",
        view: new View({
          center:
            // transform(
            //   mapDefaults.getDefaultCenter(),
            //   "EPSG:4326",
            //   "EPSG:3857"
            // ),
            transform(defaultCenter, "EPSG:4326", "EPSG:3857"),
          zoom: mapDefaults.getDefaultZoom(),
          minZoom: mapDefaults.getDefaultZoom(),
        }),
      });

      this.mapComponent.on("singleclick", (evt) => this.handleMapClick(evt));
      this.mapComponent.on("pointermove", (evt) =>
        this.handleMapPointerMove(evt)
      );

      this.mapComponent.once("postcompose", async (evt) => {
        this.handleMapRenderComplete(evt);
        let cropFeature = await this.createCropFeature();
        baseLayers.forEach((layer) => {
          if (layer.get("crop") === true) {
            if (cropFeature && cropFeature.length > 0) {
              var crop = new Crop({
                feature: cropFeature[0],
                inner: false,
                active: true,
              });
              layer.addFilter(crop);
            }
          }
        });
      });

      this.props.updateMapReference(this.mapComponent);
    } else {
      this.mapComponent
        .getLayers()
        .getArray()[1]
        .setLayers(new Collection(layers));
    }
  };

  handleMapClick = (evt) => {
    let drawInteraction = this.mapComponent
      .getInteractions()
      .getArray()
      .filter((interaction) => {
        if (interaction instanceof Draw) return interaction;
      });
    if (drawInteraction.length > 0) return;
    if (!evt.dragging) this.setState({ clickEvent: evt });
  };

  handleMapPointerMove = (evt) => {
    if (evt.dragging) return;
    try {
      var pixel = this.mapComponent.getEventPixel(evt.originalEvent);
      var hit = this.mapComponent.forEachLayerAtPixel(pixel, (layer) => {
        if (!layer.getProperties().isBaseLayer) return true;
      });
      this.mapComponent.getTargetElement().style.cursor = hit ? "pointer" : "";
    } catch (e) {
      console.log("Excepion", e);
    }
  };

  componentWillUnmount() {
    if (this.mapComponent) {
      this.mapComponent.un("pointermove", this.handleMapPointerMove);
      this.mapComponent.setTarget(undefined);
    }
  }

  layerUpdation = () => {
    const { nodes, layers } = this.state;
    const { filters, layerData } = this.props;

    let cqlFilter = [];
    let labelData = [];
    let buildingAsset = "Building Asset";
    let localBodyId = LocalStorageHandler.getLoginResponse().cql_filter;
console.log(filters,"filters");

    const fields = Object.keys(filters)
      .filter(
        (key) =>
          filters[key] !== null &&
          filters[key] !== "" &&
          filters[key] !== "undefined"
      )
      .map((key) => {
        const filterValues = filters[key];
        const value = Array.isArray(filterValues)
          ? filterValues.map((item) => item.new_pro_id)
          : filterValues;

        const field = {
          new_pro_id: value,
        };
        return field;
      });

    nodes.find((Item) => {
      if (Item.label === buildingAsset) {
        let children = Item.children;
        children.forEach((items) => {
          labelData = [...labelData, items.label];
        });
      }
    });
console.log("fields",fields);

    const getDistinctValue = (value) => {
      let data;
      fields.forEach((item) => {
        data = item[value];
      });
      return data;
    };


    const chunkSize = 250;
    const distinctValues = getDistinctValue("new_pro_id");
console.log("distinctValues",distinctValues);

    const chunkedDistinctValues = [];

    if (distinctValues && distinctValues.length) {
      for (let i = 0; i < distinctValues.length; i += chunkSize) {
        chunkedDistinctValues.push(distinctValues.slice(i, i + chunkSize));
      }
    }

    chunkedDistinctValues.forEach((chunk) => {
      const gas_conn = `new_pro_id IN (${chunk
        .map((value) => `'${value}'`)
        .join(", ")})`;

      if (gas_conn === "None" || gas_conn === "") {
        cqlFilter.push(localBodyId);
      } else {
        cqlFilter.push(gas_conn);
      }
    });

    layers.forEach((layer) => {
      const layerName = layer.getProperties().name;
      const checkedLayerName = "All Asset";

      if (
        labelData.includes(layerName) &&
        !checkedLayerName.includes(layerName)
      ) {
        const cqlfiltervalue = this.dynamicTileLayer(cqlFilter);
console.log("cqlfilter",cqlfiltervalue);

        this.setState(
          {
            layers: cqlfiltervalue,
          },
          () => this.createOlMap()
        );
      } else {
        layer.setVisible(false);
      }
    });
  };

  addSearchResultToMap(layer) {
    const { mapComponent, featureData } = this.props;

    // DropdownService.layerExtendFilter()
    const vectorSource = this.state.vectorSource;
    vectorSource.clear();

    // basicsearchapi---//
    if (featureData != null) {
      let features = new GeoJSON().readFeatures(featureData);

      if (features != []) {
        vectorSource.addFeatures(features);

        //  const extend = [8413553.733662792, 1296077.885636299, 8416804.246468673, 1301290.0752649156]

        mapComponent && mapComponent.getView().fit(vectorSource.getExtent());
      }
      // else{
      //   alert("no feature found")
      // }
    } else {
      mapComponent &&
        mapComponent
          .getView()
          .setCenter(
            transform(mapDefaults.getDefaultCenter(), "EPSG:4326", "EPSG:3857")
          );
      mapComponent &&
        mapComponent.getView().setZoom(mapDefaults.getDefaultZoom());
    }
  }

  render() {
    return (
      <div style={{ width: "100%", height: "70%", marginTop: "5px" }}>
        <div id="RoadMap" style={{ width: "100%", position: "relative" }}>
          <DefaultExtend />
        </div>
        <Feature_info
          clickEvent={this.state.clickEvent}
          mapLayers={this.state.layers}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dashboardSummary: state.dashboard.dashboardSummary,
    wards: state.dashboard.wards,
    isWardsLoaded: state.dashboard.isWardsLoaded,
    mapComponent: state.mapReducer.OlMap,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDashboardSummary: (localBodyId, ward) =>
      dispatch(getDashboardSummary(localBodyId, ward)),
    getWards: (localBodyId) => dispatch(getWards(localBodyId)),
    getLayers: () => dispatch(getLayers()),
    updateMapReference: (mapComponent) =>
      dispatch(updateMapReference(mapComponent)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PropertyMap);
