//Local
// export const BASE_URL = "http://localhost:8000/";
// export const ADMIN_URL = "http://localhost:8000/";
// export const BASE_URL = "http://localhost:8000/";
// export const ADMIN_URL = "http://localhost:8000/";


// //DEV
// export const BASE_URL = "http://dev.ipms.ults.build:6071/";
// export const ADMIN_URL = "http://dev.ipms.ults.build:6071/";
//QA
export const BASE_URL = "http://drishtitestadmin.ulgis.com/";
export const ADMIN_URL = "http://drishtitestadmin.ulgis.com/";
//Live


// export const BASE_URL = "https://drishtiadmin.ulgis.com/";
// export const ADMIN_URL = "https://drishtiadmin.ulgis.com/";
//DEMO
// export const BASE_URL = "http://demo.ulgis.com:6072/";
// export const ADMIN_URL = "http://demo.ulgis.com:6072/";
// // LIVE
// export const BASE_URL = "https://drishtiadmin.ulgis.com/";
// export const ADMIN_URL = "https://drishtiadmin.ulgis.com/";
//PROD
export const DOMAIN_URL = "https://qa.ipms.ults.build/map";
export const CAPTCHA_KEY = "6LePM_UdAAAAAI7OcTxjB94PeOO8BvQPIJJ8FlvP";
export const CAPTCHA_KEY_SERVER = "6LePM_UdAAAAAEErBMCzamnRQpcABlrwhuGQnjWo";
export const AUTH_KEY = "a1213a5b-5131-41e4-97bb-ebcf80de3923";
export const PUSHER_KEY = "349e24561a8d3df9cbaf";
