import { TileWMS, XYZ, WMTS } from "ol/source/";
import Projection from "ol/proj/Projection";
import TiledWMTS from "ol/tilegrid/WMTS";
import GeoJSON from "ol/format/GeoJSON.js";
import { Vector as VectorLayer, Tile as TileLayer } from "ol/layer/";
import VectorSource from "ol/source/Vector.js";
import axios from "axios";
import { bbox as bboxStrategy } from "ol/loadingstrategy.js";
import LocalStorageHandler from "../../../../util/storage";
import { AUTH_KEY } from "../../../../config";

export default function generateLayer(
  layerType,
  layerName,
  urlLayerName,
  type,
  url,
  style,
  cql_filter,
  visibility,
  infoAttributes,
  opacity,
  crop
) {
  let createdLayer;
  let gridsetName = "EPSG:4326";
  let gridNames = [
    "EPSG:4326:0",
    "EPSG:4326:1",
    "EPSG:4326:2",
    "EPSG:4326:3",
    "EPSG:4326:4",
    "EPSG:4326:5",
    "EPSG:4326:6",
    "EPSG:4326:7",
    "EPSG:4326:8",
    "EPSG:4326:9",
    "EPSG:4326:10",
    "EPSG:4326:11",
    "EPSG:4326:12",
    "EPSG:4326:13",
    "EPSG:4326:14",
    "EPSG:4326:15",
    "EPSG:4326:16",
    "EPSG:4326:17",
    "EPSG:4326:18",
    "EPSG:4326:19",
    "EPSG:4326:20",
    "EPSG:4326:21",
  ];

  let projection = new Projection({
    code: "EPSG:4326",
    units: "degrees",
    axisOrientation: "neu",
  });
  let resolutions = [
    0.703125,
    0.3515625,
    0.17578125,
    0.087890625,
    0.0439453125,
    0.02197265625,
    0.010986328125,
    0.0054931640625,
    0.00274658203125,
    0.001373291015625,
    6.866455078125e-4,
    3.4332275390625e-4,
    1.71661376953125e-4,
    8.58306884765625e-5,
    4.291534423828125e-5,
    2.1457672119140625e-5,
    1.0728836059570312e-5,
    5.364418029785156e-6,
    2.682209014892578e-6,
    1.341104507446289e-6,
    6.705522537231445e-7,
    3.3527612686157227e-7,
  ];
  let params = {};
  let localBody = null;
  if (LocalStorageHandler.getLocalBody() !== null) {
    localBody = LocalStorageHandler.getLocalBody().local_body_name;
  }
  if (LocalStorageHandler.getLocalBody().ward_filter !== null) {
    let cql_ward = LocalStorageHandler.getLocalBody().ward_filter;
   
    if (layerName == "Residential" || layerName == "Commercial") {
      cql_filter 
    }
  }
  
  params = {
    LAYERS: urlLayerName,
    CQL_FILTER: cql_filter,
    TILED: true,
    EXCEPTIONS: "application/vnd.ogc.se_inimage",
    VERSION: "1.1.0",
    SRS: "EPSG:4326",
  };
  
  if (style != null) {
    params.STYLES = style;
  }
  if (type === "TILE") {
    createdLayer = new TileLayer({
      name: layerName,
      layerType: layerType,
      infoAttributes: infoAttributes,
      visible: visibility,
      minZoom: 15,
      opacity: opacity,
      source: new TileWMS({
        url: url + "wms",
        params: params,
        serverType: "geoserver",
        // Countries have transparency, so do not fade tiles:
        transition: 0,
        crossOrigin: "anonymous",
      }),
    });
  } else if (type === "wmts") {
    createdLayer = new TileLayer({
      name: layerName,
      layerType: layerType,
      crop: crop,
      visible: visibility,
      opacity: opacity / 100,
      source: new WMTS({
        url: url + "gwc/service/wmts",
        layer: urlLayerName,
        matrixSet: gridsetName,
        format: "image/png",
        projection: projection,
        tileGrid: new TiledWMTS({
          tileSize: [256, 256],
          extent: [-180.0, -90.0, 180.0, 90.0],
          origin: [-180.0, 90.0],
          resolutions: resolutions,
          matrixIds: gridNames,
        }),
        style: style,
        wrapX: true,
        crossOrigin: "anonymous",
      }),
    });
  } else if (type === "xyz") {
    createdLayer = new TileLayer({
      name: layerName,
      visible: visibility,
      opacity: opacity / 100,
      source: new XYZ({
        url: url + ".png",
      }),
    });
  } 
  else if (type == "VECTOR") {
    createdLayer = new VectorLayer({
      name: layerName,
      layerType: layerType,
      visible: false,
      infoAttributes: infoAttributes,
      source: new VectorSource({
        loader: function(extent) {
          axios(url + "athavanad/ows?", {
            params: {
              service: "WFS",
              version: "1.1.0",
              request: "GetFeature",
              typename: urlLayerName,
              srsname: "EPSG:3857",
              outputFormat: "application/json",
              bbox: extent.join(",") + ",EPSG:3857",
              authkey: AUTH_KEY,
            },
          }).then(function(response) {
            createdLayer
              .getSource()
              .addFeatures(new GeoJSON().readFeatures(response.data));
          });
        },
        strategy: bboxStrategy,
        projection: "EPSG:3857",
      }),
    });
  }

  return createdLayer;
}
