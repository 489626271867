import React, { Component } from "react";
import { connect } from "react-redux";

import "./style.css";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { Col, Row } from "react-bootstrap";
import DashboardMap from "./DashboardMap";
import RoadMap from "./Analysis/RoadMap";
import { X } from "@mui/icons-material";
import {
  getPropertyID,
  getSecondDashboardData,
  getWardList,
} from "./data/action";
// import { PieChart } from "@mui/icons-material";
// import { Button, Col, Form, FormControl, FormGroup, Row } from "react-bootstrap";
// import Form from 'react-bootstrap';
// import {
//   Chart as ChartJS,
//   ArcElement,
//   Tooltip,
//   CategoryScale,
//   Legend,
//   LinearScale,
//   BarElement,
// } from "chart.js";
// import { Pie, Bar } from "react-chartjs-2";
// import { getData } from "./data";
// ChartJS.register(
//   ArcElement,
//   Tooltip,
//   Legend,
//   LinearScale,
//   BarElement,
//   CategoryScale
// );

class SecondDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      area_range: [0, 2800000],
      page: 0,
      rowsPerPage: 10,
      ward_id: null,
    };
  }


  componentDidMount() {
    this.props.getWardList();
    this.props.getSecondDashboardData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.ward_id !== prevState.ward_id) {
      this.props.getSecondDashboardData(this.state.ward_id);
    }
    // if (
    //   !this.state.ward_id &&
    //   this.props.wardList &&
    //   prevProps.wardList !== this.props.wardList
    // ) {
    //   const firstWardId = Object.keys(this.props.wardList)[0];
    //   this.setState({ ward_id: firstWardId });
    //   console.log("firstWardID componentDidUpdate", firstWardId);

    //   this.props.getSecondDashboardData(firstWardId);
    // }
    if (this.state.area_range !== prevState.area_range) {
      let data = null;

      if (this.state.ward_id) {
        data = {
          max_area: this.state.area_range[1],
          min_area: this.state.area_range[0],
          ward: parseInt(this.state.ward_id),
        };
      } else {
        data = {
          max_area: this.state.area_range[1],
          min_area: this.state.area_range[0],
        };
      }
      this.props.getPropertyID(data);
    }

    // if(this.props.property_id !== null && this.props.property_id.length > 0){
    //   console.log("property details",this.props.property_id);

    //   this.setState({

    //   })
    // }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {

    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  handleWardChange = (e) => {
    this.setState((prev) => ({
      ...prev,
      ward_id: e.target.value,
    }));
  };

  areaTableData = (property_id, floor_area, assessment_area, area_diff) => {
    return { property_id, floor_area, assessment_area, area_diff };
  };

  handleRangeChange = (event, value) => {
    console.log("value", value);
    this.setState({
      area_range: value,
      //   max_area:value[1],
      // min_area:value[0]
    });
  };

  render() {
    const barData = {
      labels: ["Category 1"],
      datasets: [
        {
          label: "Value A",
          data: [30], // Value for Category 1
          backgroundColor: "#36A2EB",
        },
        {
          label: "Value B",
          data: [50], // Value for Category 1
          backgroundColor: "#FF6384",
        },
        {
          label: "Value C",
          data: [20],
          backgroundColor: "#FFCE56",
        },
      ],
    };

    const barOptions = {
      indexAxis: "y", // Makes the bar chart horizontal
      scales: {
        x: {
          stacked: true, // Stacks the bars horizontally
          beginAtZero: true,
        },
        y: {
          stacked: true, // Stacks the bars
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: "top", // Position of the legend
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            font: {
              size: 8
            }
          },
        },
        title: {
          display: true,
          text: "Single Stacked Horizontal Bar Chart",
        },
      },
    };

    const options = {
      rotation: -90,
      circumference: 180,
      cutout: "70%",
    };

    const secondDahboardData = this.props.secondDashboardData;
    console.log("secondDahboardData", secondDahboardData);


    // doughnut building area

    const percentage =
      (secondDahboardData?.floor_prop_area_gt_250 /
        secondDahboardData?.building_count_asper_IPMS) *
      100;
    const totalValue = secondDahboardData?.building_count_asper_IPMS;
    const centerValue = secondDahboardData?.floor_prop_area_gt_250;

    const buildingAreaDoughnut = {
      datasets: [
        {
          data: [percentage, 100 - percentage],
          backgroundColor: ["#4bc0c0", "rgba(0, 0, 0, 0)"],
          borderWidth: 0,
          hoverOffset: 4,
        },
      ],
    };

    // bar chart doorStatus

    const doorStatusData = secondDahboardData?.door_status_counts;
    const doorStatusLabels = [];
    doorStatusData &&
      Object.keys(doorStatusData).map((key) => doorStatusLabels.push(key));
    const doorStatusValues = [];
    doorStatusData &&
      Object.keys(doorStatusData).map((key) =>
        doorStatusValues.push(doorStatusData[key])
      );

    const doorStatusBarChart = {
      labels: doorStatusLabels,
      datasets: [
        {
          data: doorStatusValues,
          backgroundColor: [
            "rgba(255, 99, 132, 0.6)",
            "rgba(54, 162, 235, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(75, 192, 192, 0.6)",
            "rgba(153, 102, 255, 0.6)",
            "rgba(235, 149, 52,0.6)",
            "rgba(164, 194, 76,0.6)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(235, 149, 52,1)",
            "rgba(164, 194, 76,1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const doorStatusOptions = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 6,
            },
            color: "#333",
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 12,
            },
            color: "#333",
          },
        },
      },
      layout: {
        padding: 8,
      },
    };

    // properties vs ration card

    const rationCardColorData = secondDahboardData?.ration_card_colors;

    const rationCardColorLabels = [];
    rationCardColorData &&
      Object.keys(rationCardColorData).map((key) =>
        rationCardColorLabels.push(key)
      );
    const rationCardColorValues = [];
    rationCardColorData &&
      Object.keys(rationCardColorData).map((key) =>
        rationCardColorValues.push(rationCardColorData[key])
      );

    const rationCardColorDoughnutChart = {
      labels: rationCardColorLabels,
      datasets: [
        {
          data: rationCardColorValues,
          backgroundColor: [
            "rgba(139, 212, 241, 0.6)",
            "rgba(202, 128, 130, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(240, 61, 21, 0.6)",
            "rgba(244, 139, 116, 0.6)",
            "rgba(249, 246, 245,0.6)",
            "rgba(245, 238, 104,0.6)",
          ],
          borderColor: [
            "rgba(139, 212, 241, 1)",
            "rgba(202, 128, 130, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(240, 61, 21, 1)",
            "rgba(244, 139, 116, 1)",
            "rgba(249, 246, 245,1)",
            "rgba(245, 238, 104,1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const rationCardColorOptions = {
      plugins: {
        legend: {
          display: true, // Show or hide legend
          position: "right",
          font: {
            size: 10,
          },
          labels: {
            font: {
              size: 10,
              weight: "bold",
            },
            color: "#333",
            padding: 10,
            usePointStyle: true,
            pointStyle: "circle",
          },
          // radius:"90%"
        },
      },
    };

    const latrineData = secondDahboardData?.latrine_counts;

    const latrineLabels = [];
    latrineData &&
      Object.keys(latrineData).map((key) => latrineLabels.push(key));
    const latrineValues = [];
    latrineData &&
      Object.keys(latrineData).map((key) =>
        latrineValues.push(latrineData[key])
      );

    const latrineDoughnutChart = {
      labels: latrineLabels,
      datasets: [
        {
          data: latrineValues,
          backgroundColor: [
            "rgba(139, 212, 241, 0.6)",
            "rgba(202, 128, 130, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(240, 61, 21, 0.6)",
            "rgba(244, 139, 116, 0.6)",
            "rgba(249, 246, 245,0.6)",
            "rgba(245, 238, 104,0.6)",
          ],
          borderColor: [
            "rgba(139, 212, 241, 1)",
            "rgba(202, 128, 130, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(240, 61, 21, 1)",
            "rgba(244, 139, 116, 1)",
            "rgba(249, 246, 245,1)",
            "rgba(245, 238, 104,1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const latrineOptions = {
      plugins: {
        legend: {
          display: true,
          position: "right",
          font: {
            size: 10,
          },
          labels: {
            font: {
              size: 10,
              weight: "bold",
            },
            color: "#333",
            padding: 15,
            usePointStyle: true,
            pointStyle: "circle",
          },
        },
      },
    };

    // bathroom_counts

    const bathroomData = secondDahboardData?.bathroom_counts;

    const bathroomLabels = [];
    bathroomData &&
      Object.keys(bathroomData).map((key) => bathroomLabels.push(key));
    const bathroomValues = [];
    bathroomData &&
      Object.keys(bathroomData).map((key) =>
        bathroomValues.push(bathroomData[key])
      );

    const bathroomDoughnutChart = {
      labels: bathroomLabels,
      datasets: [
        {
          data: bathroomValues,
          backgroundColor: [
            "rgba(139, 212, 241, 0.6)",
            "rgba(202, 128, 130, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(240, 61, 21, 0.6)",
            "rgba(244, 139, 116, 0.6)",
            "rgba(249, 246, 245,0.6)",
            "rgba(245, 238, 104,0.6)",
          ],
          borderColor: [
            "rgba(139, 212, 241, 1)",
            "rgba(202, 128, 130, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(240, 61, 21, 1)",
            "rgba(244, 139, 116, 1)",
            "rgba(249, 246, 245,1)",
            "rgba(245, 238, 104,1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const bathroomOptions = {
      plugins: {
        legend: {
          display: true, // Show or hide legend
          position: "right",
          font: {
            size: 10,
          },
          labels: {
            font: {
              size: 10, // Legend font size
              weight: "bold", // Font weight
            },
            color: "#333", // Color of legend text
            padding: 15, // Space between legend items
            usePointStyle: true,
            pointStyle: "circle",
          },
        },
      },
    };

    const ward_name = secondDahboardData?.ward_name;
    const presi_name = secondDahboardData?.President;
    const viceP_name = secondDahboardData?.Vice_President;
    const member_name = secondDahboardData?.ward__designation_data?.Member;

    const ward_designation = secondDahboardData?.ward__designation_data;
    // console.log("secondDahboardData", secondDahboardData);

    const areaDiffTableData = secondDahboardData?.area_difference;

    const areaTableColumns = [
      { id: "title", label: "Property Id" },
      { id: "floor_area", label: "Floor" },
      { id: "assessment_area", label: "Assessment Area" },
      {
        id: "area_diff",
        label: "Area Difference",
      },
    ];

    let areaTableArray =
      areaDiffTableData &&
      Object.entries(areaDiffTableData).map(([title, columns]) => ({
        title,
        ...columns,
      }));

    console.log("are table values", areaTableArray);
    // areaTableArray?.map(())

    const maxItem = areaTableArray?.reduce((prev, current) => {
      return current.floor_area > prev.floor_area ? current : prev;
    });
    console.log("response max values", maxItem);

    const bldgCateGroupData = secondDahboardData?.bldg_category_counts;
    const bldgCateGroupLabels = [];
    bldgCateGroupData &&
      Object.keys(bldgCateGroupData).map((key) =>
        bldgCateGroupLabels.push(key)
      );
    const bldgCateGroupValues = [];
    bldgCateGroupData &&
      Object.keys(bldgCateGroupData).map((key) =>
        bldgCateGroupValues.push(bldgCateGroupData[key])
      );

    const bldgCateGroupBarChart = {
      labels: bldgCateGroupLabels,
      datasets: [
        {
          data: bldgCateGroupValues,
          backgroundColor: [
            "rgba(255, 99, 132, 0.6)",
            "rgba(54, 162, 235, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(75, 192, 192, 0.6)",
            "rgba(153, 102, 255, 0.6)",
            "rgba(235, 149, 52,0.6)",
            "rgba(164, 194, 76,0.6)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(235, 149, 52,1)",
            "rgba(164, 194, 76,1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const bldgCateGroupOptions = {
      indexAxis: "y", // This makes the chart horizontal
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 6,
            },
            color: "#333",
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 12,
            },
            color: "#333",
          },
        },
      },
      layout: {
        padding: 8,
      },
    };

    const schemaCountValues = secondDahboardData?.scheme_counts;

    let dataSetValues = [];
    let backgroundColors = [
      "rgba(139, 212, 241, 0.6)",
      "rgba(202, 128, 130, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(240, 61, 21, 0.6)",
      "rgba(244, 139, 116, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(245, 238, 104, 0.6)",
      "rgba(153, 102, 255, 0.6)",
    ];
    let borderColors = [
      "rgba(139, 212, 241, 1)",
      "rgba(202, 128, 130, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(240, 61, 21, 1)",
      "rgba(244, 139, 116, 1)",
      "rgba(255, 159, 64, 1)",
      "rgba(245, 238, 104, 1)",
      "rgba(153, 102, 255, 1)",
    ];
    schemaCountValues &&
      Object.keys(schemaCountValues).map((key, i) => {
        if (key != "null") {
          let value = {
            label: key,
            data: [schemaCountValues[key]],
            backgroundColor: backgroundColors[i],
            borderColor: borderColors[i],
            borderWidth: 1,
          };
          dataSetValues.push(value);
        }
      });

    const schemaCountBarData = {
      labels: [" "],
      datasets: dataSetValues,
    };
    return (
      <>
        <Row className="second-dashboard-main-row" style={{ height: "90%", width: "100%" }}>
          <Col lg={3} md={3} sm={3} xs={3} className="second-dashboard-col" style={{ height: "100%", width: "20%" }}>
            <div className="second-dashboard-btn" style={{ height: "12%" }}>
              <span className="second-dashboard-heading">Ward No</span>
              <div className="dropdown">
                <FormControl sx={{ m: 0.5, minWidth: 120 }} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={this.state.ward_id || null}
                    label="Ward"
                    onChange={this.handleWardChange}
                  >
                    {this.props.wardList &&
                      Object.keys(this.props.wardList).map((key, i) => (
                        <MenuItem key={i} value={key}>
                          {this.props.wardList[key]}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="second-dashboard-btn" style={{ height: "13%" }}>
              <span className="second-dashboard-heading">Total Building </span>
              <div style={{ width: "100%", display: "flex", justifyContent: "flex-start", paddingLeft: "10px" }}>
                <span className="second-dashboard-heading-key">
                  As per IPMS:
                  <span className="second-dashboard-heading-value">
                    {secondDahboardData?.building_count_asper_IPMS}
                  </span>
                </span>
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "flex-start", paddingLeft: "10px" }}>
                <span className="second-dashboard-heading-key">
                  As per AR  :{"  "}
                  <span className="second-dashboard-heading-value">
                    {secondDahboardData?.building_count_asper_AR}
                  </span>
                </span>
              </div>
            </div>
            <div className="second-dashboard-btn" style={{ height: "40%" }}>
              <span className="second-dashboard-heading">
                Building Area with {">"} 250 sqm
              </span>
              {/* <Doughnut data={buildingAreaDoughnut} options={options} /> */}
              <Doughnut
                data={buildingAreaDoughnut}
                options={{
                  ...options,
                  radius: "50%",
                  responsive: true,
                  maintainAspectRatio: false,
                }}
                // options={{ ...options, radius: '50%', maxHeight: '80px' }} 
                // style={{ height: "150px", width: "150px" }} 
                height={200}
                style={{ height: "80%" }}
              />

              <div className="second-dashboard-doughnuts-values" style={{ height: "20%", position: "relative", bottom: "45px" }}>
                <span className="second-dashoard-doughnut-start-value">0</span>
                <span className="second-dashoard-doughnut-center-value">
                  {centerValue}
                </span>

                <span className="second-dashoard-doughnut-end-value">
                  {totalValue}
                </span>
              </div>
            </div>
            <div className="second-dashboard-doorStatusBarChart" style={{ height: "35%" }}>
              <span className="second-dashboard-heading">
                Count of Buildings by Door Status
              </span>
              <Bar data={doorStatusBarChart} options={doorStatusOptions} />
              {/* <Bar data={doorStatusBarChart} options={{...doorStatusOptions, maintainAspectRatio: true, responsive: false}} /> */}
            </div>

          </Col>
          <Col lg={6} md={6} sm={6} xs={6} className="second-dashboard-col" style={{ height: "100%", width: "60%" }}>
            <Row className="second-dashboard-row" style={{ height: "40%" }}>
              <Col
                className="second-dashboard-row-col"
                lg={6}
                md={6}
                sm={6}
                xs={6}
                style={{ height: "100%", width: "35%" }}
              >
                <div className="second-dashboard-btn" style={{ height: "30%" }}>
                  <span className="second-dashboard-heading">
                    Building Area
                  </span>
                  <Slider
                    className="second-dashboard-slider-range"
                    value={this.state.area_range}
                    onChange={this.handleRangeChange}
                    style={{ marginBottom: "10px !important", color: "grey" }}
                    valueLabelDisplay="auto"
                    min={0}
                    // max={2800}
                    max={maxItem?.area_diff}
                    // step={10}
                    marks={[
                      { value: 0, label: "0" },
                      {
                        value: maxItem?.area_diff,
                        label: `${maxItem?.area_diff}`,
                      },
                    ]}
                  />
                </div>
                <div className="second-dashboard-btn" style={{ height: "70%" }}
                >
                  <span className="second-dashboard-heading">
                    Properites Vs Ration Card Color
                  </span>

                  <Doughnut
                    className="doughnut-container"
                    data={rationCardColorDoughnutChart}
                    options={{
                      ...rationCardColorOptions,
                      maintainAspectRatio: true,
                      responsive: true
                    }}
                    height={150}
                    width={200}
                  />
                </div>
              </Col>
              <Col
                className="second-dashboard-row-col"
                lg={6}
                md={6}
                sm={6}
                xs={6}
                style={{ height: "100%", width: "65%" }}
              >
                <div className="second-dashboard-btn" style={{ height: "50%" }}>
                  <span className="second-dashboard-heading">
                    Houses by scheme
                  </span>

                  <Bar
                    className="bar-container"
                    data={schemaCountBarData}
                    options={{
                      ...barOptions,
                      maintainAspectRatio: false,
                      responsive: true
                    }}
                    height="90"
                    width="400"
                  />
                </div>
                <Row className="second-dashboard-pie-chart-row" style={{ height: "50%" }}>
                  <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="second-dashboard-row-col"
                    style={{ height: "100%" }}
                  >
                    <div className="second-dashboard-btn" style={{ height: "100%" }}>
                      <span className="second-dashboard-heading">
                        Count of Houses By Toilet
                      </span>

                      <Pie
                        className="pie-container"
                        data={latrineDoughnutChart}
                        options={{
                          ...latrineOptions,
                          responsive: true,
                          maintainAspectRatio: true,
                        }}
                      />
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="second-dashboard-row-col"
                    style={{ height: "100%" }}
                  >
                    <div className="second-dashboard-btn" style={{ height: "100%" }}>
                      <span className="second-dashboard-heading">
                        Count of Houses By Bathroom
                      </span>

                      <Pie
                        className="pie-container"
                        data={bathroomDoughnutChart}
                        options={{
                          ...bathroomOptions,
                          responsive: true,
                          maintainAspectRatio: true,
                        }}
                      // height={90}
                      // width={140}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="second-dashboard-row" style={{ height: "60%", width: "100%" }}>
              <Col style={{ height: "100%", width: "100%" }}>
                <div className="second-dashboard-btn" style={{ height: "100%", width: "100%" }}>
                  <DashboardMap wardId={this.state.ward_id} property_id={this.props.property_id} />
                </div>
              </Col>
            </Row>

          </Col>
          <Col lg={3} md={3} sm={3} xs={3} className="second-dashboard-col" style={{ height: "100%", width: "20%" }}>
          <div className="second-dashboard-btn" style={{ height: "5%" }}>
              {/* <span className="second-dashboard-heading">Ward Details </span> */}
              <span className="second-dashboard-heading-key">
                President :
                <span className="second-dashboard-heading-value">
                  {presi_name}
                </span>
              </span>
            </div>
            <div className="second-dashboard-btn" style={{ height: "5%" }}>
              {/* <span className="second-dashboard-heading">Ward Details </span> */}
              <span className="second-dashboard-heading-key">
                Vice President :
                <span className="second-dashboard-heading-value">
                  {viceP_name}
                </span>
              </span>
            </div>
            <div className="second-dashboard-btn" style={{ height: "5%" }}>
              {/* <span className="second-dashboard-heading">Ward Details </span> */}
              <span className="second-dashboard-heading-key">
                Ward Name :
                <span className="second-dashboard-heading-value">
                  {ward_name}
                </span>
              </span>
            </div>
            <div className="second-dashboard-btn" style={{ height: "5%" }}>
              <span className="second-dashboard-heading-key">
                Member :
                <span className="second-dashboard-heading-value">
                  {member_name}
                </span>
              </span>
            </div>
            <div className="second-dashboard-btn" style={{ height: "53%" }}>
              <span className="second-dashboard-heading">Area Difference</span>
              <Paper sx={{ width: "100%", overflow: "hidden" }} >
                <TableContainer
                  sx={{ maxHeight: 230 }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {areaTableColumns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, whiteSpace: "nowrap" }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {areaTableArray
                        ?.slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                        )
                        .map((value) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                            // key={row.code}
                            >
                              {areaTableColumns.map((column) => {
                                const values = value[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    style={{ whiteSpace: "nowrap" }}
                                  // align={column.align}
                                  >
                                    {values}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={areaTableArray?.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </Paper>
            </div>
            <div className="second-dashboard-doorStatusBarChart" style={{ height: "35%" }}>
              <span className="second-dashboard-heading">
                Count of Buildings by Door Status
              </span>
              <Bar
                data={bldgCateGroupBarChart}
                options={bldgCateGroupOptions}
              />
            </div>

          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log("state", state);

  return {
    wardList: state.dashboard.wardList,
    secondDashboardData: state.dashboard.secondDashboardData,
    property_id: state.dashboard.property_ids,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSecondDashboardData: (id) => dispatch(getSecondDashboardData(id)),
    getWardList: () => dispatch(getWardList()),
    getPropertyID: (data) => dispatch(getPropertyID(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondDashboard);
