import React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import axios from 'axios';
import { refreshAccessToken } from '../service/refreshaccesstoken';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
        minHeight: 40,
    },
}));

export default function ProminentAppBar() {
    const refreshtoken = localStorage.getItem("refresh_token");

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ backgroundColor: '#ca2208' }}>
                <StyledToolbar>
                    <Typography
                        variant="h5"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 2, alignSelf: 'flex-end', color: 'white', position: 'absolute', bottom: '30%', left: '35%', fontFamily: 'Night Pumpkind ' }}
                    >
                        IPMS Verification Data Downloader
                    </Typography>
                    <IconButton size="large" aria-label="search" color="inherit">
                        {/* <SearchIcon /> */}
                    </IconButton>
                    <IconButton
                        size="large"
                        aria-label="display more actions"
                        edge="end"
                        color="inherit"
                        style={{ position: 'absolute', top: 60, right: 26 }}
                    >
                    </IconButton>
                </StyledToolbar>
            </AppBar>
        </Box>
    );
}