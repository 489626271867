import React, { Component } from "react";
import { Grid, Col, Panel, Row, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
// import analysisimage from '../../../asset/png/data.png'
import "./Analysis.css";
import { connect } from "react-redux";
import { clearDownLoadInitAction, downloadReport, fetchColumnsByLayer, fetchDataByFilter, getLayers, requestSearchedLayer } from "../../map_viewer/map_header/advanced_filter/data/action";
import LocalStorageHandler from "../../../util/storage";
import DropdownService from "../../../service/DropdownService";
import DashboardLayout from "../DashboardLayout";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import RoadMap from "./RoadMap";
// import { Prev } from "react-bootstrap/lib/Pagination";
import ResultModel from "../../map_viewer/map_header/advanced_filter/components/ResultModel";
import { fetchDataByValue, toggleAdvancedFilterWindow, updateSearchResultLayer } from "../../map_viewer/map_header/navigation/navbar_form/data/action";
import { loadingActions } from "../../loader/data/action";
import { snackbarActions } from "../../snackbar/data/action";
import { generateReport } from "../data/action";
// import MapSearchService from "../../../service/MapSearchService";
// import { push } from "connected-react-router";


class RoadAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roadlist: {},
      localbody_id: LocalStorageHandler.getLocalBody().local_body_id,
      showAtrbt: false,
      reportStatus: false,
      filterFormCount: [],
      result: [],
      index: 0,
      localBody_name: LocalStorageHandler.getLocalBody().local_body_name,
      data: {
        road_name: "",
        ward: "",
        length: "",
        carriage_width: "",
        road_category: "",
        road_material: "",
        maintained_by: "",
      },
      showReport: false,
      attribute: "",
      columnlist: [],
      layer: null,
      parameters: [],
      temp: [],
      page: "1",
      limit: "10",
      modelList: [],
      fileName: "",
      recordCount: 0,
      parentModel: "",
      layerToBeSearched: {},
      attributeValue: null,
      operationFieldValue: "",
      valueField: "",
      downloadConfirmation: false,
      readyToDownloadFlag: false,
      downloadPage: 1,
      downloadLimit: 100,
      isDownloading: false,
      percentage: 0,
      obj:[],
      columnlist:[],
      featureData:null,
    }
    this.typeaheadRef = React.createRef()
  }


  componentDidMount = () => {
    this.props.getLayers();
    
  }

  componentDidUpdate = (prevProps,prevState) => {

    if (this.props.layers && !this.state.layersAvailable) {
      this.setState({ layersAvailable: true }, () => {
        this.dropdownLayer();
      });
    }
    if (prevProps.response_id != this.props.response_id) {
    if (
      this.props.response_id &&
      this.props.response_id.features &&
      this.props.response_id.features.length > 0
    ) {
      this.setState({
        showReport: !this.state.showReport
      })
    }
  }

  if(prevState.data != this.state.data)
  {
    this.handleExtendlayer()
  }
  
  if (prevState.data.ward !== this.state.data.ward) {
    this.roadNameFetch()

  }
  }

  dropdownLayer = () => {

    const { layers } = this.props;
    const { state } = this.props.location;
   

    const selectedLayer = layers.find((layer) => layer.label === state.model);
    this.setState({
      ...this.state,
      layer: selectedLayer
    })


    if (selectedLayer) {
     
      const sortedFilter = selectedLayer.advancefilterinfo_sorted.Road
      const app_label = selectedLayer.app_label

      sortedFilter.find((items) => {
   
        if (items.field === "road_name" && this.state.roadlist.road_name != null) {
          let roadNameData = {
            model: items.model_name,
            attribute: items.field,
            app_label: app_label,
            filters: {
              localbody: this.state.localbody_id,
            }
          }
      
          DropdownService.analysisDropdown(roadNameData).then((res) => {
            this.setState({
              roadlist: {
                ...this.state.roadlist,
                road_name: res.data.response_data
              }
            })
          })
      }

      })
    }
  }

  handleDropdown=(name)=>{
    const { layers } = this.props
    const { state } = this.props.location;

    const selectedLayer = layers.find((layer) => layer.label === state.model);
    this.setState({
      ...this.state,
      layer: selectedLayer
    })


    if (selectedLayer){
      const sortedFilter = selectedLayer.advancefilterinfo_sorted.Road
      const app_label = selectedLayer.app_label
      sortedFilter.find((items) => {
        if (items.field === name ) {
        
        let data = {
          model: items.model_name,
          attribute: items.field,
          app_label: app_label,
          filters: {
            localbody: this.state.localbody_id,
          }
        }
        
        DropdownService.analysisDropdown(data).then((res) => {
          
          this.setState({
            roadlist: {
              ...this.state.roadlist,
              [name]: res.data.response_data
            }
          })
        })
        }
        })
      
    }

  }

  roadNameFetch = () => {
    const {layer} =this.state

   let filter;
   if(layer)
   {
 
    if(this.state.data.ward === "" || this.state.data.ward === "None")
    {
      filter = {
            localbody: this.state.localbody_id,
      }
    }else{
      filter = {
            localbody: this.state.localbody_id,
            ward: this.state.data.ward 
          }
    }
      const sortedFilter = layer.advancefilterinfo_sorted.Road
     
      const app_label = layer.app_label

      sortedFilter.find((items) => {
        if (items.field === "road_name") {
          let roadNameData = {
            model: items.model_name,
            attribute: items.field, 
            app_label: app_label,
            filters: filter
          }
          DropdownService.analysisDropdown(roadNameData).then((res) => {
            this.setState({
              roadlist: {
                ...this.state.roadlist,
                road_name: res.data.response_data
              }
            })
          })
        }
      })
   } 
  }
 
  handleInputChange = (e) => {
    const {name,value} = e.target;
    this.handleDropdown(name)
    
    this.setState(
      {
        data: { ...this.state.data, [name]: value },
        attribute: name,
      }
    );
    // console.log("input name",name,"inputvalue",value);
    
    // this.props.fetchDataByValue(name,value)
    
  };

  handleSearchInputChange = (selectedOption, attribute) => {
   
    this.setState({
      data: {
        ...this.state.data,
        road_name: selectedOption,
      },
      
      attribute: attribute
    });
    // console.log("attribute",attribute);
    // this.props.fetchDataByValue(attribute,selectedOption)
  };

  handleExtendlayer =()=>{
   const {layers} = this.props
   const { state } = this.props.location;


    const selectedLayer = layers.find((layer) => layer.label === state.model);
    const dataValue = this.state.data
    
    let filters = null;
  
 Object.keys(dataValue).map((value)=>{
  if(dataValue[value] != undefined && dataValue[value] != '' && value != "road_category" && value != "road_material" && value != "maintained_by")
  {
  filters = {...filters,[value] : dataValue[value]}
}
 })

  if(filters != null)
  {
  const data =  {
      layer_name: selectedLayer.layer_name,
      filters:filters
  }

  DropdownService.layerExtendFilter(data).then((res)=>{
    
    const data = res.data.detail.data
    this.setState({
      ...this.state,
      featureData: data
    })
  })
}
  }

  ClearFilter = () => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        road_name: "",
        ward: "",
        length: "",
        carriage_width: "",
        road_category: "",
        road_material: "",
        maintained_by: "",
      },
      columnlist:[],
      obj:[],
      featureData:null,
    }))
    if (this.typeaheadRef.current) {
      this.typeaheadRef.current.getInstance().clear();
    }
  }



showReports = () => {
  const { state } = this.props.location;
  const { data, layer, columnlist, obj } = this.state;

  if (layer) {
    const advancedFilter = layer.advancefilterinfo;

    let filters = [];
    let columns = [];
    let join_op;

    this.state.obj = [];
this.state.columnlist = [];

Object.keys(data).forEach((key) => {
      if (data[key] !== "" && data[key] !== "None") {
        const attribute = key;
        const value = data[key];


        advancedFilter.forEach((item) => {
          if (attribute === item.field) {
            this.state.columnlist.push({
              field: item.field,
              model_name: item.model_name,
              display_name: item.display_name,
            });

           this.state.columnlist.forEach((item,index)=> join_op= index === 0 ? null : "and")

           const obj ={
            attribute,
            op: "equal",
            value,
            model_name: item.model_name,
            parent_model: item.parent_model,
            join_op,
          }
            this.state.obj.push(obj);  
          }
        });
      }
    });  


    let filter = {
      page: this.state.page,
      limit: this.state.limit,
      report: true,
      parent_model: state.model,
      obj:this.state.obj,
      category_field: null,
      category_id: null,
      file_name: "",
      layer_name: state.model,
      module: "advanceresult",
      file_extension: "",
      columns:this.state.columnlist,
      models: [state.model],
    };
this.props.fetchDataByFilter(filter)
  }
};




  handleShowReport = () => {
    this.setState({
      showReport: !this.state.showReport,
    });
  };

  handlepaginationClick = (page) => {
    this.setState(
      {
        page: page,
        percentage: 0,
      },
    );
  };

  handleFileChange = (el, field) => {
    this.setState({

      ...this.state,
      fileType: el.target.value,

    });
  };

  handleFileFormatChange = (el, field) => {
    this.setState({

      ...this.state,
      fileFormat: el.target.value,

    });
  };

  downloadFile = () => {
    this.setState({ isDownloading: false });
    let urls =
      this.props.reportUrls &&
      this.props.reportUrls[0] &&
      this.props.reportUrls[0].urls;

    if (!urls) return;
    if (this.state.fileFormat === "csv") {
      const { building, member, merged } = urls.csv;
      if (this.state.data.layerName === "Residential") {
        this.state.data.fileType === "building" && window.open(building);
        this.state.data.fileType === "member" && member && window.open(member);
        this.state.data.fileType === "merged" && merged && window.open(merged);
      } else {
        building && window.open(building);
      }
    }
    if (this.state.fileFormat === "excel") {
      const { building, member, merged } = urls.excel;
      if (this.state.data.layerName === "Residential") {
        this.state.data.fileType === "building" && window.open(building);
        this.state.data.fileType === "member" && member && window.open(member);
        this.state.data.fileType === "merged" && merged && window.open(merged);
      } else {
        building && window.open(building);
      }
    }
  };

  generateReport = () => {
    const { state } = this.props.location;
    const { layer, data } = this.state

    let attribute, value;

    this.state.recordCount > 1000 &&
      this.setState({ downloadConfirmation: true });
    this.setState({ downloadPercentage: 0, isDownloading: true });

    Object.keys(data).map((key) => {
      if (data[key] !== "") {
        attribute = key;
        value = data[key];
      }
    });
    
    let reportValues = {
        page: 1,
        limit: this.state.downloadLimit,
        report: "True",
        file_name: this.state.fileName,
        file_extension: this.state.fileFormat,
        models: [
            state.model
        ],
        columns: this.state.columnlist,
        layer_name: state.model,
        parent_model: state.model,
        obj: this.state.obj,
        category_field: null,
        category_id: null,
        unique_id: this.props.unique_id,
        recordCount: this.state.recordCount,
        maxPage: this.state.recordCount / this.state.downloadLimit,
        file_type: null
    
    };
    this.props.downloadReport(reportValues);
    this.setState({ downloadPage: 1 });
    // window.open(this.props.reportLink);
  };

  render() {
    const { roadlist } = this.state

    const {
      ward,
      road_name,
      length,
      carriage_width,
      road_category,
      road_material,
      maintained_by,
    } = this.state.data;


    const isButtonEnabled =
      ward ||
      road_name ||
      length ||
      carriage_width ||
      road_category ||
      road_material ||
      maintained_by;
    return (
      <DashboardLayout handleRedirect={this.handleRedirect}>

        <Grid style={{ width: "100%", display: 'flex', flexDirection: 'column', gap: '4px',overflow:"hidden" }}>
        {this.state.showReport && (
          <ResultModel
            show={this.state.showReport}
            handleShowReport={this.handleShowReport}
            result={this.props.response_table}
            layer={this.state.layer}
            recordCount={this.props.recordCount}
            handlepaginationClick={(page) => this.handlepaginationClick(page)}
            data={this.state.data}
            response_member={this.props.response_member}
            handleFileChange={this.handleFileChange}
            handleFileFormatChange={this.handleFileFormatChange}
            downloadFile={() => this.downloadFile}
            generateReport={() => this.generateReport}
            reportFile={this.props.reportFile}
            advanceLoading={this.props.advanceLoading}
            percentage={this.state.percentage}
            downloadPercentage={this.state.downloadPercentage}
            downloadLoading={this.props.downloadLoading}
          />
        )}
          <Row className="show-grid analysis-row">
            <h1 className="analysis-heading">Smart {this.state.localBody_name} - Road Details</h1>
          </Row>
          {/* <Row className="show-grid top-analysis-div">
            <Col xs={6} md={3} lg={3} >
              <FormGroup controlId="formControlsSelect">
                <ControlLabel>Ward Name</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  className="form-controls"
                  name="ward"
                  value={this.state.data.ward}
                  onChange={(e) => this.handleInputChange(e)}
                >
                  <option value="">Select Ward</option>
                  {roadlist.ward &&
                    roadlist.ward.map((ward, i) => (
                      <option value={ward.id} key={i}>
                        {ward.attribute_data}
                      </option>
                    ))}
                </FormControl>
              </FormGroup>
            </Col>

            <Col style={{
              display: 'flex',
              flexDirection: 'column',
              width: '20rem',
              gap: '1rem'
            }}>
              <span>Road Name</span>
              <AsyncTypeahead
  id="searchinput"
  minLength={0}
  options={
    roadlist.road_name && Array.isArray(roadlist.road_name)
      ? roadlist.road_name.map(item => item.attribute_data || []) // Check for null or undefined
      : []
  }
  value={this.state.data.road_name}
  inputProps={{ name: "road_name" }}
  onChange={(selectedOptions) =>
    this.handleSearchInputChange(selectedOptions[0], "road_name")
  }
  placeholder="SELECT"
  ref={this.typeaheadRef}
/>
            </Col>

        
            <Col xs={4} md={3} lg={4} style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1.3rem'
            }}>
              <button style={{
                height: '60%',
                width: '50%',
                background: 'rgb(241 96 74)',
                border: 'none',
                color: 'white',
                fontSize: '1.5rem'
              }}
                onClick={this.ClearFilter}
              >
                Clear
              </button>

            </Col>
          </Row> */}
          <Row className="analysis-second-div">
            <Col className="left-row">
              <div className="analysis-dropdown-head">
                <h3 className="left-row-head">Road Type Filter </h3>
              </div>
              <FormGroup controlId="formControlsSelect">
                <ControlLabel>Road Category</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  className="form-controls"
                  name="road_category"
                  value={this.state.data.road_category}
                  onChange={(e) => this.handleInputChange(e)}
                  onClick={(e)=>this.handleDropdown(e.target.name)}
                >
                  <option value="">Category</option>
                  {roadlist.road_category ?
                   (roadlist.road_category.map((category, i) => (
                      <option value={category.attribute_data} key={i}>
                        {category.attribute_data}
                      </option>
                    ))):(<option>loading...</option>)}
                </FormControl>
              </FormGroup>
              <FormGroup controlId="formControlsSelect">
                <ControlLabel>Road Material</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  className="form-controls"
                  name="road_material"
                  value={this.state.data.road_material}
                  onChange={(e) => this.handleInputChange(e)}
                  onClick={(e)=>this.handleDropdown(e.target.name)}
                >
                  <option value="">Material</option>
                  {roadlist.road_material ?
                    (roadlist.road_material.map((metrial, i) => (
                      <option value={metrial.attribute_data}>
                        {metrial.attribute_data}
                      </option>
                    ))):(<option>Loading...</option>)}
                </FormControl>
              </FormGroup>
            </Col>
            <Col className="center-row" style={{ width: "70%" }}>
              <Row className="center-dropdowns-row">
              <Col xs={6} md={3} lg={3} >
              <FormGroup controlId="formControlsSelect">
                <ControlLabel>Ward Name</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  className="form-controls"
                  name="ward"
                  value={this.state.data.ward}
                  onChange={(e) => this.handleInputChange(e)}
                  onClick={(e)=>this.handleDropdown(e.target.name)}
                >
                  <option value="">Select Ward</option>
                  {roadlist.ward ?
                    (roadlist.ward.map((ward, i) => (
                      <option value={ward.id} key={i}>
                        {ward.attribute_data}
                      </option>
                    ))):(<option>loading...</option>)}
                </FormControl>
              </FormGroup>
            </Col>

            <Col xs={6} md={3} lg={3}>
              <span>Road Name</span>
              <AsyncTypeahead
  id="searchinput"
  minLength={0}
  options={
    roadlist.road_name && Array.isArray(roadlist.road_name)
      ? roadlist.road_name.map(item => item.attribute_data || []) // Check for null or undefined
      : []
  }
  value={this.state.data.road_name}
  inputProps={{ name: "road_name" }}
  onChange={(selectedOptions) =>
    this.handleSearchInputChange(selectedOptions[0], "road_name")
  }
  // onClick={(e)=>this.handleDropdown(e.target.name)}

  placeholder="SELECT"
  ref={this.typeaheadRef}
/>
            </Col>

        
            <Col xs={4} md={3} lg={4} style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1.3rem'
            }}>
              <button style={{
                height: '60%',
                width: '50%',
                background: 'rgb(241 96 74)',
                border: 'none',
                color: 'white',
                fontSize: '1.5rem'
              }}
                onClick={this.ClearFilter}
              >
                Clear
              </button>

            </Col>
              </Row>
              <Row className="center-map-row">
              <RoadMap
                layerData={this.state.data}
                featureData={this.state.featureData}
                dropdownLayer={this.dropdownLayer}
              />
              </Row>
            </Col>

            <Col className="right-row">
              <div className="analysis-dropdown-head">
                <h3 className="left-row-head">Road Details </h3>
              </div>
              <FormGroup controlId="formControlsSelect">
                <ControlLabel>Road Maintained By</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  className="form-controls"
                  name="maintained_by"
                  value={this.state.data.maintained_by}
                  onChange={(e) => this.handleInputChange(e)}
                  onClick={(e)=>this.handleDropdown(e.target.name)}
                >
                  <option value="">Maintained By</option>
                  {roadlist.maintained_by ?
                   ( roadlist.maintained_by.map((maintained, i) => (
                      <option value={maintained.id} key={i}>
                        {maintained.attribute_data}
                      </option>
                    ))):(<option>Loading...</option>)}
                </FormControl>

              </FormGroup>
            </Col>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'end' }}>
            <button className="show-report-btn"
              disabled={!isButtonEnabled}
              onClick={this.showReports}
            >Show Report</button>
          </Row>
        </Grid>
      </DashboardLayout>
    );
  }
}


function mapStateToProps(state) {
 
  return {
    layers: state.advancedFilter.layers,
    datas: state.advancedFilter.data,
    show: state.mapSearch.showAdvancedFilterWindow,
    mapComponent: state.mapReducer.OlMap,
    resultLayer: state.mapSearch.resultLayer,
    attributes: state.advancedFilter.attributes,
    response_id: state.advancedFilter.response_id,
    fetching: state.advancedFilter.fetching,
    data: state.advancedFilter.data,
    recordCount: state.advancedFilter.recordCount,
    responseData: state.advancedFilter.responseData,
    response_table: state.advancedFilter.response_table,
    response_member: state.advancedFilter.response_member,
    downlaodPage: state.advancedFilter.page,
    unique_id: state.advancedFilter.unique_id,

    reportUrls: state.advancedFilter.reportUrls,
    reportFile: state.advancedFilter.reportFile,
    downloadLoading: state.advancedFilter.downloadLoading,
    advanceLoading: state.advancedFilter.advanceLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLayers: () => dispatch(getLayers()),
    hide: () => dispatch(toggleAdvancedFilterWindow()),
    fetchDataByFilter: (filterValues) =>
      dispatch(fetchDataByFilter(filterValues)),
    fetchColumnsByLayer: (layer) => dispatch(fetchColumnsByLayer(layer)),
    updateSearchResultLayer: (searchLayer) =>
      dispatch(updateSearchResultLayer(searchLayer)),
    isLoading: () => dispatch(loadingActions.isloading()),
    loadingComplete: () => dispatch(loadingActions.loadingComplete()),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
    getColumns: () => dispatch(getColumns()),

    requestSearchedLayer: (layerToSearch, featureRequest) =>
      dispatch(requestSearchedLayer(layerToSearch, featureRequest)),
    generateReport: (reportValues) => dispatch(generateReport(reportValues)),
    clearDownLoadInitAction: () => dispatch(clearDownLoadInitAction()),

    downloadReport: (reportValues) => dispatch(downloadReport(reportValues)),
    fetchDataByValue: (searchBy, query) =>
    dispatch(fetchDataByValue(searchBy, query)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RoadAnalysis);
