import React, { Component } from "react";
import { transform } from "ol/proj.js";
import { connect } from "react-redux";
import "./CurrentLocation.css";
import Feature from "ol/Feature.js";
import Point from "ol/geom/Point.js";
import { Vector as VectorLayer } from "ol/layer.js";
import VectorSource from "ol/source/Vector.js";
import { Icon, Style } from "ol/style.js";
import { Stroke } from "ol/style.js";
import currentLocation from "../../../../../asset/png/currentLocation.png";
import pin from "../../../../../asset/png/pin.png";
import Widget from "../../../../../containers/widget/widget";

class DefaultExtend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vectersource: null,
    };
  }
  componentDidMount() {
    var iconStyle = new Style({
      image: new Icon(
        /** @type {module:ol/style/Icon~Options} */ ({
          anchor: [0.5, 46],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          src: "https://openlayers.org/en/v4.6.4/examples/data/icon.png",
        })
      ),
    });
    this.setState({
      vectersource: new VectorLayer({
        style: iconStyle,
        source: new VectorSource(),
      }),
    });
  }
  findPosition = () => {
    console.log("naviagteor",navigator);
    if (navigator.geolocation) {
      
      navigator.geolocation.getCurrentPosition(this.setToCurrentPosition);
    }
  };
  setToCurrentPosition = (position) => {
    const { mapComponent } = this.props;
    mapComponent.getLayers().forEach((layer) => {
      if (layer.get("name") && layer.get("name") == "location") {
        mapComponent.removeLayer(layer);
      }
    });
    var iconFeature = new Feature({
      geometry: new Point(
        transform(
          [position.coords.longitude, position.coords.latitude],
          "EPSG:4326",
          "EPSG:3857"
        )
      ),
    });
    var iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: pin,
        scale: 0.5,
      }),
    });
    iconFeature.setStyle(iconStyle);
    var vectorSource = new VectorSource({
      features: [iconFeature],
    });
    var vectorLayer = new VectorLayer({
      name: "location",
      source: vectorSource,
      wfsstyle: new Style({
        stroke: new Stroke({
          color: "rgba(0, 0, 255, 1.0)",
          width: 2,
        }),
      }),
    });
    mapComponent.addLayer(vectorLayer);
    mapComponent
      .getView()
      .setCenter(
        transform(
          [position.coords.longitude, position.coords.latitude],
          "EPSG:4326",
          "EPSG:3857"
        )
      );
    mapComponent.getView().setZoom(13);
  };
  render() {
    return (
      <Widget
        placement="right"
        tooltipText="Current Location"
        handleClick={this.findPosition}
        img={currentLocation}
        class="currentLocationButton"
        imageStyle={{ width: "32px", height: "32px" }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultExtend);
