import React, { Component } from "react";
import {
  Collapse,
  Row,
  Col,
  Panel,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Carousel,
  Modal,
  Badge,
  Label,
  ProgressBar,
} from "react-bootstrap";
import "./DetailedInfo.css";
import LocalStorageHandler from "../../../../../util/storage";
import closeButton from "../../../../../asset/svg/close_icon.svg";
import "jspdf-autotable";
import MemberDetails from "./MemberDetails";
import RelatedTables from "./RelatedTables";
import PropertyArea from "./PropertyArea";
import FloorType from "./FloorType";
import RoofType from "./RoofType";
import { ADMIN_URL } from "../../../../../../src/config";
import PdfReport from "./PdfRepoert";
import DataFormat from "../../helper/DataFormat";
import TableFormat from "./TableFormat";
import { push } from "connected-react-router";
import DetailInfoTable from "./DetailInfoTable";
// import { push } from "connected-react-router";
// import DetailInfoTable from "./DetailInfoTable";

class DetailedInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageName: null,
      show: false,
      showpdf: false,
      img: null,
      key: null,
      pdf_mgmt: false,
      pdf_doc: false,
      pdffile: null,
      total_count: 0,
      pdf_doc_file: null,
      pdf_mgmt_file: null,
      image_status: true,
      image_class: "img-responsive center-block",
      memberDetailStatus: false,
      basicDetailStatus: false,
    };
    this.displayImage = this.displayImage.bind(this);
    this.displayPdf = this.displayPdf.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.detailedInfoContents !== null &&
      this.props.detailedInfoContents !== prevProps.detailedInfoContents
    ) {
      this.setUpPdfdoc();
      this.setUpPdfMgmt();
    }
  }
  setUpPdfdoc = () => {
    let pdf = null;
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent = this.props.detailedInfoContents[
        this.props.count
      ];

      try {
        if (detailedInfoContent != undefined) {
          Object.keys(detailedInfoContent).map((key, index) => {
            if (key.includes("p_d_f_doc")) {
              this.setState({
                pdf_doc: true,
              });

              if (key == "p_d_f_doc") {
                this.setState({
                  pdf_doc_file: detailedInfoContent[key].value,
                });
              } else {
                this.setState({
                  pdf_doc_file: "filenotfound.pdf",
                });
              }
            }
          });
        }
      } catch (e) {
        console.log("Exception", e);
      }
    }
  };

  setUpPdfMgmt = () => {
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent = this.props.detailedInfoContents[
        this.props.count
      ];
      let pdf = null;
      try {
        if (detailedInfoContent != undefined) {
          Object.keys(detailedInfoContent).map((key) => {
            if (key.includes("p_d_f_mgmt")) {
              this.setState({
                pdf_mgmt: true,
              });
              if (key == "p_d_f_mgmt") {
                this.setState({
                  pdf_mgmt_file: detailedInfoContent[key].value,
                });
              } else {
                this.setState({
                  pdf_mgmt_file: "filenotfound.pdf",
                });
              }
            }
          });
          return pdf;
        }
      } catch (e) {
        console.log("Exception", e);
      }
    }
  };
  displayImage() {
    this.setState({
      show: !this.state.show,
    });
  }
  rotateImage() {
    if (this.state.image_status) {
      this.setState({
        image_class: "img-responsive center-block image-rotate",
        image_status: !this.state.image_status,
      });
    } else {
      this.setState({
        image_class: "img-responsive center-block ",
        image_status: !this.state.image_status,
      });
    }
  }
  displayPdf(param, e) {
    this.setState({
      pdffile: param,
      showpdf: !this.state.showpdf,
    });
  }

  showUpdationStatus = () => {
    const { layerParent, status } = this.props;
    if (
      layerParent.includes("waterbody_pond") ||
      layerParent.includes("buildingasset_property")
    ) {
      return (
        <>
          <tr>
            <td>Updation status</td>
            <td>{DataFormat.updationStatus(status)}</td>
          </tr>
        </>
      );
    }
  };

  formatInfo() {
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent = this.props.detailedInfoContents[
        this.props.count
      ];
      if (detailedInfoContent != undefined)
        return Object.keys(detailedInfoContent).map((key) => {
          if (key === "updation_status") {
            return (
              <>
                <tr key={key}>
                  <td style={{ width: "350px" }}>
                    {detailedInfoContent[key].display_name}
                  </td>
                  <td style={{ width: "350px" }}>
                    {DataFormat.updationStatus(detailedInfoContent[key].value)}
                  </td>
                </tr>
              </>
            );
          }
          if (
            !key.includes("photo") &&
            !key.includes("p_d_f_") &&
            !DataFormat.dataFormatOr(detailedInfoContent[key].value) &&
            !key.includes("noofyears") &&
            !key.includes("year_const") &&
            !key.includes("structural_change") &&
            !key.includes("any_institute") &&
            !key.includes("higher_floor_gt") &&
            !key.includes("normal_ac") &&
            !key.includes("bldg_zone_id") &&
            !key.includes("road_width_id") &&
            !key.includes("road_direction_id")
          ) {
            return (
              <tr key={key}>
                <td style={{ width: "300px" }}>
                  {detailedInfoContent[key].display_name}
                </td>
                <td style={{ width: "300px" }}>
                  {DataFormat.dataFormat(detailedInfoContent[key].value)}
                </td>
              </tr>
            );
          }
        });
    }
  }

  formatInfoForeign() {
    if (this.props.detailedInfoContentsForeignKey != null) {
      const detailedInfoContentForeignKey = this.props
        .detailedInfoContentsForeignKey[this.props.count];
      if (detailedInfoContentForeignKey != undefined)
        return Object.keys(detailedInfoContentForeignKey.foreignKeys).map(
          (key) => {
            if (
              !key.includes("photo") &&
              !key.includes("p_d_f_") &&
              // !DataFormat.dataFormatOr(
              //   detailedInfoContentForeignKey.foreignKeys[key].actual_value
              // ) &&
              !key.includes("noofyears") &&
              !key.includes("year_const") &&
              !key.includes("structural_change") &&
              !key.includes("any_institute") &&
              !key.includes("higher_floor_gt") &&
              !key.includes("normal_ac") &&
              !key.includes("bldg_zone_id") &&
              !key.includes("road_width_id") &&
              !key.includes("road_direction_id")
            ) {
              return (
                <tr key={key}>
                  <td>
                    {detailedInfoContentForeignKey.foreignKeys[key].display_name
                      ? detailedInfoContentForeignKey.foreignKeys[key]
                        .display_name
                      : detailedInfoContentForeignKey.foreignKeys[key].field}
                  </td>
                  <td>
                    {DataFormat.dataFormat(
                      detailedInfoContentForeignKey.foreignKeys[key]
                        .actual_value
                    )}
                  </td>
                </tr>
              );
            }
          }
        );
    }
  }

  renderProgres() {
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent = this.props.detailedInfoContents[
        this.props.count
      ];
      let prog = [];
      var element = {};
      try {
        if (detailedInfoContent != undefined) {
          Object.keys(detailedInfoContent).map((key) => {
            if (key.includes("c_h_p_")) {
              element = {};
              element.name = detailedInfoContent[key].display_name
                ? detailedInfoContent[key].display_name
                : detailedInfoContent[key].field;
              element.per = detailedInfoContent[key].value;
              prog.push(element);
            }
          });
        }
        return prog;
      } catch (e) {
        console.log("Exception", e);
      }
    }
  }

  renderImage = () => {
    let imageName = [];
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent = this.props.detailedInfoContents[
        this.props.count
      ];
      try {
        if (detailedInfoContent != undefined) {
          Object.keys(detailedInfoContent).map((key) => {
            if (key.includes("photo") && detailedInfoContent[key].value != null)
              imageName.push(
                detailedInfoContent[key].value.replace("JPG", "jpg")
              );
          });
        }
        return imageName;
      } catch (e) {
        console.log("Exception", e);
      }
    }
  };

  render() {
    const itemsForeign = this.formatInfoForeign();
    const images = this.renderImage();
    const prog = this.renderProgres();
    const image_class = this.state.image_class;
    const img_url = LocalStorageHandler.getLocalBody().image_url;
    const { selectedLayer } = this.props;
    var layerName = "";
    let firstName = "";
    let lastName = "";
    let house = "";
    let place = "";
    let fullAddress = false;
    let post = "";
    let pin = "";
    let fullNameAdded = false;

    if (selectedLayer) {
      layerName = selectedLayer.getProperties().name;
    }

    var layer_id =
      this.props.detailedInfoContentsForeignKey &&
      this.props.detailedInfoContentsForeignKey[this.props.count].id;

    const detailInfoDetailsForginkeys =
      this.props.detailedInfoContentsForeignKey &&
      this.props.detailedInfoContentsForeignKey[this.props.count].foreignKeys;
    const detailInfoDetails =
      this.props.detailedInfoContents &&
      this.props.detailedInfoContents[this.props.count];

    let related_data =
      this.props.detailedInfoContentsForeignKey &&
      this.props.detailedInfoContentsForeignKey[this.props.count]
        .related_tables;

    const tableName =
      this.props.detailedInfoContentsForeignKey &&
      this.props.detailedInfoContentsForeignKey[0].id;
    let trimmedName = tableName?.split(".")[0];


    let detailinfoTable = {
      Basic_Details: [],
      Owner_Details: [],
      Form_6_Details: [],
      Socio_Economic_Details: [],
      Drinking_Water_and_Waste_Management: [],
      // Member_Details: [],
      Tenant_Details: [],
      Establishment_Details: [],
      AR_Details: [],
      AR_Diff: [],
      Survey_Details: [],
    };

    detailInfoDetails &&
      Object.keys(detailInfoDetails).map((key) => {
        if (
          !key.includes("photo") &&
          !key.includes("p_d_f_") &&
          !key.includes("any_institute") &&
          !key.includes("bldg_zone_id") &&
          !key.includes("road_width_id") &&
          !key.includes("road_direction_id") &&
          !key.includes("other_flr_type")
        ) {
          if (
            detailInfoDetails[key].display_name === "Year of construction" ||
            detailInfoDetails[key].field === "noofyears" ||
            detailInfoDetails[key].field === "structural_change" ||
            detailInfoDetails[key].field === "higher_floor_gt" ||
            detailInfoDetails[key].field === "normal_ac"
          ) {
            if (detailInfoDetails[key].value !== "NA" &&
              detailInfoDetails[key].value !== "None" 
            ) {
              let value = {
                display_name: detailInfoDetails[key].display_name,
                values: detailInfoDetails[key].value,
              };
              if (value.values == -1 || value.values == -1.00 || value.values == -1.0000) {
                value.values = "NR";
              }
              detailinfoTable.Form_6_Details.push(value);
            }
          }
          if (
            detailInfoDetails[key].display_name === "New property id" ||
            detailInfoDetails[key].display_name === "Old property id" ||
            detailInfoDetails[key].display_name === "Place name" ||
            detailInfoDetails[key].display_name === "Street" ||
            detailInfoDetails[key].display_name === "Village" ||
            detailInfoDetails[key].display_name === "Near road" ||
            detailInfoDetails[key].display_name === "Updation status" ||
            detailInfoDetails[key].display_name === "Building status" ||
            detailInfoDetails[key].display_name === "Building usage" ||
            detailInfoDetails[key].display_name === "Post office"
          ) {
            if (detailInfoDetails[key].value !== "NA" &&
              detailInfoDetails[key].value !== "None" ) {
            let value = {
              display_name: detailInfoDetails[key].display_name,
              values:
                detailInfoDetails[key].display_name === "Updation status"
                  ? DataFormat.updationStatus(detailInfoDetails[key].value)
                  : detailInfoDetails[key].value,
            };
            detailinfoTable.Basic_Details.push(value);
          }
        }
          if (detailInfoDetails[key].display_name === "Updation status") {
            if (detailInfoDetails[key].value !== "NA" &&
              detailInfoDetails[key].value !== "None" ) {
              let value = {
              display_name: detailInfoDetails[key].display_name,
              // values: detailInfoDetails[key].value,
              values: DataFormat.updationStatus(detailInfoDetails[key].value),
            };
            if (value.values == -1 || value.values == -1.00 || value.values == -1.0000) {
          value.values = "NR";
        }
            detailinfoTable.Survey_Details.push(value);
          }}
        }
      });

    detailInfoDetailsForginkeys &&
      Object.keys(detailInfoDetailsForginkeys).map((key) => {
        if (
          !key.includes("photo") &&
          !key.includes("p_d_f_") &&
          !key.includes("noofyears") &&
          // !key.includes("year_const") &&
          !key.includes("structural_change") &&
          !key.includes("any_institute") &&
          !key.includes("higher_floor_gt") &&
          !key.includes("normal_ac") &&
          // !key.includes("bldg_zone_id") &&
          // !key.includes("road_width_id") &&
          !key.includes("road_direction_id") &&
          !key.includes("building_id")
        ) {
          if (
            detailInfoDetailsForginkeys[key].display_name ===
            "Building sub category" ||
            detailInfoDetailsForginkeys[key].display_name === "Road width" ||
            detailInfoDetailsForginkeys[key].display_name === "Zone"
          ) {
            if (detailInfoDetailsForginkeys[key].actual_value !== "NA" &&
              detailInfoDetailsForginkeys[key].actual_value !== "None" ) {
            let value = {
              display_name:
                detailInfoDetailsForginkeys[key].display_name ===
                  "Building sub category"
                  ? "Building Type"
                  : detailInfoDetailsForginkeys[key].display_name,
              values: detailInfoDetailsForginkeys[key].actual_value,
            };
            if (value.values == -1 || value.values == -1.00 || value.values == -1.0000) {
              value.values = "NR";
            }
            detailinfoTable.Form_6_Details.push(value);
          }
          }
          if (
            detailInfoDetailsForginkeys[key].display_name ===
            "New property id" ||
            detailInfoDetailsForginkeys[key].display_name ===
            "Old property id" ||
            detailInfoDetailsForginkeys[key].display_name === "Place name" ||
            detailInfoDetailsForginkeys[key].display_name === "Street" ||
            detailInfoDetailsForginkeys[key].display_name === "Village" ||
            detailInfoDetailsForginkeys[key].display_name === "Near road" ||
            detailInfoDetailsForginkeys[key].display_name ===
            "Updation status" ||
            detailInfoDetailsForginkeys[key].display_name ===
            "Building status" ||
            detailInfoDetailsForginkeys[key].display_name ===
            "Building usage" ||
            detailInfoDetailsForginkeys[key].display_name ===
            "Building under" ||
            detailInfoDetailsForginkeys[key].display_name === "Post office" ||
            detailInfoDetailsForginkeys[key].display_name === "Road type" ||
            // detailInfoDetailsForginkeys[key].display_name === "Road width" ||
            detailInfoDetailsForginkeys[key].display_name === "Ward number" ||
            detailInfoDetailsForginkeys[key].display_name ===
            "Building condition" ||
            detailInfoDetailsForginkeys[key].display_name === "Door status" ||
            detailInfoDetailsForginkeys[key].display_name ===
            "Building sub category"
          ) {
            if (detailInfoDetailsForginkeys[key].actual_value !== "NA" &&
              detailInfoDetailsForginkeys[key].actual_value !== "None") {
            let value = {
              display_name: detailInfoDetailsForginkeys[key].display_name,
              values: detailInfoDetailsForginkeys[key].actual_value,
            };
            if (value.values == -1 || value.values == -1.00 || value.values == -1.0000) {
              value.values = "NR";
            }
            
            detailinfoTable.Basic_Details.push(value);
          }
          }
          // if (
          //   detailInfoDetailsForginkeys[key].display_name === "Building usage"
          // ) {
          //   let value = {
          //     display_name: detailInfoDetailsForginkeys[key].display_name,
          //     values: detailInfoDetailsForginkeys[key].actual_value,
          //   };
          //   detailinfoTable.AR_Details.push(value);
          // }
        }
      });

    let relatedValues = [];

    related_data &&
      related_data.map((values) => {
        if (
          values.related_table != "members" &&
          values.related_table != "member_diseases" &&
          values.related_table != "floor_prop_area"
        ) {
          let relatedvalues = { related_table: values.related_table };
          if (values.fields.length > 0) {
            // let fieldValues = values.fields
            values.fields.map((values) => {
              Object.entries(values).map(([key, value]) => {
                // let values = { display_name: key, values: value };
                relatedvalues = {
                  ...relatedvalues,
                  display_name: key,
                  values: value,
                };
                // relatedValues.push(values);
                relatedValues.push(relatedvalues);
              });
            });
          }
        }
        if (values.related_table === "floor_prop_area") {
          if (values.fields.length > 0) {
            values.fields.map((values, i) => {
              let floorValues = {
                title: values["Floor Category"],
                display_name: "Floor Area",
                values: values["Floor Area"],
              };
              relatedValues.push(floorValues);
            });
          }
        }
        if (values.related_table === "members") {
          if (values.fields.length > 0) {
            let membersValues = values.fields;
            let value = {};
            Object.keys(membersValues).map((key) => {
              value = { title: key === "First Name" && membersValues[key] };
            });
          }
        }
      });

    relatedValues.filter((values) => {
      const displayName = values.display_name;
      if ((
          (displayName === "First Name" && values.related_table === "owners") ||
          // (displayName === "Last Name" && values.related_table === "owners") ||
          (displayName === "Owner Occupation" &&
            values.related_table === "owners") ||
          // (displayName === "Owner pin" && values.related_table === "owners") ||
          // (displayName === "Owner state" &&
          //   values.related_table === "owners") ||
          displayName === "Owner House" ||
          (displayName === "mobile" && values.related_table === "owners") ||
          (displayName === "landphone" && values.related_table === "owners") ||
          (displayName === "email" && values.related_table === "owners") ||
          (displayName === "street" && values.related_table === "owners") ||
          (displayName === "village" && values.related_table === "owners") ||
          (displayName === "Owner Post Office" &&
            values.related_table === "owners") ||
          (displayName === "place name" && values.related_table === "owners") ||
          displayName === "Owner Survey Number" ||
          displayName === "Other Building Newproid" ||
          displayName === "Other Building Type" ||
          displayName === "Other Buildings" ||
          displayName === "Other Land" ||
          displayName === "Other Land Count" ||
          displayName === "Other Land Details" ||
          // displayName === "Scheme" ||
          displayName === "Type of Farming" ||
          displayName === "Owner Name in Sanjaya" ||
          (displayName === "Other Buildings" &&
            values.related_table === "property_other_details")) &&

        values.values !== "NA" &&
        values.values != -2.00 &&
        values.values != -2.0000 &&
        values.values != -2 &&
        values.values !== "NA@gmail.com" &&
        values.values !== "None" &&
        values.values !== "NIQ" &&
        values.values !== null
      ) {
        if (values.values === -1 || values.values === "-1") {
          values.values = "NR";
        }
        detailinfoTable.Owner_Details.push(values);
      }
      if (
        (displayName === "Floor Type" ||
          displayName === "Roof Category" ||
          displayName === "Roof Percentage" ||
          displayName === "Structural Change Year" ||
          displayName === "Roof Change" ||
          displayName === "Roof Change Year" ||
          displayName === "Wall type" ||
          displayName === "Room count" ||
          (displayName === "Aadhaar Number" && values.related_table === "owners") ||
          (displayName === "Survey No" && values.related_table === "property_other_details") ||
          (displayName === "Swimming Pool" && values.related_table === "property_other_details") ||
          (displayName === "Parking area" && values.related_table === "property_other_details") ||
          (displayName === "Tax Amount" && values.related_table === "tax_details") ||
          (displayName === "Tax Bill Number" && values.related_table === "tax_details") ||
          (displayName === "Tax paid date" && values.related_table === "tax_details") ||
          (displayName === "Tax paid year" && values.related_table === "tax_details") ||
          (displayName === "tax annual" && values.related_table === "tax_details") ||
          displayName === "Floor Count" ||
          displayName === "Floor Area" ||
          (displayName === "Total Floor Area" &&
            values.related_table === "property_other_details") ||
          displayName === "Swimming Pool Area") &&
        values.values !== "NA" &&
        values.values != -2.00 &&
        values.values != -2.0000 &&
        values.values != -2 &&

        values.values !== "NA@gmail.com" &&
        values.values !== "None" &&
        values.values !== "NIQ" &&
        values.values !== null
      ) {
        if (values.values === -1 || values.values === "-1") {
          values.values = "NR";
        }
        detailinfoTable.Form_6_Details.push(values);
      }
      if (
        (displayName === "Gas connection" ||
          displayName === "Rain water harvesting" ||
          displayName === "Latrine" ||
          displayName === "Swimming Pool" ||
          displayName === "Aqua Fish" ||
          displayName === "Driving License" ||
          displayName === "Home Stay" ||
          displayName === "Poultry" ||
          displayName === "Poultry Details" ||
          displayName === "Caste" ||
          displayName === "Religion" ||
          displayName === "Cattle" ||
          displayName === "Cattle Details" ||
          displayName === "Solar panel" ||
          displayName === "Well" ||
          displayName === "Water connection" ||
          displayName === "Water Level Hit" ||
          displayName === "Flood Effected" ||
          displayName === "Floor Count" ||
          displayName === "Ferry" ||
          displayName === "Saline Land" ||
          displayName === "Total Plot Area" ||
          (displayName === "Electricity Connection" && values.related_table === "property_other_details") ||
          (displayName === "Consumer Number" && values.related_table === "property_other_details") ||
          displayName === "Waste Management" ||
          displayName === "Ration Card Number" ||
          displayName === "Ration card Colour" ||
          displayName === "Bank account" ||
          displayName === "Bedridden Stroke" ||
          displayName === "Bio Waste Treatment Facility" ||
          displayName === "Cattle Details" ||
          displayName === "Cattle Shed" ||
          displayName === "Cattle Waste" ||
          displayName === "Crop Cultivation" ||
          displayName === "Crop Cultivation Land Area" ||
          displayName === "Crop Cultivation Land Name" ||
          displayName === "Death Cause" ||
          displayName === "Death Count" ||
          displayName === "Death in 1year" ||
          displayName === "Dog Count" ||
          displayName === "Driving License" ||
          displayName === "EX-Service Type" ||
          displayName === "EX-Servicemen House" ||
          displayName === "Government health card" ||
          displayName === "Home Stay" ||
          displayName === "Lady Driver" ||
          displayName === "Lady Driver Name" ||
          displayName === "MNREGS Card" ||
          // (displayName === "Number of vehicle" && values.related_table === "vehicledetails") ||
          (displayName === "Vehicle Usage" && values.related_table === "vehicledetails") ||
          (displayName === "Vehicle Type" && values.related_table === "vehicledetails") ||
          displayName === "Member count" ||
          displayName === "Monthly Income" ||
          displayName === "Palliative Patient" ||
          displayName === "Parking area" ||
          displayName === "Private Health Insurance" ||
          displayName === "Rain water harvesting" ||
          displayName === "Residence association details" ||
          displayName === "Swimming Pool Area" ||
          displayName === "Tank Area" ||
          displayName === "Tax Exemption" ||
          displayName === "Tax exemption Reason" ||
          displayName === "Toilet Septic Tank" ||
          displayName === "Water Source" ||
          displayName === "pet details" ||
          displayName === "well perennial month" ||
          displayName === "Farming" ||
          displayName === "Scheme" ||
          displayName === "Farming Income" ||
          displayName === "Farming Irrigation" ||
          displayName === "Farming Land Type" ||
          displayName === "Farming Testing" ||
          displayName === "Ferry" ||
          displayName === "Fish Farming" ||
          displayName === "Harithasena" ||
          displayName === "Kudumbashree" ||
          displayName === "Name of Farming" ||
          (displayName === "Thozhilurappu" &&
            values.related_table === "property_other_details") ||

          (displayName === "Bathroom" && values.related_table === "property_other_details")
        ) &&
        values.values !== "NA" &&
        values.values != -2.00 &&
        values.values != -2.0000 &&
        values.values != -2 &&
        values.values !== "NA@gmail.com" &&
        values.values !== "None" &&
        values.values !== "NIQ" &&
        values.values !== null
      ) {
        if (values.values == -1 || values.value === -1.00 || values.value == -1.0000) {
          values.values = "NR";
        }
        detailinfoTable.Socio_Economic_Details.push(values);
      }
      if (
        (displayName === "Well" ||
          displayName === "Water in well" ||
          displayName === "Water connection" ||
          (displayName === "Water Connection Type" && values.related_table === "property_other_details") ||
          displayName === "water supply duration" ||
          displayName === "water shortage" ||
          displayName === "waste management toilet" ||
          displayName === "other waste management" ||
          displayName === "Liquid Waste Management Type" ||
          displayName === "Organic Waste Management Type" ||
          displayName === "Plastic Waste Management Type") &&
        values.values !== "NA" &&
        values.values !== "None" &&
        values.values != -2.00 &&
        values.values != -2.0000 &&
        values.values != -2 &&
        values.values !== "NIQ" &&
        values.values !== null
      ) {
        detailinfoTable.Drinking_Water_and_Waste_Management.push(values);
      }

      if (
        ((displayName === "mobile" &&
          values.related_table === "tenant_details") ||
          // (displayName === "Tenant Native" &&
          //   values.related_table === "tenant_details") ||
          // (displayName === "Tenant Status" &&
          //     values.related_table === "tenant_details") ||
          // (displayName === "Tenant Survey No" &&
          //       values.related_table === "tenant_details") ||
          (displayName === "email" &&
            values.related_table === "tenant_details") ||
          (displayName === "Rent Amount" &&
            values.related_table === "tenant_details") ||
          (displayName === "Tenant State" &&
            values.related_table === "tenant_details")) &&
        values.values !== "NA@gmail.com" &&
        values.values !== "NA" &&
        values.values != -2.00 &&
        values.values != -2.0000 &&
        values.values != -2 &&
        values.values !== "None" &&
        values.values !== "NIQ" &&
        values.values !== null
      ) {
        if (values.values === -1 || values.values === "-1") {
          values.values = "NR";
        }
        detailinfoTable.Tenant_Details.push(values);
      }
      if (
        displayName === "First Name" &&
        values.related_table === "tenant_details"
      ) {
        firstName = values.values;
      }
      if (
        displayName === "Last Name" &&
        values.related_table === "tenant_details"
      ) {
        lastName = values.values;
      }
      if (
        displayName === "Tenant House Name" &&
        values.related_table === "tenant_details"
      ) {
        house = values.values;
      }
      if (
        displayName === "place name" &&
        values.related_table === "tenant_details"
      ) {
        place = values.values;
      }
      if (
        displayName === "Tenant Post Office" &&
        values.related_table === "tenant_details"
      ) {
        post = values.values;
      }
      if (
        displayName === "Tenant Pincode" &&
        values.related_table === "tenant_details"
      ) {
        pin = values.values;
      }
      if (firstName && lastName && !fullNameAdded) {
        let fullName = (firstName !== "NA" && firstName !== "NR" && firstName !== "None" && lastName !== "NA" && lastName !== "NR" && lastName !== "None") ? (firstName + " " + lastName).trim() : "";
        let value = {
          display_name: "Name",
          values: fullName,
          related_table: "tenant_details",
        };
        if (value.values !== "") {
          fullNameAdded = true;
          detailinfoTable.Tenant_Details.push(value);
        }
      }

      if (house && place && post && pin && !fullAddress) {
        let fullAdd = [house, place, post, pin]
          .filter(
            (part) =>
              // part !== "NA@gmail.com" &&
              part !== "NA" &&
              part !== "None" &&
              part !== "NIQ" &&
              part !== null
          )
          .join(", ")
          .trim();
        let value = {
          display_name: "Address",
          values: fullAdd,
          related_table: "tenant_details",
        };
        if (fullAdd) {
          fullAddress = true;
          detailinfoTable.Tenant_Details.push(value);
        }
      }

      if (
        (displayName === "Establishment Name" ||
          displayName === "Establishment Type" ||
          displayName === "Establishment Year" ||
          displayName === "Owner Name" ||
          (displayName === "mobile" &&
            values.related_table === "establishment") ||
          displayName === "Incharge Name" ||
          displayName === "Incharge Mobile" ||
          displayName === "Incharge Post" ||
          displayName === "Number of Employees" ||
          // displayName === "Female Employee Count" ||
          // displayName === "Male Employee Count" ||
          (displayName === "email" &&
            values.related_table === "establishment") ||
          (displayName === "landphone" &&
            values.related_table === "establishment") ||
          // displayName === "Establishment PF" ||
          // displayName === "Establishment Post" ||
          // displayName === "Incharge Role" ||
          // displayName === "Professional tax payment status" ||
          displayName === "License" ||
          displayName === "License No" ||
          displayName === "GST status" ||
          displayName === "GST Number") &&
        values.values !== "NA" &&
        values.values != -2.00 &&
        values.values != -2.0000 &&
        values.values != -2 &&
        values.values !== "NA@gmail.com" &&
        values.values !== "None" &&
        values.values !== "NIQ" &&
        values.values !== null
      ) {
        if (values.values === -1 || values.values === "-1") {
          values.values = "NR";
        }
        detailinfoTable.Establishment_Details.push(values);
      }
      if (
        (displayName === "Owner address" && values.related_table === "assessment_register_details") ||
        (displayName === "Building ID" && values.related_table === "assessment_register_details") ||
        (displayName === "Building usage" && values.related_table === "assessment_register_details") ||
        (displayName === "Floor area" && values.related_table === "assessment_register_details") ||
        (displayName === "Total" && values.related_table === "assessment_register_details") &&
        values.values !== "NA" &&
        values.values != -2.00 &&
        values.values != -2.0000 &&
        values.values != -2 &&
        values.values !== "NA@gmail.com" &&
        values.values !== "None" &&
        values.values !== "NIQ" &&
        values.values !== null
      ) {
        if (values.values === -1 || values.values === "-1") {
          values.values = "NR";
        }
        detailinfoTable.AR_Details.push(values);
      }
      if (
        (displayName === "Cooperation" ||
          displayName === "Informed By" ||
          displayName === "Remark" ||
          displayName === "Property" ||
          displayName === "New Property Id Remark" ||
          displayName === "Old Property Id Remark" ||
          displayName === "Ownership change" ||
          displayName === "Surveyor") &&
        // displayName ===
        values.values !== "NA" &&
        values.values != -2.00 &&
        values.values != -2.0000 &&
        values.values != -2 &&
        values.values !== "NA@gmail.com" &&
        values.values !== "None" &&
        values.values !== "NIQ" &&
        values.values !== null
      ) {
        if (values.values === -1 || values.values === "-1") {
          values.values = "NR";
        }
        detailinfoTable.Survey_Details.push(values);
      }
    });
    return (
      <React.Fragment>
        <Collapse
          in={this.props.isDetailedInfoVisible}
          className="featureInfoPanel"
        >
          <Panel bsStyle="success" className="detailedInfoPanel">
            <Panel.Heading>
              <Panel.Title>
                <Label className="padding" bsStyle="primary">
                  Total Features
                </Label>{" "}
                <Badge>{this.props.total_count + 1}</Badge>
              </Panel.Title>

              <ButtonToolbar className="pull-right button">
                <ButtonGroup>
                  <Button
                    onClick={this.props.onCountDecrement}
                    className="topbutton"
                    disabled={this.props.count == 0}
                  >
                    <i className="fa fa-angle-left" />
                  </Button>
                  <Button className="topbutton infobutton">
                    {this.props.count + 1}
                  </Button>
                  <Button
                    className="topbutton"
                    disabled={this.props.count == this.props.total_count}
                    onClick={this.props.onCountIncrement}
                  >
                    <i className="fa fa-angle-right" />
                  </Button>
                  <button
                    className="dpanelheadbtn"
                    onClick={this.props.onDetailedInfoClose}
                  >
                    <img src={closeButton} />
                  </button>
                </ButtonGroup>
              </ButtonToolbar>
            </Panel.Heading>
            <Modal
              className="imageModel"
              show={this.state.show}
              bsSize="large"
              aria-labelledby="contained-modal-title-lg"
              onHide={this.displayImage}
            >
              <Modal.Header className="imageModelHeader">
                <Modal.Title id="contained-modal-title-lg">
                  Photographs
                  <ButtonToolbar className="pull-right button">
                    <img
                      src={closeButton}
                      className="photoheadbtn"
                      onClick={this.displayImage}
                    />
                  </ButtonToolbar>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="imageModelBody">
                {this.props.detailedInfoContents != null && (
                  <div className="imageContainerModel">
                    {images != undefined &&
                      images.length > 0 &&
                      img_url != undefined && (
                        <Carousel>
                          {images.map(function (image, index) {
                            return (
                              <Carousel.Item key={index}>
                                <img
                                  className={image_class}
                                  key={index}
                                  src={img_url.concat(image)}
                                  width={750}
                                  height={750}
                                  alt="350x350"
                                />
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      )}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className="imageModelFooter">
                <Button bsStyle="primary" onClick={() => this.rotateImage()}>
                  Rotate
                </Button>
                <Button bsStyle="primary" onClick={this.displayImage}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              className="pdfModel"
              show={this.state.showpdf}
              bsSize="large"
              aria-labelledby="contained-modal-title-lg"
            >
              <Modal.Header className="pdfModelHeader">
                <Modal.Title id="contained-modal-title-lg">
                  PDF VIEWER
                  <ButtonToolbar className="pull-right button">
                    <Button bsStyle="primary" onClick={this.displayPdf}>
                      <i className="fa fa-times" />
                    </Button>
                  </ButtonToolbar>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="pdfModelBody">
                {this.props.detailedInfoContents != null &&
                  this.state.showpdf &&
                  img_url != undefined && (
                    <div className="pdfContainerModel">
                      <iframe
                        className="pdf_iframe"
                        src={img_url.concat("pdf/" + this.state.pdffile)}
                        frameBorder="0"
                        allowFullScreen
                      />
                    </div>
                  )}
              </Modal.Body>
              <Modal.Footer className="pdfModelFooter">
                <Button bsStyle="primary" onClick={this.displayPdf}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            {this.props.detailedInfoContents != null && (
              <Panel.Body
                className="featureInfoPanelBody"
                id="featureInfoPanelBody"
              >
                <div className="imageContainer" onClick={this.displayImage}>
                  {images != undefined &&
                    images.length > 0 &&
                    img_url != undefined && (
                      <Carousel>
                        {images.map(function (image, index) {
                          return (
                            <Carousel.Item key={index}>
                              <img
                                key={index}
                                src={img_url.concat(image)}
                                width={350}
                                height={350}
                                alt="350x350"
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    )}
                </div>
                <div>
                  {prog.length == 3 && (
                    <div className="show-gridsss">
                      {prog.map((element) => {
                        return (
                          <Row>
                            <Col md={4} className="progresbarname">
                              <p>
                                <b>{element.name}</b>
                              </p>
                            </Col>
                            <Col md={8} className="progresbar">
                              <ProgressBar
                                striped
                                active
                                bsStyle="success"
                                now={element.per}
                                label={`${element.per}%`}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  )}
                </div>
                {detailinfoTable &&
                  Object.keys(detailinfoTable).map((key) => {
                    if (trimmedName == "buildingasset_property") {
                      return (
                        <DetailInfoTable
                          name={key}
                          data={detailinfoTable[key]}
                        />
                      );
                    }
                    // <DetailInfoTable name={key} data={detailinfoTable[key]} />
                  })}

                {trimmedName !== "buildingasset_property" && (
                  <TableFormat displayName="Basic Details">
                    <tbody className="tablebody">
                      {this.showUpdationStatus()}
                      {this.formatInfo()}
                      {itemsForeign}
                    </tbody>
                  </TableFormat>
                )}
                {/* <TableFormat displayName="Basic Details">
                  <tbody className="tablebody">
                    {this.showUpdationStatus()}
                    {this.formatInfo()}
                    {itemsForeign}
                  </tbody>
                </TableFormat> */}
                {/* <DetailInfoTable data={detailinfoTable}/> */}

                {this.props.detailedInfoContentsForeignKey[
                  this.props.count
                ].related_tables.map((data) => {
                  if (
                    data.related_table === "members" &&
                    data.fields.length > 0
                  ) {
                    return <MemberDetails data={data.fields} />;
                  }
                  // if (
                  //   data.related_table === "floor_prop_area" &&
                  //   data.fields.length > 0
                  // ) {
                  //   return <PropertyArea data={data.fields} />;
                  // }
                  // if (
                  //   data.related_table === "property_floor_type" &&
                  //   data.fields.length > 0
                  // ) {
                  //   return <FloorType data={data.fields} />;
                  // }
                  // if (
                  //   data.related_table === "property_roof_type" &&
                  //   data.fields.length > 0
                  // ) {
                  //   return <RoofType data={data.fields} />;
                  // }
                  // if (
                  //   data.fields.length > 0 &&
                  //   !(
                  //     layerName == "Residential" &&
                  //     data.related_table === "establishment"
                  //   ) &&
                  //   !(
                  //     layerName !== "Residential" &&
                  //     data.related_table === "property_Other_Details"
                  //   )
                  // ) {
                  //   return <RelatedTables data={data} />;
                  // }
                })}
                {this.props.detailedInfoContentsForeignKey &&
                  this.props.detailedInfoContentsForeignKey[this.props.count] &&
                  this.props.detailedInfoContentsForeignKey[this.props.count]
                    .editable &&
                  this.props.detailedInfoContentsForeignKey[this.props.count]
                    .available_permissions &&
                  this.props.detailedInfoContentsForeignKey[this.props.count]
                    .available_permissions.length ? (
                  <Button
                    bsStyle="primary"
                    block
                    onClick={(e) => {
                      this.setState({
                        editAddItem: !this.state.editAddItem,
                      });
                    }}
                  >
                    Action
                  </Button>
                ) : (
                  ""
                )}
                {this.props.detailedInfoContentsForeignKey &&
                  this.props.detailedInfoContentsForeignKey[this.props.count] &&
                  this.props.detailedInfoContentsForeignKey[this.props.count]
                    .available_permissions ? (
                  <Collapse
                    in={this.state.editAddItem}
                    className="featureInfoPanel"
                  >
                    <div>
                      {this.props.detailedInfoContentsForeignKey[
                        this.props.count
                      ].available_permissions.indexOf("can_edit") >= 0 ? (
                        <Button
                          className="editAddButton"
                          onClick={(e) => {
                            window.open(
                              ADMIN_URL +
                              "map/login/" +
                              LocalStorageHandler.getToken() +
                              "/" +
                              layer_id.replace("_", "/").replace(".", "/") +
                              "/change/",
                              "_blank"
                            );
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        ""
                      )}
                      {/* {this.props.detailedInfoContentsForeignKey[
                        this.props.count
                      ].available_permissions.indexOf("can_add") >= 0 ? (
                        <Button
                          className="editAddButton"
                          onClick={(e) => {
                            window.open(
                              ADMIN_URL +
                                "admin/" +
                                layer_id.split(".")[0].replace("_", "/") +
                                "/add/",
                              "_blank"
                            );
                          }}
                        >
                          Add New
                        </Button>
                      ) : (
                        ""
                      )} */}
                      <PdfReport
                        count={this.props.count}
                        detailedInfoContents={this.props.detailedInfoContents}
                        detailedInfoContentsForeignKey={
                          this.props.detailedInfoContentsForeignKey
                        }
                        selectedLayer={this.props.selectedLayer}
                        renderImage={this.renderImage}
                      ></PdfReport>
                    </div>
                  </Collapse>
                ) : (
                  ""
                )}
                <div className="pdfBotton">
                  <ButtonGroup bsSize="xsmall">
                    {this.state.pdf_mgmt && (
                      <Button
                        bsStyle="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          this.displayPdf(this.state.pdf_mgmt_file, e);
                        }}
                      >
                        Management Details
                      </Button>
                    )}
                    {this.state.pdf_doc && (
                      <Button
                        bsStyle="success"
                        onClick={(e) => {
                          e.preventDefault();
                          this.displayPdf(this.state.pdf_doc_file, e);
                        }}
                      >
                        Documents
                      </Button>
                    )}
                  </ButtonGroup>
                </div>
              </Panel.Body>
            )}
          </Panel>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default DetailedInfo;

// import React, { Component } from "react";
// import {
//   Collapse,
//   Row,
//   Col,
//   Panel,
//   Button,
//   ButtonToolbar,
//   ButtonGroup,
//   Carousel,
//   Modal,
//   Badge,
//   Label,
//   ProgressBar,
// } from "react-bootstrap";
// import "./DetailedInfo.css";
// import LocalStorageHandler from "../../../../../util/storage";
// import closeButton from "../../../../../asset/svg/close_icon.svg";
// import "jspdf-autotable";
// import MemberDetails from "./MemberDetails";
// import RelatedTables from "./RelatedTables";
// import PropertyArea from "./PropertyArea";
// import FloorType from "./FloorType";
// import RoofType from "./RoofType";
// import { ADMIN_URL } from "../../../../../../src/config";
// import PdfReport from "./PdfRepoert";
// import DataFormat from "../../helper/DataFormat";
// import TableFormat from "./TableFormat";

// class DetailedInfo extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       imageName: null,
//       show: false,
//       showpdf: false,
//       img: null,
//       key: null,
//       pdf_mgmt: false,
//       pdf_doc: false,
//       pdffile: null,
//       total_count: 0,
//       pdf_doc_file: null,
//       pdf_mgmt_file: null,
//       image_status: true,
//       image_class: "img-responsive center-block",
//       memberDetailStatus: false,
//       basicDetailStatus: false,
//     };

//     this.displayImage = this.displayImage.bind(this);
//     this.displayPdf = this.displayPdf.bind(this);
//   }

//   componentDidUpdate(prevProps) {
//     if (
//       this.props.detailedInfoContents !== null &&
//       this.props.detailedInfoContents !== prevProps.detailedInfoContents
//     ) {
//       this.setUpPdfdoc();
//       this.setUpPdfMgmt();
//     }
//   }

//   setUpPdfdoc = () => {
//     let pdf = null;
//     if (this.props.detailedInfoContents != null) {
//       const detailedInfoContent = this.props.detailedInfoContents[
//         this.props.count
//       ];

//       try {
//         if (detailedInfoContent != undefined) {
//           Object.keys(detailedInfoContent).map((key, index) => {
//             if (key.includes("p_d_f_doc")) {
//               this.setState({
//                 pdf_doc: true,
//               });

//               if (key == "p_d_f_doc") {
//                 this.setState({
//                   pdf_doc_file: detailedInfoContent[key].value,
//                 });
//               } else {
//                 this.setState({
//                   pdf_doc_file: "filenotfound.pdf",
//                 });
//               }
//             }
//           });
//         }
//       } catch (e) {
//         console.log("Exception", e);
//       }
//     }
//   };

//   setUpPdfMgmt = () => {
//     if (this.props.detailedInfoContents != null) {
//       const detailedInfoContent = this.props.detailedInfoContents[
//         this.props.count
//       ];
//       let pdf = null;
//       try {
//         if (detailedInfoContent != undefined) {
//           Object.keys(detailedInfoContent).map((key) => {
//             if (key.includes("p_d_f_mgmt")) {
//               this.setState({
//                 pdf_mgmt: true,
//               });
//               if (key == "p_d_f_mgmt") {
//                 this.setState({
//                   pdf_mgmt_file: detailedInfoContent[key].value,
//                 });
//               } else {
//                 this.setState({
//                   pdf_mgmt_file: "filenotfound.pdf",
//                 });
//               }
//             }
//           });
//           return pdf;
//         }
//       } catch (e) {
//         console.log("Exception", e);
//       }
//     }
//   };
//   displayImage() {
//     this.setState({
//       show: !this.state.show,
//     });
//   }
//   rotateImage() {
//     if (this.state.image_status) {
//       this.setState({
//         image_class: "img-responsive center-block image-rotate",
//         image_status: !this.state.image_status,
//       });
//     } else {
//       this.setState({
//         image_class: "img-responsive center-block ",
//         image_status: !this.state.image_status,
//       });
//     }
//   }
//   displayPdf(param, e) {
//     this.setState({
//       pdffile: param,
//       showpdf: !this.state.showpdf,
//     });
//   }

//   showUpdationStatus = () => {
//     const { layerParent, status } = this.props;
//     if (
//       layerParent.includes("waterbody_pond") ||
//       layerParent.includes("buildingasset_property")
//     )
//      {
//       return (
//         <>
//           <tr>
//             <td>Updation  status</td>
//             <td>{DataFormat.updationStatus(status)}</td>
//           </tr>
//         </>
//       );
//       }
//   };

//   formatInfo() {
//     if (this.props.detailedInfoContents != null) {
//       const detailedInfoContent = this.props.detailedInfoContents[
//         this.props.count
//       ];
//       if (detailedInfoContent != undefined)
//         return Object.keys(detailedInfoContent).map((key) => {
//           if (key === "updation_status") {
//             return (
//               <>
//                 <tr key={key}>
//                   <td style={{ width: "350px" }}>
//                     {detailedInfoContent[key].display_name}
//                   </td>
//                   <td style={{ width: "350px" }}>
//                     {DataFormat.updationStatus(detailedInfoContent[key].value)}
//                   </td>
//                 </tr>
//               </>
//             );
//           }
//           if (
//             !key.includes("photo") &&
//             !key.includes("p_d_f_") &&
//             !DataFormat.dataFormatOr(detailedInfoContent[key].value)
//           ) {
//             return (
//               <tr key={key}>
//                 <td style={{ width: "300px" }}>
//                   {detailedInfoContent[key].display_name}
//                 </td>
//                 <td style={{ width: "300px" }}>
//                   {DataFormat.dataFormat(detailedInfoContent[key].value)}
//                 </td>
//               </tr>
//             );
//           }
//         });
//     }
//   }

//   formatInfoForeign() {
//     if (this.props.detailedInfoContentsForeignKey != null) {
//       const detailedInfoContentForeignKey = this.props
//         .detailedInfoContentsForeignKey[this.props.count];

//       if (detailedInfoContentForeignKey != undefined)
//         return Object.keys(detailedInfoContentForeignKey.foreignKeys).map(
//           (key) => {
//             if (
//               !key.includes("photo") &&
//               !key.includes("p_d_f_") &&
//               !DataFormat.dataFormatOr(
//                 detailedInfoContentForeignKey.foreignKeys[key].actual_value
//               )
//             ) {
//               return (
//                 <tr key={key}>
//                   <td>
//                     {detailedInfoContentForeignKey.foreignKeys[key].display_name
//                       ? detailedInfoContentForeignKey.foreignKeys[key]
//                           .display_name
//                       : detailedInfoContentForeignKey.foreignKeys[key].field}
//                   </td>
//                   <td>
//                     {DataFormat.dataFormat(
//                       detailedInfoContentForeignKey.foreignKeys[key]
//                         .actual_value
//                     )}
//                   </td>
//                 </tr>
//               );
//             }
//           }
//         );
//     }
//   }

//   renderProgres() {
//     if (this.props.detailedInfoContents != null) {
//       const detailedInfoContent = this.props.detailedInfoContents[
//         this.props.count
//       ];
//       let prog = [];
//       var element = {};
//       try {
//         if (detailedInfoContent != undefined) {
//           Object.keys(detailedInfoContent).map((key) => {
//             if (key.includes("c_h_p_")) {
//               element = {};
//               element.name = detailedInfoContent[key].display_name
//                 ? detailedInfoContent[key].display_name
//                 : detailedInfoContent[key].field;
//               element.per = detailedInfoContent[key].value;
//               prog.push(element);
//             }
//           });
//         }
//         return prog;
//       } catch (e) {
//         console.log("Exception", e);
//       }
//     }
//   }

//   renderImage = () => {
//     let imageName = [];
//     if (this.props.detailedInfoContents != null) {
//       const detailedInfoContent = this.props.detailedInfoContents[
//         this.props.count
//       ];
//       try {
//         if (detailedInfoContent != undefined) {
//           Object.keys(detailedInfoContent).map((key) => {

//             if (key.includes("photo") && detailedInfoContent[key].value != null)
//               imageName.push(
//                 detailedInfoContent[key].value.replace("JPG", "jpg")
//               );
//           });
//         }
//         return imageName;

//       } catch (e) {
//         console.log("Exception", e);
//       }
//     }
//   };

//   render() {
//     const itemsForeign = this.formatInfoForeign();
//     const images = this.renderImage();
//     const prog = this.renderProgres();
//     const image_class = this.state.image_class;
//     const img_url = LocalStorageHandler.getLocalBody().image_url;

//     const { selectedLayer } = this.props;
//     var layerName = "";
//     if (selectedLayer) {
//       layerName = selectedLayer.getProperties().name;
//     }
//     var layer_id =
//       this.props.detailedInfoContentsForeignKey &&
//       this.props.detailedInfoContentsForeignKey[this.props.count].id;

// var table_Datas = []
// if (
//   this.props.detailedInfoContentsForeignKey &&
//   this.props.count !== undefined &&
//   this.props.detailedInfoContentsForeignKey[this.props.count] &&
//   this.props.detailedInfoContentsForeignKey[this.props.count].related_tables
// ) {
//   table_Datas = this.props.detailedInfoContentsForeignKey[this.props.count].related_tables;
// } else {
//   console.error('Invalid data or count value. Cannot access related_tables.');
// }

// // const combinedItem = {
// //     table_name: 'buildingasset_property',
// //     related_table: 'combined',
// //     index:null,
// //     display_name: '',
// //     fields: [],
// //   };

//   // for (const item of table_Datas)
//   // {
//   //   if(item.display_name.includes("Roof type") || item.display_name.includes("Floor type"))
//   //   {
//   //     combinedItem.display_name = "Form 6 Details"
//   //   combinedItem.fields = combinedItem.fields.concat(item.fields);
//   //   combinedItem.index = 6;
//   //   }
//   // }

//   // if (combinedItem.display_name = "Form 6 Details") {
//   //   table_Datas = table_Datas.filter((item) => !item.display_name.includes("Roof type") && !item.display_name.includes("Floor type"));
//   //   table_Datas.push(combinedItem)
//   // }

//   var sortedData = []
//   if (
//       this.props.detailedInfoContentsForeignKey &&
//       this.props.count !== undefined &&
//       this.props.detailedInfoContentsForeignKey[this.props.count] &&
//       this.props.detailedInfoContentsForeignKey[this.props.count].related_tables
//     ) {

//       sortedData = table_Datas.sort((a,b)=>a.index - b.index);
//     }

//     return (
//       <React.Fragment>
//         <Collapse
//           in={this.props.isDetailedInfoVisible}
//           className="featureInfoPanel"
//         >
//           <Panel bsStyle="success" className="detailedInfoPanel">
//             <Panel.Heading>
//               <Panel.Title>
//                 <Label className="padding" bsStyle="primary">
//                   Total Features
//                 </Label>{" "}
//                 <Badge>{this.props.total_count + 1}</Badge>
//               </Panel.Title>

//               <ButtonToolbar className="pull-right button">
//                 <ButtonGroup>
//                   <Button
//                     onClick={this.props.onCountDecrement}
//                     className="topbutton"
//                     disabled={this.props.count == 0}
//                   >
//                     <i className="fa fa-angle-left" />
//                   </Button>
//                   <Button className="topbutton infobutton">
//                     {this.props.count + 1}
//                   </Button>
//                   <Button
//                     className="topbutton"
//                     disabled={this.props.count == this.props.total_count}
//                     onClick={this.props.onCountIncrement}
//                   >
//                     <i className="fa fa-angle-right" />
//                   </Button>
//                   <button
//                     className="dpanelheadbtn"
//                     onClick={this.props.onDetailedInfoClose}
//                   >
//                     <img src={closeButton} />
//                   </button>
//                 </ButtonGroup>
//               </ButtonToolbar>
//             </Panel.Heading>
//             <Modal
//               className="imageModel"
//               show={this.state.show}
//               bsSize="large"
//               aria-labelledby="contained-modal-title-lg"
//               onHide={this.displayImage}
//             >
//               <Modal.Header className="imageModelHeader">
//                 <Modal.Title id="contained-modal-title-lg">
//                   Photographs
//                   <ButtonToolbar className="pull-right button">
//                     <img
//                       src={closeButton}
//                       className="photoheadbtn"
//                       onClick={this.displayImage}
//                     />
//                   </ButtonToolbar>
//                 </Modal.Title>
//               </Modal.Header>
//               <Modal.Body className="imageModelBody">
//                 {this.props.detailedInfoContents != null && (
//                   <div className="imageContainerModel">
//                     {images != undefined &&
//                       images.length > 0 &&
//                       img_url != undefined && (
//                         <Carousel>
//                           {images.map(function(image, index) {
//                             return (
//                               <Carousel.Item key={index}>
//                                 <img
//                                   className={image_class}
//                                   key={index}
//                                   src={img_url.concat(image)}
//                                   width={750}
//                                   height={750}
//                                   alt="350x350"
//                                 />
//                               </Carousel.Item>
//                             );
//                           })}
//                         </Carousel>
//                       )}
//                   </div>
//                 )}
//               </Modal.Body>
//               <Modal.Footer className="imageModelFooter">
//                 <Button bsStyle="primary" onClick={() => this.rotateImage()}>
//                   Rotate
//                 </Button>
//                 <Button bsStyle="primary" onClick={this.displayImage}>
//                   Close
//                 </Button>
//               </Modal.Footer>
//             </Modal>
//             <Modal
//               className="pdfModel"
//               show={this.state.showpdf}
//               bsSize="large"
//               aria-labelledby="contained-modal-title-lg"
//             >
//               <Modal.Header className="pdfModelHeader">
//                 <Modal.Title id="contained-modal-title-lg">
//                   PDF VIEWER
//                   <ButtonToolbar className="pull-right button">
//                     <Button bsStyle="primary" onClick={this.displayPdf}>
//                       <i className="fa fa-times" />
//                     </Button>
//                   </ButtonToolbar>
//                 </Modal.Title>
//               </Modal.Header>
//               <Modal.Body className="pdfModelBody">
//                 {this.props.detailedInfoContents != null &&
//                   this.state.showpdf &&
//                   img_url != undefined && (
//                     <div className="pdfContainerModel">
//                       <iframe
//                         className="pdf_iframe"
//                         src={img_url.concat("pdf/" + this.state.pdffile)}
//                         frameBorder="0"
//                         allowFullScreen
//                       />
//                     </div>
//                   )}
//               </Modal.Body>
//               <Modal.Footer className="pdfModelFooter">
//                 <Button bsStyle="primary" onClick={this.displayPdf}>
//                   Close
//                 </Button>
//               </Modal.Footer>
//             </Modal>
//             {this.props.detailedInfoContents != null && (
//               <Panel.Body
//                 className="featureInfoPanelBody"
//                 id="featureInfoPanelBody"
//               >
//                 <div className="imageContainer" onClick={this.displayImage}>
//                   {images != undefined &&
//                     images.length > 0 &&
//                     img_url != undefined && (
//                       <Carousel>
//                         {images.map(function(image, index) {
//                           return (
//                             <Carousel.Item key={index}>
//                               <img
//                                 key={index}
//                                 src={img_url.concat(image)}
//                                 width={350}
//                                 height={350}
//                                 alt="350x350"
//                               />
//                             </Carousel.Item>
//                           );
//                         })}
//                       </Carousel>
//                     )}
//                 </div>
//                 <div>
//                   {prog.length == 3 && (
//                     <div className="show-gridsss">
//                       {prog.map((element) => {
//                         return (
//                           <Row>
//                             <Col md={4} className="progresbarname">
//                               <p>
//                                 <b>{element.name}</b>
//                               </p>
//                             </Col>
//                             <Col md={8} className="progresbar">
//                               <ProgressBar
//                                 striped
//                                 active
//                                 bsStyle="success"
//                                 now={element.per}
//                                 label={`${element.per}%`}
//                               />
//                             </Col>
//                           </Row>
//                         );
//                       })}
//                     </div>
//                   )}
//                 </div>
//                 <TableFormat displayName="Basic Details">
//                   <tbody className="tablebody">
//                     {this.showUpdationStatus()}
//                     {this.formatInfo()}
//                     {itemsForeign}
//                   </tbody>
//                     </TableFormat>

//                     {sortedData.map((data) => {

//                   if (
//                     data.related_table === "members" &&
//                     data.fields.length > 0
//                   ) {
//                     return <MemberDetails data={data.fields} />;
//                   }
//                   if (
//                     data.related_table === "floor_prop_area" &&
//                     data.fields.length > 0
//                   ) {
//                     return <PropertyArea data={data.fields} />;
//                   }

//                   if (
//                     data.related_table === "property_floor_type" &&
//                      data.related_table === "property_roof_type" &&
//                     data.fields.length > 0
//                   ) {
//                     return <RoofType data={data.fields} />;
//                   }
//                   if (
//                     data.fields.length > 0 &&
//                     !(
//                       layerName == "Residential" &&
//                       data.related_table === "establishment"
//                     ) &&
//                     !(
//                       layerName !== "Residential" &&
//                       data.related_table === "property_Other_Details"
//                     )
//                   ) {
//                     return <RelatedTables data={data} />;
//                   }
//                 })}
//                 {/*<TableFormat displayName="Basic Details">
//                   <tbody className="tablebody">
//                     {this.showUpdationStatus()}
//                     {this.formatInfo()}
//                     {itemsForeign}
//                   </tbody>
//                     </TableFormat>*/}
//                 {this.props.detailedInfoContentsForeignKey &&
//                 this.props.detailedInfoContentsForeignKey[this.props.count] &&
//                 this.props.detailedInfoContentsForeignKey[this.props.count]
//                   .editable &&
//                 this.props.detailedInfoContentsForeignKey[this.props.count]
//                   .available_permissions &&
//                 this.props.detailedInfoContentsForeignKey[this.props.count]
//                   .available_permissions.length ? (
//                   <Button
//                     bsStyle="primary"
//                     block
//                     onClick={(e) => {
//                       this.setState({
//                         editAddItem: !this.state.editAddItem,
//                       });
//                     }}
//                   >
//                     Action
//                   </Button>
//                 ) : (
//                   ""
//                 )}
//                 {this.props.detailedInfoContentsForeignKey &&
//                 this.props.detailedInfoContentsForeignKey[this.props.count] &&
//                 this.props.detailedInfoContentsForeignKey[this.props.count]
//                   .available_permissions ? (
//                   <Collapse
//                     in={this.state.editAddItem}
//                     className="featureInfoPanel"
//                   >
//                     <div>
//                       {this.props.detailedInfoContentsForeignKey[
//                         this.props.count
//                       ].available_permissions.indexOf("can_edit") >= 0 ? (
//                         <Button
//                           className="editAddButton"
//                           onClick={(e) => {
//                             window.open(
//                               ADMIN_URL +
//                                 "map/login/" +
//                                 LocalStorageHandler.getToken() +
//                                 "/" +
//                                 layer_id.replace("_", "/").replace(".", "/") +
//                                 "/change/",
//                               "_blank"
//                             );
//                           }}
//                         >
//                           Edit
//                         </Button>
//                       ) : (
//                         ""
//                       )}
//                       {/* {this.props.detailedInfoContentsForeignKey[
//                         this.props.count
//                       ].available_permissions.indexOf("can_add") >= 0 ? (
//                         <Button
//                           className="editAddButton"
//                           onClick={(e) => {
//                             window.open(
//                               ADMIN_URL +
//                                 "admin/" +
//                                 layer_id.split(".")[0].replace("_", "/") +
//                                 "/add/",
//                               "_blank"
//                             );
//                           }}
//                         >
//                           Add New
//                         </Button>
//                       ) : (
//                         ""
//                       )} */}
//                       <PdfReport
//                         count={this.props.count}
//                         detailedInfoContents={this.props.detailedInfoContents}
//                         detailedInfoContentsForeignKey={this.props.detailedInfoContentsForeignKey}
//                         selectedLayer={this.props.selectedLayer}
//                         renderImage={this.renderImage}
//                       ></PdfReport>
//                     </div>
//                   </Collapse>
//               ) : (
//                   ""
//                 )}
//                 <div className="pdfBotton">
//                   <ButtonGroup bsSize="xsmall">
//                     {this.state.pdf_mgmt && (
//                       <Button
//                         bsStyle="primary"
//                         onClick={(e) => {
//                           e.preventDefault();
//                           this.displayPdf(this.state.pdf_mgmt_file, e);
//                         }}
//                       >
//                         Management Details
//                       </Button>
//                     )}
//                     {this.state.pdf_doc && (
//                       <Button
//                         bsStyle="success"
//                         onClick={(e) => {
//                           e.preventDefault();
//                           this.displayPdf(this.state.pdf_doc_file, e);
//                         }}
//                       >
//                         Documents
//                       </Button>
//                     )}
//                   </ButtonGroup>
//                 </div>
//               </Panel.Body>
//             )}
//           </Panel>
//         </Collapse>
//       </React.Fragment>
//     );
//   }
// }

// export default DetailedInfo
