import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Table,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Badge,
} from "react-bootstrap";
import "../style.css";
import DashboardFilters from "./DashboardFilters";
import {
  getMemberDetails,
  getJobFilter,
  getEducationFilter,
  downloadAction,
  generateReport,
} from "../data/action";
import { loadingActions } from "../../loader/data/action";
import LocalStorageHandler from "../../../util/storage";
import TablePagination from "../../pagination/pagination";
import ConfirmBox from "../../confirmation_box";
import Pusher from "pusher-js";
import { CircularProgressbar } from "react-circular-progressbar";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ageFilters: {
        min_age: 0,
        max_age: 0,
      },
      actualFilters: {},
      singleFilters: {},
      ward_id: null,
      page: "1",
      limit: "10",
      recordCount: 0,
      fileName: "",
      fileType: "",
      fileFormat: "",
      downloadLimit: 100,
      downloadPageCount: 1,
      downloadConfirmation: false,
      readyToDownloadFlag: false,
      isDownloading: false,
      readyToDownloadFlagConfirm: false,
      pageLoading: 1,
      pageloadPercentage: 0,
      tableres: this.props.memberDetails && this.props.memberDetails.length > 0,
    };
  }

  
  
  componentDidMount() {
    {console.log("tableres", this.state.tableres)}
    this.props.getMemberDetails({
      common: {
        localbody_id: LocalStorageHandler.getLocalBody().local_body_id,
        ward_id__in: [this.state.ward_id],
      },
      member: {},
      other: {},

      disability: [this.state.actualFilters.disability__in],
      page: this.state.page,
      limit: this.state.limit,
    });
    if (this.props.isWardsLoaded) {
      this.setState({
        ...this.state,
        // ward_id: this.props.wards[0].id,
        ward_id: null,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isWardsLoaded != this.props.isWardsLoaded &&
      this.props.isWardsLoaded
    ) {
      this.setState({
        ...this.state,
        // ward_id: this.props.wards[0].id,
        ward_id: null,
      });
    }

    if (prevState.page != this.state.page) {
      //this.handleSubmit();
    }
    //download file functionality
    if (prevProps.reportFile != this.props.reportFile) {
      this.setState({ downloadConfirmation: false });
    }

    if (prevProps.downloadPage != this.props.downloadPage) {
      this.appendDownload();
    }
  }

  setFilters = (value, filter) => {
    if (filter === "min_age" || filter === "max_age") {
      this.setState({
        ...this.state,
        ageFilters: {
          ...this.state.ageFilters,
          [filter]: Number(value),
        },
      });
    } else if (value === "None") {
      let actualFilters = this.state.actualFilters;
      delete actualFilters[filter];
      this.setState({
        ...this.state,
        actualFilters: actualFilters,
      });
    } else {
      this.setState({
        ...this.state,
        actualFilters: { ...this.state.actualFilters, [filter]: [value] },
      });
    }
  };

  setSingleFilter = (value, filter) => {
    if (value === "") {
      let singleFilters = this.state.singleFilters;
      delete singleFilters[filter];
      this.setState({
        ...this.state,
        singleFilters: singleFilters,
      });
    } else {
      this.setState({
        ...this.state,
        singleFilters: { ...this.state.singleFilters, [filter]: value },
      });
    }
    if (filter === "job_category") {
      this.props.getJobFilter(value);
    }
    if (filter === "education_category") {
      this.props.getEducationFilter(value);
    }
  };

  clearFilter = () => {
    console.log("clear filter");
    this.setState({
      ageFilters: {
        min_age: 0,
        max_age: 0,
      },
      fileName: "",
      fileFormat: "",
      tableres: null,
      actualFilters: {
        diseases__in: [""],
        pension__in: [""],
        disability__in: [""],
      },
      singleFilters: {
        gender: "",
        blood_group: "",
        marital_status: "",
        job_category: "",
        education_category: "",
        caste: "",
      },
      // ward_id: this.props.wards[0].id,
      ward_id: null,
      readyToDownloadFlag: false,
    });
    this.setState({ isDownloading: false });
    console.log('clear state',this.state);
  };

  handleWardChange = (ward) => {
    this.setState({
      ...this.state,
      ward_id: ward,
    });
  };

  handleSubmit = (isPagination) => {
    this.setState({
      fileName: "",
      fileFormat: "",
    });
    if (isPagination != "isPagination") {
      this.setState({
        page: 1,
      });
    }
    const { actualFilters } = this.state;
    const member_filter = Object.fromEntries(
      Object.entries(actualFilters).filter(([_, value]) => value[0] !== "")
    );
    // if (this.state.ward_id === "0") {
    //   alert("You are loading a huge data, So it will take time..!!!");
    // }
    if (this.state.ageFilters.min_age > this.state.ageFilters.max_age) {
      alert("Min Age must be less than or equal to Max Age");
      return;
    }
    if (this.state.ageFilters.max_age > 0) {
      member_filter["age__range"] = [
        this.state.ageFilters.min_age,
        this.state.ageFilters.max_age,
      ];
    }
    let singleFilters = this.state.singleFilters;
    
    Object.entries(singleFilters).forEach(([key, value]) => {
      if (value === "") delete singleFilters[key];
    });
    let finalFilter = Object.assign(member_filter, singleFilters);
    let common =
      this.state.ward_id === "0" || this.state.ward_id === null
        ? {
            localbody_id: LocalStorageHandler.getLocalBody().local_body_id,
            ward_id__in: ["all"],
          }
        : {
            localbody_id: LocalStorageHandler.getLocalBody().local_body_id,
            ward_id__in: [this.state.ward_id],
          };
    this.props.getMemberDetails({
      common: common,
      member: finalFilter,
      other: {},
      disability: [this.state.actualFilters.disability__in],
      page: isPagination != "isPagination" ? 1 : this.state.page,
      limit: this.state.limit,
      unique_id: this.props.unique_id,
      recordCount: this.props.recordCount,
      file_name: this.state.fileName,
      file_extension: this.state.fileFormat,
    });
    this.setState({
      tableres: this.props.memberDetails && this.props.memberDetails.length > 0,
    });
    console.log("tableres", this.state.tableres);
  };
  afterPageClicked = (page) => {
    if (!isNaN(page))
      this.setState(
        {
          page: page,
        },
        () => this.handleSubmit("isPagination")
      );
  };

  handleInputChange = (event) => {
    this.setState({ fileFormat: event.target.value });
  };

  handleInputChangeName = (e) => {
    this.setState({ fileName: e.target.value });
  };

  downloadFile = () => {
    this.setState({ isDownloading: false });

    if (this.state.fileFormat === "csv") {
      let url =
        this.props.path +
        this.state.fileName +
        "_" +
        this.props.unique_id +
        ".csv";
      window.open(url);
    }

    if (this.state.fileFormat === "excel") {
      let downloadUrls =
        this.props.path +
        this.state.fileName +
        "_" +
        this.props.unique_id +
        ".xlsx";
      window.open(downloadUrls);
    }

  };

  generateReport = () => {

    this.props.recordCount > 1000 &&
      this.setState({ downloadConfirmation: true });
    this.setState({ downloadPercentage: 0, isDownloading: true });

    const page = 1;
    const recordCount = this.props.recordCount;
    const downloadLimit = this.state.downloadLimit;

    const limit =
      page * downloadLimit >= recordCount
        ? recordCount - (page - 1) * downloadLimit
        : downloadLimit;

    let data = {
      page,
      limit,
      report: "True",

      common: {
        localbody_id: LocalStorageHandler.getLocalBody().local_body_id,
        ward_id__in: [this.state.ward_id],
      },
      member: {
        gender: this.state.singleFilters.gender,
      ...(this.state.ageFilters.min_age !== 0 || this.state.ageFilters.max_age !== 0) && {
        age__range: [this.state.ageFilters.min_age, this.state.ageFilters.max_age],
    },
        marital_status: this.state.singleFilters.marital_status,
        job_category: this.state.singleFilters.job_category,
        education_category: this.state.singleFilters.education_category,
        caste: this.state.singleFilters.caste,
        ...(this.state.actualFilters.diseases__in != "" && { diseases: parseInt(this.state.actualFilters.diseases__in)}),
        ...(this.state.actualFilters.pension__in != null && { pension: parseInt(this.state.actualFilters.pension__in)}),
      },
      other: {},

      disability: [this.state.actualFilters.disability__in],
      unique_id: this.props.unique_id,
      file_name: this.state.fileName,
      file_extension: this.state.fileFormat,
      recordCount: this.props.recordCount,
      path: this.props.path,
      maxPage: this.props.recordCount / this.state.downloadLimit,
    };
  
    this.props.downloadAction(data);
    this.setState({ downloadPageCount: 1 });
  };

  appendDownload = () => {
    try {
      if (!this.state.isDownloading) return;
      let maxCount = this.props.recordCount / this.state.downloadLimit;
      let pusherCount = (100 / maxCount).toFixed(2);
      let currentPage = Number(this.state.downloadPageCount);
      let currentPusherCount = Number(this.state.downloadPercentage);

      const page = currentPage + 1;
      const recordCount = this.props.recordCount;
      const downloadLimit = this.state.downloadLimit;
      const limit =
        page * downloadLimit >= recordCount
          ? recordCount - (page - 1) * downloadLimit
          : downloadLimit;

      if (maxCount > currentPage) {
        let data = {
          page,
          limit,
          report: "True",

          common: {
            localbody_id: LocalStorageHandler.getLocalBody().local_body_id,
            ward_id__in: [this.state.ward_id],
          },
          member: {
            gender: this.state.singleFilters.gender,
            // age__range: [
            //   this.state.ageFilters.min_age,
            //   this.state.ageFilters.max_age,
            // ],
            ...(this.state.ageFilters.min_age !== 0 || this.state.ageFilters.max_age !== 0) && {
              age__range: [this.state.ageFilters.min_age, this.state.ageFilters.max_age],
          },
            marital_status: this.state.singleFilters.marital_status,
            job_category: this.state.singleFilters.job_category,
            education_category: this.state.singleFilters.education_category,
            caste: this.state.singleFilters.caste,
            ...(this.state.actualFilters.diseases__in != "" && { diseases: parseInt(this.state.actualFilters.diseases__in)}),
            ...(this.state.actualFilters.pension__in != null && { pension: parseInt(this.state.actualFilters.pension__in)}),
          },
          disability: [this.state.actualFilters.disability__in],
          other: {},
          unique_id: this.props.unique_id,
          file_name: this.state.fileName,
          file_extension: this.state.fileFormat,
          recordCount: this.props.recordCount,
          path: this.props.path,
          maxPage: this.props.recordCount / this.state.downloadLimit,
        };
        this.props.downloadAction(data);
        this.setState({ downloadPageCount: currentPage + 1 });

        var sumPercentage = Number(currentPusherCount) + Number(pusherCount);
        let finalPercentage = sumPercentage.toFixed(2);
        if (sumPercentage < 100) {
          this.setState({
            downloadPercentage: finalPercentage,
          });
        }
      } else {
        this.setState({
          readyToDownloadFlag: true,
          isDownloading: false,
          readyToDownloadFlagConfirm: true,
        });
      }
    } catch (e) {
      return;
    }
  };

  render() {
    const { localBodyId } = this.props;
   console.log('filterData',this.state);
    return (
      <>
        <DashboardFilters
          setFilters={this.setFilters}
          actualFilters={this.state.actualFilters}
          ageFilters={this.state.ageFilters}
          handleSubmit={this.handleSubmit}
          setSingleFilter={this.setSingleFilter}
          handleWardChange={this.handleWardChange}
          filterData={this.state}
          clearFilter={this.clearFilter}
          // fetchDashboardSummary={this.props.fetchDashboardSummary}
          localBodyId={localBodyId}
        />
        {/* {this.props.memberDetails && this.props.memberDetails.length > 0  && ( */}
          {this.props.memberDetails && this.props.memberDetails.length > 0  && (
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th>SL NO</th>
              <th>Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Education</th>
              <th>Marital Status</th>
              <th>Pension</th>
              <th>Diseases</th>
              <th>Property</th>
              <th>Disability</th>
              <th>Caste</th>
            </tr>
          </thead>
          
            <tbody>
              {this.props.memberDetails.map(
                (item, index) => {
                  return (
                    <tr key={index}>
                      <td>{(this.state.page - 1) * 10 + (index + 1)}</td>
                      <td>
                        {item.firstname +
                          " " +
                          (item.lastname === null ? "" : item.lastname)}
                      </td>
                      <td>{item.age}</td>
                      <td>{item.gender}</td>
                      <td>{item.education}</td>
                      <td>{item.marital_status}</td>
                      <td>{item.pension.toString()}</td>
                      <td>{item.diseases.toString()}</td>
                      <td>{item.property}</td>
                      <td>{item.disability.toString()}</td>
                      <td>{item.caste}</td>
                    </tr>
                  );
                },
                () => {
                  loadingComplete();
                }
              )}
            </tbody>
        </Table>
        )}
        {this.props.recordCount > 0 && (
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <h5 style={{ padding: "0px", marginTop: "34px" }}>
              Total/Searched Count: {this.props.recordCount}
              <Badge className="countBadge" />
            </h5>
          </Col>
        </Row>
        )}
        <div style={{ paddingBottom: "40px" }}>
          <TablePagination
            recordCount={this.props.recordCount}
            page={this.state.page}
            limit={this.state.limit}
            pageClicked={(item) => {
              this.afterPageClicked(item);
            }}
          />

          {this.props.recordCount > 0 && (
            <Row style={{ marginTop: "10px" }}>
            <Col xs={12} sm={4} md={4} lg={4}>
              {" "}
              <FormGroup className="selectLayer">
                <ControlLabel style={{ fontWeight: "bold" }}>
                  File Format
                </ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  name="fileFormat"
                  value={this.state.fileFormat}
                  onChange={this.handleInputChange}
                  required
                >
                  <option value="">Select</option>
                  <option value="excel">Excel</option>
                  <option value="csv">CSV</option>
                </FormControl>
              </FormGroup>
            </Col>

            <Col xs={12} sm={4} md={4} lg={4}>
              <FormGroup required>
                <ControlLabel style={{ fontWeight: "bold" }}>
                  File name
                </ControlLabel>
                <FormControl
                  type="input"
                  placeholder="Enter file name"
                  value={this.state.fileName}
                  name="limit"
                  onChange={this.handleInputChangeName}
                  required
                />
              </FormGroup>
            </Col>

            <Col xs={12} sm={4} md={2} lg={2}>
              <Button
                bsSize="xsmall"
                block
                type="submit"
                disabled={this.state.fileName == "" || this.state.fileFormat == ""}
                bsStyle="success"
                className="btn_submit"
                onClick={
                  this.state.readyToDownloadFlag
                    ? this.downloadFile
                    : this.generateReport
                }
              >
                {this.state.readyToDownloadFlag ? "Download" : "Export"}
              </Button>
            </Col>
            <ConfirmBox
              massage={
                "You are downloading a huge data, So it will take time..!!!"
              }
              show={this.state.downloadConfirmation}
              action={() => {
                this.setState({ downloadConfirmation: false });
              }}
              onHide={() => {
                this.setState({ downloadConfirmation: false });
              }}
              isNotSelected={true}
            />
            <ConfirmBox
              massage={"Your file is ready to download"}
              show={this.state.readyToDownloadFlagConfirm}
              action={() => {
                this.setState({ readyToDownloadFlagConfirm: false });
              }}
              onHide={() => {
                this.setState({ readyToDownloadFlagConfirm: false });
              }}
              isNotSelected={true}
            />
            {this.props.downloadLoading && (
              <div className="resultProgressBarDiv">
                <div className="resultProgressBar">
                  <CircularProgressbar
                    value={this.state.downloadPercentage}
                    text={`${this.state.downloadPercentage} %`}
                  />
                </div>
              </div>
            )}
          </Row>
        )}

        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log("memberDetails", state.dashboard.memberDetails);

  return {    
    filters: state.dashboard.filters,
    isFiltersLoaded: state.dashboard.isFiltersLoaded,
    memberDetails: state.dashboard.memberDetails,
    wards: state.dashboard.wards,
    isWardsLoaded: state.dashboard.isWardsLoaded,
    reportUrls: state.dashboard.reportUrls,
    reportFile: state.dashboard.reportFile,
    downloadFilter: state.dashboard.downloadFilter,
    downloadPage: state.dashboard.downloadPage,
    unique_id: state.dashboard.unique_id,
    recordCount: state.dashboard.recordCount,
    path: state.dashboard.path,
    socioEconomicFetchData: state.dashboard.socioEconomicFetchData,
    downloadLoading: state.dashboard.downloadLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMemberDetails: (body) => dispatch(getMemberDetails(body)),
    getJobFilter: (category_id) => dispatch(getJobFilter(category_id)),
    getEducationFilter: (category_id) =>
      dispatch(getEducationFilter(category_id)),
    loadingComplete: () => dispatch(loadingActions.loadingComplete()),
    downloadAction: (reportValues) => dispatch(downloadAction(reportValues)),
    generateReport: (reportValues) => dispatch(generateReport(reportValues)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);


// import React, { Component } from "react";
// import { connect } from "react-redux";
// import {
//   Table,
//   Row,
//   Col,
//   FormGroup,
//   ControlLabel,
//   FormControl,
//   Button,
//   Badge,
// } from "react-bootstrap";
// import "../style.css";
// import DashboardFilters from "./DashboardFilters";
// import {
//   getMemberDetails,
//   getJobFilter,
//   getEducationFilter,
//   downloadAction,
//   generateReport,
// } from "../data/action";
// import { loadingActions } from "../../loader/data/action";
// import LocalStorageHandler from "../../../util/storage";
// import TablePagination from "../../pagination/pagination";
// import ConfirmBox from "../../confirmation_box";
// import Pusher from "pusher-js";
// import { CircularProgressbar } from "react-circular-progressbar";
// import NoDataImg from "../../../asset/png/no-results.png";

// class Index extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       ageFilters: {
//         min_age: 0,
//         max_age: 0,
//       },
//       actualFilters: {},
//       singleFilters: {},
//       ward_id: null,
//       page: "1",
//       limit: "10",
//       recordCount: 0,
//       fileName: "",
//       fileType: "",
//       fileFormat: "",
//       downloadLimit: 100,
//       downloadPageCount: 1,
//       downloadConfirmation: false,
//       readyToDownloadFlag: false,
//       isDownloading: false,
//       readyToDownloadFlagConfirm: false,
//       pageLoading: 1,
//       pageloadPercentage: 0,
//       tableShow:false,
//     };
//   }

//   componentDidMount() {
//     // this.props.getMemberDetails({
//     //   common: {
//     //     localbody_id: LocalStorageHandler.getLocalBody().local_body_id,
//     //     ward_id__in: [this.state.ward_id],
//     //   },
//     //   member: {},
//     //   other: {},

//     //   disability: [this.state.actualFilters.disability__in],
//     //   page: this.state.page,
//     //   limit: this.state.limit,
//     // });
//     if (this.props.isWardsLoaded) {
//       this.setState({
//         ...this.state,
//         ward_id: this.props.wards[0].id,
//       });
//     }
//   }

//   componentDidUpdate(prevProps, prevState) {
//     if (
//       prevProps.isWardsLoaded != this.props.isWardsLoaded &&
//       this.props.isWardsLoaded
//     ) {
//       this.setState({
//         ...this.state,
//         ward_id: this.props.wards[0].id,
//       });
//     }

//     if (prevState.page != this.state.page) {
//       //this.handleSubmit();
//     }
//     //download file functionality
//     if (prevProps.reportFile != this.props.reportFile) {
//       this.setState({ downloadConfirmation: false });
//     }

//     if (prevProps.downloadPage != this.props.downloadPage) {
//       this.appendDownload();
//     }
//     if(prevProps.memberDetails != this.props.memberDetails)
//     {
//       this.setState({
//         tableShow: true
//       })
//     }
//   }

//   setFilters = (value, filter) => {
//     if (filter === "min_age" || filter === "max_age") {
//       this.setState({
//         ...this.state,
//         ageFilters: {
//           ...this.state.ageFilters,
//           [filter]: Number(value),
//         },
//       });
//     } else if (value === "None") {
//       let actualFilters = this.state.actualFilters;
//       delete actualFilters[filter];
//       this.setState({
//         ...this.state,
//         actualFilters: actualFilters,
//       });
//     } else {
//       this.setState({
//         ...this.state,
//         actualFilters: { ...this.state.actualFilters, [filter]: [value] },
//       });
//     }
//   };

//   setSingleFilter = (value, filter) => {
//     if (value === "") {
//       let singleFilters = this.state.singleFilters;
//       delete singleFilters[filter];
//       this.setState({
//         ...this.state,
//         singleFilters: singleFilters,
//       });
//     } else {
//       this.setState({
//         ...this.state,
//         singleFilters: { ...this.state.singleFilters, [filter]: value },
//       });
//     }
//     if (filter === "job_category") {
//       this.props.getJobFilter(value);
//     }
//     if (filter === "education_category") {
//       this.props.getEducationFilter(value);
//     }
//     // if(this.props.memberDetails !=null){
//     //   this.setState({
//     //     tableShow: true
//     //   })
//     // }
//   };

//   clearFilter = () => {
//     this.setState({
//       ageFilters: {
//         min_age: 0,
//         max_age: 0,
//       },
//       actualFilters: {
//         diseases__in: [""],
//         pension__in: [""],
//         disability__in: [""],
//       },
//       singleFilters: {
//         gender: "",
//         blood_group: "",
//         marital_status: "",
//         job_category: "",
//         education_category: "",
//         caste: "",
//       },
//       ward_id: this.props.wards[0].id,
//       readyToDownloadFlag: false,
//       // tableShow:false,
//     });
//     this.setState({ isDownloading: false,tableShow:false, });
    
//   };

//   handleWardChange = (ward) => {
//     this.setState({
//       ...this.state,
//       ward_id: ward,
//     });
//   };

//   handleSubmit = (isPagination) => {
  
//     if (isPagination != "isPagination") {
//       this.setState({
//         page: 1,
//       });
//     }
//     const { actualFilters } = this.state;
//     const member_filter = Object.fromEntries(
//       Object.entries(actualFilters).filter(([_, value]) => value[0] !== "")
//     );
//     if (this.state.ward_id === "0") {
//       alert("You are loading a huge data, So it will take time..!!!");
//     }
//     if (this.state.ageFilters.min_age > this.state.ageFilters.max_age) {
//       alert("Min Age must be less than or equal to Max Age");
//       return;
//     }
//     if (this.state.ageFilters.max_age > 0) {
//       member_filter["age__range"] = [
//         this.state.ageFilters.min_age,
//         this.state.ageFilters.max_age,
//       ];
//     }
//     let singleFilters = this.state.singleFilters;
    
//     Object.entries(singleFilters).forEach(([key, value]) => {
//       if (value === "") delete singleFilters[key];
//     });
//     let finalFilter = Object.assign(member_filter, singleFilters);
//     let common =
//       this.state.ward_id === "0" || this.state.ward_id === null
//         ? {
//             localbody_id: LocalStorageHandler.getLocalBody().local_body_id,
//             ward_id__in: ["all"],
//           }
//         : {
//             localbody_id: LocalStorageHandler.getLocalBody().local_body_id,
//             ward_id__in: [this.state.ward_id],
//           };
//     this.props.getMemberDetails({
//       common: common,
//       member: finalFilter,
//       other: {},
//       disability: [this.state.actualFilters.disability__in],
//       page: isPagination != "isPagination" ? 1 : this.state.page,
//       limit: this.state.limit,
//       unique_id: this.props.unique_id,
//       recordCount: this.props.recordCount,
//       file_name: this.state.fileName,
//       file_extension: this.state.fileFormat,
//     });
//   };
//   afterPageClicked = (page) => {
//     if (!isNaN(page))
//       this.setState(
//         {
//           page: page,
//         },
//         () => this.handleSubmit("isPagination")
//       );
//   };

//   handleInputChange = (event) => {
//     this.setState({ fileFormat: event.target.value });
//   };

//   handleInputChangeName = (e) => {
//     this.setState({ fileName: e.target.value });
//   };

//   downloadFile = () => {
//     this.setState({ isDownloading: false });

//     if (this.state.fileFormat === "csv") {
//       let url =
//         this.props.path +
//         this.state.fileName +
//         "_" +
//         this.props.unique_id +
//         ".csv";
//       window.open(url);
//     }

//     if (this.state.fileFormat === "excel") {
//       let downloadUrls =
//         this.props.path +
//         this.state.fileName +
//         "_" +
//         this.props.unique_id +
//         ".xlsx";
//       window.open(downloadUrls);
//     }

//   };

//   generateReport = () => {

//     this.props.recordCount > 1000 &&
//       this.setState({ downloadConfirmation: true });
//     this.setState({ downloadPercentage: 0, isDownloading: true });

//     const page = 1;
//     const recordCount = this.props.recordCount;
//     const downloadLimit = this.state.downloadLimit;

//     const limit =
//       page * downloadLimit >= recordCount
//         ? recordCount - (page - 1) * downloadLimit
//         : downloadLimit;

//     let data = {
//       page,
//       limit,
//       report: "True",

//       common: {
//         localbody_id: LocalStorageHandler.getLocalBody().local_body_id,
//         ward_id__in: [this.state.ward_id],
//       },
//       member: {
//         gender: this.state.singleFilters.gender,
//       ...(this.state.ageFilters.min_age !== 0 || this.state.ageFilters.max_age !== 0) && {
//         age__range: [this.state.ageFilters.min_age, this.state.ageFilters.max_age],
//     },
//         marital_status: this.state.singleFilters.marital_status,
//         job_category: this.state.singleFilters.job_category,
//         education_category: this.state.singleFilters.education_category,
//         caste: this.state.singleFilters.caste,
//         ...(this.state.actualFilters.diseases__in != "" && { diseases: parseInt(this.state.actualFilters.diseases__in)}),
//         ...(this.state.actualFilters.pension__in != null && { pension: parseInt(this.state.actualFilters.pension__in)}),
//       },
//       other: {},

//       disability: [this.state.actualFilters.disability__in],
//       unique_id: this.props.unique_id,
//       file_name: this.state.fileName,
//       file_extension: this.state.fileFormat,
//       recordCount: this.props.recordCount,
//       path: this.props.path,
//       maxPage: this.props.recordCount / this.state.downloadLimit,
//     };
  
//     this.props.downloadAction(data);
//     this.setState({ downloadPageCount: 1 });
//   };

//   appendDownload = () => {
//     try {
//       if (!this.state.isDownloading) return;
//       let maxCount = this.props.recordCount / this.state.downloadLimit;
//       let pusherCount = (100 / maxCount).toFixed(2);
//       let currentPage = Number(this.state.downloadPageCount);
//       let currentPusherCount = Number(this.state.downloadPercentage);

//       const page = currentPage + 1;
//       const recordCount = this.props.recordCount;
//       const downloadLimit = this.state.downloadLimit;
//       const limit =
//         page * downloadLimit >= recordCount
//           ? recordCount - (page - 1) * downloadLimit
//           : downloadLimit;

//       if (maxCount > currentPage) {
//         let data = {
//           page,
//           limit,
//           report: "True",

//           common: {
//             localbody_id: LocalStorageHandler.getLocalBody().local_body_id,
//             ward_id__in: [this.state.ward_id],
//           },
//           member: {
//             gender: this.state.singleFilters.gender,
//             // age__range: [
//             //   this.state.ageFilters.min_age,
//             //   this.state.ageFilters.max_age,
//             // ],
//             ...(this.state.ageFilters.min_age !== 0 || this.state.ageFilters.max_age !== 0) && {
//               age__range: [this.state.ageFilters.min_age, this.state.ageFilters.max_age],
//           },
//             marital_status: this.state.singleFilters.marital_status,
//             job_category: this.state.singleFilters.job_category,
//             education_category: this.state.singleFilters.education_category,
//             caste: this.state.singleFilters.caste,
//             ...(this.state.actualFilters.diseases__in != "" && { diseases: parseInt(this.state.actualFilters.diseases__in)}),
//             ...(this.state.actualFilters.pension__in != null && { pension: parseInt(this.state.actualFilters.pension__in)}),
//           },
//           disability: [this.state.actualFilters.disability__in],
//           other: {},
//           unique_id: this.props.unique_id,
//           file_name: this.state.fileName,
//           file_extension: this.state.fileFormat,
//           recordCount: this.props.recordCount,
//           path: this.props.path,
//           maxPage: this.props.recordCount / this.state.downloadLimit,
//         };
//         this.props.downloadAction(data);
//         this.setState({ downloadPageCount: currentPage + 1 });

//         var sumPercentage = Number(currentPusherCount) + Number(pusherCount);
//         let finalPercentage = sumPercentage.toFixed(2);
//         if (sumPercentage < 100) {
//           this.setState({
//             downloadPercentage: finalPercentage,
//           });
//         }
//       } else {
//         this.setState({
//           readyToDownloadFlag: true,
//           isDownloading: false,
//           readyToDownloadFlagConfirm: true,
//         });
//       }
//     } catch (e) {
//       return;
//     }
//   };

//   render() {
// console.log("state values chnagea",this.state.tableShow);
//     return (
//       <>
//         <DashboardFilters
//           setFilters={this.setFilters}
//           actualFilters={this.state.actualFilters}
//           ageFilters={this.state.ageFilters}
//           handleSubmit={this.handleSubmit}
//           setSingleFilter={this.setSingleFilter}
//           handleWardChange={this.handleWardChange}
//           filterData={this.state}
//           clearFilter={this.clearFilter}
//         />
//         {this.state.tableShow ? (
//         <>
//         <Table striped bordered condensed hover>
//           <thead>
//             <tr>
//               <th>SL NO</th>
//               <th>Name</th>
//               <th>Age</th>
//               <th>Gender</th>
//               <th>Education</th>
//               <th>Marital Status</th>
//               <th>Pension</th>
//               <th>Diseases</th>
//               <th>Property</th>
//               <th>Disability</th>
//               <th>Caste</th>
//             </tr>
//           </thead>
//           {/* {this.state.tableShow && ( */}
//             <tbody>
//               {this.props.memberDetails.map(
//                 (item, index) => {
//                   return (
//                     <tr key={index}>
//                       <td>{(this.state.page - 1) * 10 + (index + 1)}</td>
//                       <td>
//                         {item.firstname +
//                           " " +
//                           (item.lastname === null ? "" : item.lastname)}
//                       </td>
//                       <td>{item.age}</td>
//                       <td>{item.gender}</td>
//                       <td>{item.education}</td>
//                       <td>{item.marital_status}</td>
//                       <td>{item.pension.toString()}</td>
//                       <td>{item.diseases.toString()}</td>
//                       <td>{item.property}</td>
//                       <td>{item.disability.toString()}</td>
//                       <td>{item.caste}</td>
//                     </tr>
//                   );
//                 },
//                 () => {
//                   loadingComplete();
//                 }
//               )}
//             </tbody>
//           {/* )} */}
//         </Table>
//         <Row>
//           <Col xs={6} sm={6} md={6} lg={6}>
//             <h5 style={{ padding: "0px", marginTop: "34px" }}>
//               Total/Searched Count: {this.props.recordCount}
//               <Badge className="countBadge" />
//             </h5>
//           </Col>
//         </Row>
//         <div style={{ paddingBottom: "40px" }}>
//           <TablePagination
//             recordCount={this.props.recordCount}
//             page={this.state.page}
//             limit={this.state.limit}
//             pageClicked={(item) => {
//               this.afterPageClicked(item);
//             }}
//           />

//           <Row style={{ marginTop: "10px" }}>
//             <Col xs={12} sm={4} md={4} lg={4}>
//               {" "}
//               <FormGroup className="selectLayer">
//                 <ControlLabel style={{ fontWeight: "bold" }}>
//                   File Format
//                 </ControlLabel>
//                 <FormControl
//                   componentClass="select"
//                   placeholder="select"
//                   name="fileFormat"
//                   value={this.state.fileFormat}
//                   onChange={this.handleInputChange}
//                   required
//                 >
//                   <option value="">Select</option>
//                   <option value="excel">Excel</option>
//                   <option value="csv">CSV</option>
//                 </FormControl>
//               </FormGroup>
//             </Col>

//             <Col xs={12} sm={4} md={4} lg={4}>
//               <FormGroup required>
//                 <ControlLabel style={{ fontWeight: "bold" }}>
//                   File name
//                 </ControlLabel>
//                 <FormControl
//                   type="input"
//                   placeholder="Enter file name"
//                   value={this.state.fileName}
//                   name="limit"
//                   onChange={this.handleInputChangeName}
//                   required
//                 />
//               </FormGroup>
//             </Col>

//             <Col xs={12} sm={4} md={2} lg={2}>
//               <Button
//                 bsSize="xsmall"
//                 block
//                 type="submit"
//                 bsStyle="success"
//                 className="btn_submit"
//                 onClick={
//                   this.state.readyToDownloadFlag
//                     ? this.downloadFile
//                     : this.generateReport
//                 }
//               >
//                 {this.state.readyToDownloadFlag ? "Download" : "Export"}
//               </Button>
//             </Col>
//             </Row>
//         </div>
//         </>):(<div style={{marginTop:"20px"}}>
//                 <img src={NoDataImg} width="100" className="center" />
//                 <p className="noDataTxt">No data found</p>
//               </div>)}
//             <ConfirmBox
//               massage={
//                 "You are downloading a huge data, So it will take time..!!!"
//               }
//               show={this.state.downloadConfirmation}
//               action={() => {
//                 this.setState({ downloadConfirmation: false });
//               }}
//               onHide={() => {
//                 this.setState({ downloadConfirmation: false });
//               }}
//               isNotSelected={true}
//             />
//             <ConfirmBox
//               massage={"Your file is ready to download"}
//               show={this.state.readyToDownloadFlagConfirm}
//               action={() => {
//                 this.setState({ readyToDownloadFlagConfirm: false });
//               }}
//               onHide={() => {
//                 this.setState({ readyToDownloadFlagConfirm: false });
//               }}
//               isNotSelected={true}
//             />
//             {this.props.downloadLoading && (
//               <div className="resultProgressBarDiv">
//                 <div className="resultProgressBar">
//                   <CircularProgressbar
//                     value={this.state.downloadPercentage}
//                     text={`${this.state.downloadPercentage} %`}
//                   />
//                 </div>
//               </div>
//             )}
//           {/* </Row>
//         </div> */}
//       </>
//     );
//   }
// }

// function mapStateToProps(state) {
//   return {
//     filters: state.dashboard.filters,
//     isFiltersLoaded: state.dashboard.isFiltersLoaded,
//     memberDetails: state.dashboard.memberDetails,
//     wards: state.dashboard.wards,
//     isWardsLoaded: state.dashboard.isWardsLoaded,
//     reportUrls: state.dashboard.reportUrls,
//     reportFile: state.dashboard.reportFile,
//     downloadFilter: state.dashboard.downloadFilter,
//     downloadPage: state.dashboard.downloadPage,
//     unique_id: state.dashboard.unique_id,
//     recordCount: state.dashboard.recordCount,
//     path: state.dashboard.path,
//     socioEconomicFetchData: state.dashboard.socioEconomicFetchData,
//     downloadLoading: state.dashboard.downloadLoading,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     getMemberDetails: (body) => dispatch(getMemberDetails(body)),
//     getJobFilter: (category_id) => dispatch(getJobFilter(category_id)),
//     getEducationFilter: (category_id) =>
//       dispatch(getEducationFilter(category_id)),
//     loadingComplete: () => dispatch(loadingActions.loadingComplete()),
//     downloadAction: (reportValues) => dispatch(downloadAction(reportValues)),
//     generateReport: (reportValues) => dispatch(generateReport(reportValues)),
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Index);
