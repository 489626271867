import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./Filter.css";
import { Modal, Panel, ButtonToolbar, Button, Checkbox } from "react-bootstrap";
import closeButton from "../../../asset/svg/close_icon.svg";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

class Attribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFields: [],
      sectionSelected: {},
      expandedSections: {},
    };
  }

  componentDidMount() {
    const { parameters } = this.props;
    if (parameters && parameters.length > 0) {
      const selectedFields = parameters.map((param) => param.field);
      this.setState({ selectedFields });
    }
  }

  checkedStatus = (name) => {
    const { parameters } = this.props;
    let status = false;
    if (parameters.length != 0) {
      parameters.map((item, index) => {

        if (item.display_name == name) {
          status = true;
        }
      });
    }
    return status;
  };

  handleSectionSelectAll = (sectionKey, sectionValues, isChecked) => {
    const updatedFields = { ...this.state.selectedFields };

    // Update all values under this section
    sectionValues.forEach((item) => {
      if (isChecked) {
        updatedFields[item.display_name] = true;
      } else {
        delete updatedFields[item.display_name];
      }
    });

    // Update section state
    this.setState({
      selectedFields: updatedFields,
      sectionSelected: {
        ...this.state.sectionSelected,
        [sectionKey]: isChecked,
      },
    });
  };

  toggleSection = (sectionKey) => {
    this.setState((prevState) => ({
      expandedSections: {
        ...prevState.expandedSections,
        [sectionKey]: !prevState.expandedSections[sectionKey],
      },
    }));
  };

  attributeList = () => {
    const { selectedFields, expandedSections } = this.state;
    let filterData = [];
    for (const key in this.props.data.layer.advancefilterinfo_sorted) {
      let keys = key.toString();
      let data = {
        key: `${key}`,
        values: this.props.data.layer.advancefilterinfo_sorted[keys],
      };
      console.log("data attribute", data);

      filterData.push(data);
    }

    return filterData.map((el) => {
      const allChecked = el.values.every((col) =>
        this.checkedStatus(col.display_name)
      );

      return (
        <div key={el.key} style={{ marginBottom: "10px", border: "1px solid #ddd", borderRadius: "5px" }}>
          <div
            style={{
              backgroundColor: "#f5f5f5",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={allChecked}
              onChange={(e) =>
                this.props.handleInputChangeSelect(e, null, true, el.values)
              }
              style={{ marginRight: "10px" }}
            >
              <b>{el.key}</b>
            </Checkbox>
            <Button
              variant="link"
              onClick={() => this.toggleSection(el.key)}
              style={{ padding: "0", borderColor: "#f2f5fb" }}
            >
              {expandedSections[el.key] ? <IoIosArrowUp size={20} style={{ backgroundColor: "#f5f5f5"}} /> : <IoIosArrowDown size={20} style={{ backgroundColor: "#f5f5f5" }} />}
            </Button>
          </div>
          {expandedSections[el.key] && (
            <div style={{ padding: "10px" }}>
              {el.values
                .sort((a, b) =>
                  a["display_name"] > b["display_name"] ? 1 : -1
                )
                .map((column) => (
                  <div key={column.display_name}>
                    <Checkbox
                      value={column.display_name}
                      key={column.display_name}
                      checked={this.checkedStatus(column.display_name)}
                      onChange={(event) =>
                        this.props.handleInputChangeSelect(event)
                      }
                    >
                      {column.display_name}
                    </Checkbox>
                  </div>
                ))}
            </div>
          )}
        </div>
      );
    });
  };

  // filterData.length > 0 &&
  //   return filterData.map((el, i) => {
  //     let flag = 0;

  //     return el.values

  //       .sort((a, b) => (a["display_name"] > b["display_name"] ? 1 : -1))
  //       .map((column, index) => {
  //         if (flag == 0) {
  //           ++flag;
  //           const allChecked = el.values.every((col) =>
  //             this.checkedStatus(col.display_name)
  //           );

  //           return (
  //             <div key={el.key}>
  //               <p style={{ margin: "15px 0px 0px 0px", fontSize: "16px", backgroundColor: "#f5f5f5", padding: "5px 5px 5px 0px" }}>
  //                 <Checkbox
  //                   checked={allChecked}
  //                   onChange={(e) =>
  //                     this.props.handleInputChangeSelect(e, null, true, el.values)
  //                   }
  //                 >
  //                   <b>{el.key}</b>
  //                 </Checkbox>
  //               </p>
  //               <Checkbox
  //                 value={column.display_name}
  //                 key={column.display_name}
  //                 checked={this.checkedStatus(column.display_name)}
  //                 onChange={(event) =>
  //                   this.props.handleInputChangeSelect(event, index)
  //                 }
  //               >
  //                 {column.display_name}
  //               </Checkbox>
  //             </div>
  //           );
  //         }

  //         return (
  //           <div key={column.display_name}>
  //             <Checkbox
  //               value={column.display_name}
  //               key={column.display_name - new Date().getTime()}
  //               // key={index}
  //               checked={this.checkedStatus(column.display_name)}
  //               onChange={(event) =>
  //                 this.props.handleInputChangeSelect(event, index)
  //               }
  //             >
  //               {column.display_name}
  //             </Checkbox>
  //           </div>
  //         );
  //         // }
  //       });
  //   });
  // };

  render() {
    const { closeAttri, parameters } = this.props;

    return (
      <Modal show={this.props.show} className="filterFilterModal">
        <Panel bsStyle="primary" className="filterFilterPanel">
          <Panel.Heading>
            <Panel.Title componentClass="h3">Filter</Panel.Title>
            <ButtonToolbar className="pull-right button">
              <img
                src={closeButton}
                className="panelheadbtn"
                onClick={closeAttri}
              />
            </ButtonToolbar>
          </Panel.Heading>
          <Panel.Body className="panelBody">
            {this.props.show && this.attributeList()}
          </Panel.Body>
          <Panel.Footer className="panelFooter">
            <Button
              bsStyle="success"
              style={{ marginLeft: "3px", left: "5px" }}
              onClick={this.props.handleInputSelectAll}
            >
              Select All
            </Button>
            {parameters && parameters.length > 0 && (
              <Button
                bsStyle="success"
                style={{ marginLeft: "3px", left: "5px" }}
                onClick={this.props.handleInputUnselectAll}
              >
                Unselect All
              </Button>
            )}
            <Button
              bsStyle="success"
              style={{ marginLeft: "3px", left: "5px" }}
              onClick={closeAttri}
            >
              OK
            </Button>
          </Panel.Footer>
        </Panel>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    layerColumns: state.advancedFilter.layerColumns,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Attribute)
);
