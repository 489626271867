import React, { Component } from "react";
import {
  Modal,
  ButtonToolbar,
  Col,
  Row,
  Table,
  Button,
  Badge,
  FormGroup,
  FormControl,
  ControlLabel,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./ResultModel.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import closeButton from "../../../../../asset/svg/close_icon.svg";
import getAttributeName from "../../../../../util/attributes/";
import TablePagination from "../../../../pagination/pagination";
import Thumbnail from "react-pdf-reader/dist/js/components/Thumbnail";
import { CircularProgressbar } from "react-circular-progressbar";
class ResultModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excelName: "Excel",
      page: "1",
      limit: "10",
      recordCount: 0,
    };
  }
  componentDidMount() {
    this.setState({
      excelName: this.props.layer.label,
    });
  }
  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  filterProperties(properties) {
    let filteredProps = {};
    let detailedInfoAttributes = [];
    if (properties != null) {
      if (this.props.layer != null) {
        detailedInfoAttributes = this.props.layer.detailedInfoAttributes;
      }
      Object.keys(properties).filter((key) => {
        detailedInfoAttributes.forEach((attribute) => {
          if (key === attribute.field) {
            filteredProps[key] = properties[key];
          }
        });
      });
    }
    return filteredProps;
  }

  handleResponseHeader() {
     let advancefilterinfo = this.props.layer.advancefilterinfo;

    let properties = {};
    let names = {};
    this.props.result.map((res, i) => {
      properties = res;
    });
   
    advancefilterinfo &&
      advancefilterinfo.map((res, i) => {
        names = res;
      });
    if (properties != null) {
      return (
        <tr key={-1}>
          <th>SL NO</th>
          {Object.keys(properties).map((key) => {
            if (key == "Member_Details") {
              return <th key={key}>MEMBER COUNT</th>;
            } else if (key == "Owner_Details") {
              return <th key={key}>OWNER DETAILS</th>;
            } else if (!key.includes("photo") && !key.includes("p_d_f_")) {
              return <th key={key}>{getAttributeName(key)}</th>;
            }
          })}
        </tr>
      );
    }
  }

  handleResponse() {
    let properties = {};
    return this.props.result.map((res, i) => {
      properties = res;

      if (properties != null) {
        return (
          <tr key={i}>
            <td>{(this.state.page - 1) * 10 + i + 1}</td>
            {Object.keys(properties).map((key) => {
              var key1, floorPropArea, propRoofType;
              if (!key.includes("photo") && !key.includes("p_d_f_")) {
                switch (key) {
                  case "Member_Details": {
                    var obj = [];
                    if (properties["Member_Details"] != "") {
                      obj = JSON.parse(properties["Member_Details"]);
                      key1 = "Member Details";

                      return (
                        <td key={key}>
                          <button>{obj.length}</button>
                        </td>
                      );
                    } else return <td key={key}></td>;
                    break;
                  }
                  case "Owner_Details": {
                    if (properties["Owner_Details"] != "") {
                      var obj = JSON.parse(properties["Owner_Details"]);
                      var ownerDisplay = "";

                      obj.map((ownerItem) => {
                        ownerDisplay += ownerItem["firstname"]
                          ? ownerItem["firstname"]
                          : null;
                      });
                      key1 = "Owner Details";
                      return (
                        <td key={key}>
                          {/* <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="menu">
                                Click on to see more details
                              </Tooltip>
                            }
                          > */}
                          <p
                          // onClick={(e) =>
                          //   this.handleShowDetailsTable(e, obj, key1)
                          // }
                          >
                            {ownerDisplay}
                          </p>
                          {/* </OverlayTrigger> */}
                        </td>
                      );
                    } else return <td key={key}></td>;
                    break;
                  }
                  case "Floor_Prop_Area": {
                    var floorDisplay = "";
                    const newline = "<br>";
                    if (properties["Floor_Prop_Area"] != "") {
                      floorPropArea = JSON.parse(properties["Floor_Prop_Area"]);

                      floorPropArea.map((floorItem) => {
                        if (floorItem["floor_category"])
                          floorDisplay +=
                            floorItem["floor_category"] +
                            ":" +
                            floorItem["floor_area"] +
                            ";  ";
                        else if (floorItem["floor_area"]) {
                          floorDisplay += floorItem["floor_area"] + ";";
                        }
                      });
                    }
                    return <td key={key}>{floorDisplay}</td>;
                    break;
                  }
                  case "Property_Roof_Type": {
                    var roofDisplay = "";
                    if (properties["Property_Roof_Type"] != "") {
                      propRoofType = JSON.parse(
                        properties["Property_Roof_Type"]
                      );

                      propRoofType.map((roofItem) => {
                        roofDisplay +=
                          roofItem["roof_type"] +
                          ":" +
                          roofItem["roof_per"] +
                          "%;  ";
                      });
                    }
                    return <td key={key}>{roofDisplay}</td>;
                    break;
                  }

                  default: {
                    return (
                      <td className="new-line" key={key}>
                        {properties[key] ? properties[key].toString() : "Null"}
                      </td>
                    );
                  }
                }
              }
            })}
          </tr>
        );
      }
    });
  }

  afterPageClicked = (page) => {
    if (!isNaN(page))
      this.setState({
        page: page,
      });
  };

  handleMemberResponse() {
    let properties = {};
    return (
      this.props.response_member &&
      this.props.response_member.map((res, i) => {
        properties = res;
        if (properties != null) {
          return (
            <tr key={i}>
              <td>{i + 1}</td>
              {Object.keys(properties).map((key) => {
                var key1, floorPropArea, propRoofType;
                if (!key.includes("photo") && !key.includes("p_d_f_")) {
                  // return <p>hhh</p>;

                  switch (key) {
                    case "member": {
                      var obj = [];
                      if (properties["member"] != "") {
                        return <td key={key}></td>;
                      } else return <td key={key}></td>;
                      break;
                    }
                    case "Owner_Details": {
                      if (properties["Owner_Details"] != "") {
                        var obj = JSON.parse(properties["Owner_Details"]);
                        var ownerDisplay = "";

                        obj.map((ownerItem) => {
                          ownerDisplay += ownerItem["firstname"];
                        });
                        key1 = "Owner Details";
                        return (
                          <td key={key}>
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="menu">
                                  Click on to see more details
                                </Tooltip>
                              }
                            >
                              <p
                                onClick={(e) =>
                                  this.handleShowDetailsTable(e, obj, key1)
                                }
                              >
                                {ownerDisplay}
                              </p>
                            </OverlayTrigger>
                          </td>
                        );
                      } else return <td key={key}></td>;
                      break;
                    }
                    case "Floor_Prop_Area": {
                      var floorDisplay = "";
                      const newline = "<br>";
                      if (properties["Floor_Prop_Area"] != "") {
                        floorPropArea = JSON.parse(
                          properties["Floor_Prop_Area"]
                        );
                        floorPropArea.map((floorItem) => {
                          floorDisplay +=
                            floorItem["floor_category"] +
                            ":" +
                            floorItem["floor_area"] +
                            ";  ";
                        });
                      }
                      return <td key={key}>{floorDisplay}</td>;
                      break;
                    }
                    case "Property_Roof_Type": {
                      var roofDisplay = "";
                      if (properties["Property_Roof_Type"] != "") {
                        propRoofType = JSON.parse(
                          properties["Property_Roof_Type"]
                        );
                        propRoofType.map((roofItem) => {
                          roofDisplay +=
                            roofItem["roof_type"] +
                            ":" +
                            roofItem["roof_per"] +
                            "%;  ";
                        });
                      }
                      return <td key={key}>{roofDisplay}</td>;
                      break;
                    }
                    default: {
                      return (
                        <td className="new-line" key={key}>
                          {properties[key]}
                        </td>
                      );
                    }
                  }
                }
              })}
            </tr>
          );
        }
      })
    );
  }

  handleResponseMemberHeader() {
    let advancefilterinfo = this.props.layer.advancefilterinfo;

    let properties = {};
    let names = {};
    this.props.response_member &&
      this.props.response_member.map((res, i) => {
        properties = res;
      });
    advancefilterinfo.map((res, i) => {
      names = res;
    });
    if (properties != null) {
      return (
        <tr key={-1}>
          <th>SL NO</th>
          {Object.keys(properties).map((key) => {
            if (key == "Member_Details") {
              return <th key={key}>MEMBER COUNT</th>;
            } else if (key == "Owner_Details") {
              return <th key={key}>OWNER DETAILS</th>;
            } else if (!key.includes("photo") && !key.includes("p_d_f_")) {
              return <th key={key}>{getAttributeName(key)}</th>;
            }
          })}
        </tr>
      );
    }
  }

  render() {
    const rowItems = this.handleResponse();
    const rowHead = this.handleResponseHeader();
    
    return (
      <Modal
        className="pdfModelResult"
        show={this.props.show}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
        recordCount={this.props.recordCount}
        page={this.state.page}
        limit={this.state.limit}
        dialogClassName="custom-modal"
      >
        <Modal.Header className="pdfModelResultHeader">
          <Modal.Title id="contained-modal-title-lg">
            SEARCH REPORT
            <ButtonToolbar className="pull-right button">
              <img
                src={closeButton}
                className="pdfheadbtn"
                onClick={this.props.handleShowReport}
              />
            </ButtonToolbar>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pdfModelResultBody">
          <div className="reportContainerModel">
            <Table
              striped
              bordered
              condensed
              hover
              className="detailedInfoContent"
              id="table-to-xls"
            >
              <thead>{rowHead}</thead>
              <tbody>{rowItems}</tbody>
            </Table>
          </div>

          {this.props.layer && this.props.layer.label === "Residential" && (
            <div className="reportContainerModel">
              <Table
                striped
                bordered
                condensed
                hover
                className="detailedInfoContent"
                id="table-to-xls"
              >
                <thead>{this.handleResponseMemberHeader()}</thead>
                <tbody>{this.handleMemberResponse()}</tbody>
              </Table>
            </div>
          )}

          <React.Fragment>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={12} sm={4} md={4} lg={4}>
                {" "}
                <FormGroup className="selectLayer">
                  <ControlLabel style={{ fontWeight: "bold" }}>
                    File Format
                  </ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    name="fileFormat"
                    value={this.props.data.fileFormat}
                    onChange={this.props.handleFileFormatChange}
                    required
                  >
                    <option value="">Select</option>
                    <option value="excel">Excel</option>
                    <option value="csv">CSV</option>
                  </FormControl>
                </FormGroup>
              </Col>
              {this.props.data.layerName === "Residential" && (
                <Col xs={12} sm={4} md={4} lg={4}>
                  {" "}
                  <FormGroup className="selectLayer">
                    <ControlLabel style={{ fontWeight: "bold" }}>
                      File
                    </ControlLabel>

                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="fileFormat"
                      value={this.props.data.fileType}
                      onChange={(event) => {
                        this.props.handleFileChange(event);
                        // this.setState({
                        //   data: {
                        //     ...this.props.data,
                        //     fileType: event.target.value,
                        //   },
                        // });
                        // this.props.clearDownLoadInitAction();
                      }}
                      required
                    >
                      <option value="">Select</option>
                      <option value="building">Residential details</option>
                      member
                      {this.props.reportUrls &&
                        this.props.reportUrls[0].urls.csv.member && (
                          <option value="member">Member details</option>
                        )}
                      {this.props.reportUrls &&
                        this.props.reportUrls[0].urls.csv.merged && (
                          <option value="merged">
                            Merged building and member details
                          </option>
                        )}
                    </FormControl>
                  </FormGroup>
                </Col>
              )}
              <Col xs={6} sm={3} md={2} lg={2}>
                <Button
                  bsSize="xsmall"
                  block
                  type="submit"
                  bsStyle="success"
                  className="btn_submit"
                  onClick={
                    this.props.reportFile
                      ? this.props.downloadFile()
                      : this.props.generateReport()
                  }
                  disabled={
                    this.props.data.layerName === "Residential"
                      ? this.props.data.fileFormat == "" ||
                        this.props.data.fileType == ""
                      : this.props.data.fileFormat == ""
                  }
                >
                  {this.props.reportFile ? "Download" : "Export"}
                </Button>
              </Col>
            </Row>

            {this.props.advanceLoading && (
              <div className="resultProgressBarDiv">
                <div className="resultProgressBar">
                  <CircularProgressbar
                    value={this.props.percentage}
                    text={`${this.props.percentage}%`}
                  />
                </div>
              </div>
            )}

            <Row>
              <Col>
                {this.props.downloadLoading && (
                  <div className="resultProgressBarDiv">
                    <div className="resultProgressBar">
                      <CircularProgressbar
                        value={this.props.downloadPercentage}
                        text={`${this.props.downloadPercentage}%`}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            {/* <Row>
              <Col xs={6} sm={6} md={6} lg={6}>
                <h5 style={{ padding: "0px", marginTop: "34px" }}>
                  Total/Searched Count: {this.props.recordCount}
                  <Badge className="countBadge" />
                </h5>
              </Col>
            </Row> */}
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer className="pdfModelResultFooter">
          <Row>
            <Col md={3}>
              <h5 style={{ padding: "0px", marginTop: "18px" }}>
                Search Result Count:
                {this.props.recordCount ? this.props.recordCount : null}
                {/* {this.props.result.length ? this.props.result.length : null} */}
                <Badge className="countBadge" />
              </h5>
            </Col>
            {/* <Col md={6}>
              <TablePagination
                recordCount={this.props.recordCount}
                page={this.state.page}
                limit={this.state.limit}
                pageClicked={(item) => {
                  this.afterPageClicked(item);
                  this.props.handlepaginationClick(item);
                }}
              />
            </Col> */}
          </Row>
          <Row>
            <Col>
              <TablePagination
                recordCount={this.props.recordCount}
                page={this.state.page}
                limit={this.state.limit}
                pageClicked={(item) => {
                  this.afterPageClicked(item);
                  this.props.handlepaginationClick(item);
                }}
              />
            </Col>
          </Row>

          <Row>
            {/* <Col md={5}>
              <label>Excel Name</label>
              <input
                type="text"
                placeholder="Label"
                value={this.state.excelName}
                name="excelName"
                onChange={this.handleInputChange}
                required
                className="excelName"
              />
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-success"
                table="table-to-xls"
                filename={this.state.excelName}
                sheet="tablexls"
                buttonText="Download as XLS"
              />
            </Col> */}
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default ResultModel;
